import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import {
  DataGrid
} from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import FormatDateTime from '../shared/FormatDateTime';



export default function MonitorTrucks() {
  const [page, setPage] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);

  const [rows, setRows] = React.useState([]);

  const columns = [
    { editable: false, field: 'deliveryOrderNo', headerAlign: 'center', headerName: 'Delivery Order No', flex: 1, renderCell: (node) => {
      return(
        <p style={{ fontSize: '18px'}}>{node.value}</p>
      )
    } },
    { editaple: false, field: 'truckNo', headerAlign: 'center', headerName: 'Truck/Vehicle Plate Number', flex: 2, renderCell: (node) => {
      return(
        <p style={{ fontSize: '18px'}}>{node.value}</p>
      )
    } },
    { editable: false, field: 'status', headerAlign: 'center', headerName: 'Status', flex: 2, renderCell: (node) => {
      return(
        <p style={{ fontSize: '18px'}}>{node.value}</p>
      )
    } },
    { editable: false, field: 'lastUpdated',  sortable: false, headerAlign: 'center', headerName: 'Last Updated', flex: 2, 
      renderCell: (node) => {
        return <p style={{ fontSize: '18px'}}><FormatDateTime value={node.value} /></p>
      } 
    }
  ];
  

  const updateToken = () => {
    const headers = {
      'Authorization': "Bearer "+localStorage.getItem("access_token")
    };
    axios.get(process.env.REACT_APP_API_AUTH_URL+'/getRefreshOktaToken', { headers: headers })
    .then(response => {
      localStorage.setItem("access_token", response.data.accessToken);
    })
    .catch((error) => {
        console.log('error ' + error);
    });
  }

  const getDeliveryList = (count) => {
    const headers = {
      'Authorization': "Bearer "+localStorage.getItem("access_token")
    };
    axios.get(process.env.REACT_APP_API_AUTH_URL+`/listMonitorDeliveryForm?start=${count}`, { headers: headers })
    .then(response => {
      setRows(response.data.message)
      setTotalCount(parseInt(response.data.totalCount))
    })
    .catch((error) => {
        console.log('error ' + error);
    });
  }


  useEffect(() => {
    getDeliveryList(page)
    

    var interval = setInterval(() => {
      var pageCount = Math.ceil((page+10)/10)
      var total = Math.ceil(totalCount/10)
      if(total > pageCount) {       
        getDeliveryList(page+10)
        setPage(page+10)
      } else {
        getDeliveryList(0)
        setPage(0)
      }
    }, 10000);

    return () => {
      clearInterval(interval);
    };


  }, [page, totalCount]);

  useEffect(() => {


    var tokenInterval = setInterval(() => {
      var today = new Date();
      var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      console.log("API call",time)
      updateToken()
    }, 1200000);
    return () => {
      clearInterval(tokenInterval);
    };

  },[page, totalCount]);

  return (
    <Box sx={{ height: 600, width: '100%',
    '& .MuiDataGrid-footerContainer': {
      display: 'none',          
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
      fontSize: '18px'
    } }}>
        <Typography style={{ paddingBottom:'10px'}} component="h2">
            List Delivery Form
        </Typography>
      <DataGrid
        // pagination
        getRowId={(r) => r.deliveryOrderNo}

        pageSize={10}
        rows={rows}
        page={page}
        columns={columns}
        isRowSelectable={() => false}
        
      />
    </Box>
  );
}
