import store from '../../store';
const initialState = {
    data: {
        "objectId": "",
        "userId": "",
        "entryName": "",
        "provinceId": "",
        "cityId": "",
        "driverContactNo": "",
        "truckNo": "",
        "quantity": 0,
        "quantitystr": 0,
        "cargoType": "",
        "remarks": "",
        "slotDate": "",
        "slotStartTime": "",
        "slotEndTime": "",
        "slotSequence": 0,
        "status": "",
        "createdUser": "",
        "createdDate": "",
        "cancelReason": "",
        "slotId": ""
    },
    slotsAvailable: [],
    slotDates: [],
    formErr: {
        driverContactNo: false,
        provinceId: false,
        cityId: false,
        truckNo: false,
        quantity: false,
        quantitystr: false,
        cargoType: false,
        remarks: false,
        slotDate: false
    },
    getFormAPIResponse: {},
    updateAPIResponse: {},
    completeAPIResponse: {},
    cancelAPIResponse: {},
    slotsAvailableAPIResponse: {}
};

export const GET_DELIVERY_FORM_INFO = 'GET_DELIVERY_FORM_INFO';
export const SET_DELIVERY_FORM_INFO = 'SET_DELIVERY_FORM_INFO';
export const UPDATE_DELIVERY_FORM_INFO = 'UPDATE_DELIVERY_FORM_INFO';
export const SET_DELIVERY_FORM_FIELD = 'SET_DELIVERY_FORM_FIELD';

export const GET_SLOTS_AVAILABLE = 'GET_SLOTS_AVAILABLE';
export const SET_SLOTS_AVAILABLE = 'SET_SLOTS_AVAILABLE';

export const GET_SLOT_DATES = 'GET_SLOT_DATES';
export const SET_SLOT_DATES = 'SET_SLOT_DATES';
export const SET_FORM_ERROR = 'SET_FORM_ERROR';
export const UPDATE_API_RESPONSE = 'UPDATE_API_RESPONSE';
export const COMPLETE_API_RESPONSE = 'COMPLETE_API_RESPONSE';
export const CANCEL_API_RESPONSE = 'CANCEL_API_RESPONSE';
export const GET_FORM_API_RESPONSE = 'GET_FORM_API_RESPONSE';
export const CANCEL_DELIVERY_FORM = 'CANCEL_DELIVERY_FORM';
export const COMPLETE_DELIVERY_FORM = 'COMPLETE_DELIVERY_FORM';
export const SET_SLOTS_AVAILABLE_API_RESPONSE = 'SET_SLOTS_AVAILABLE_API_RESPONSE';


export const getDeliveryFormInfo = (formId) => {
    return {
        type: GET_DELIVERY_FORM_INFO,
        payload: formId
    }
}

export const setDeliveryFormInfo = (data) => {
    return {
        type: SET_DELIVERY_FORM_INFO,
        payload: data
    }
}

export const updateDeliveryFormInfo = (data) => {
    return {
        type: UPDATE_DELIVERY_FORM_INFO,
        payload: {...store.getState().editForm, data: data}
    }
}

export const setDeliveryFormField = (data) => {
    return {
        type: SET_DELIVERY_FORM_FIELD,
        payload: data
    }
}

export const getSlotsAvailable = (data) => {
    return {
        type: GET_SLOTS_AVAILABLE,
        request: data
    }
}

export const setSlotsAvailable = (data) => {
    return {
        type: SET_SLOTS_AVAILABLE,
        payload: data
    }
}

export const getSlotDates = () => {
    return {
        type: GET_SLOT_DATES
    }
}

export const setSlotDates = (data) => {
    return {
        type: SET_SLOT_DATES,
        payload: data
    }
}

export const setFormError = (data) => {
    return {
        type: SET_FORM_ERROR,
        payload: data
    }
}

export const updateAPIResponse = (data) => {
    return {
        type: UPDATE_API_RESPONSE,
        payload: data
    }
}

export const completeAPIResponse = (data) => {
    return {
        type: COMPLETE_API_RESPONSE,
        payload: data
    }
}

export const setSlotsAvailableAPIResponse = (data) => {
    return {
        type: SET_SLOTS_AVAILABLE_API_RESPONSE,
        payload: data
    }
}

export const cancelAPIResponse = (data) => {
    return {
        type: CANCEL_API_RESPONSE,
        payload: data
    }
}

export const getFormAPIResponse = (data) => {
    return {
        type: GET_FORM_API_RESPONSE,
        payload: data
    }
}

export const cancelDeliveryForms = (data) => {
    return {
        type: CANCEL_DELIVERY_FORM,
        payload: data
    }
}

export const completeDeliveryForms = (data) => {
    return {
        type: COMPLETE_DELIVERY_FORM,
        payload: data
    }
}

export const editDeliveryFormReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_DELIVERY_FORM_INFO: {
            return {
                ...state,
                data: action.payload
            }
        }
        case SET_DELIVERY_FORM_FIELD: {
            return {
                ...state,
                
                data: action.payload
            }
        }
        case SET_SLOTS_AVAILABLE: {
            return {
                ...state,
                slotsAvailable: action.payload
            }
        }
        case SET_SLOT_DATES: {
            return {
                ...state,
                slotDates: action.payload
            }
        }
        case SET_FORM_ERROR: {
            return {
                ...state,
                formErr: action.payload
            }
        }
        case UPDATE_API_RESPONSE: {
            return {
                ...state,
                updateAPIResponse: action.payload
            }
        }
        case COMPLETE_API_RESPONSE: {
            return {
                ...state,
                completeAPIResponse: action.payload
            }
        }
        case CANCEL_API_RESPONSE: {
            return {
                ...state,
                cancelAPIResponse: action.payload
            }
        }
        case GET_FORM_API_RESPONSE: {
            return {
                ...state,
                getFormAPIResponse: action.payload
            }
        }
        case SET_SLOTS_AVAILABLE_API_RESPONSE: {
            return {
                ...state,
                slotsAvailableAPIResponse: action.payload
            }
        }
        default:
            return state;
    }
}