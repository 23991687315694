import {Grid, Typography, FormControl, Switch, TextField, Button, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { getSlotDatesCount, updateSlotDatesCount, setSlotDatesCount, updateSlotDatesCountAPIResponse, getBookingsAllowedCount, setBookingsAllowedCount, updateBookingsAllowedCount, updateBookingsAllowedCountAPIResponse } from '../../store/reducers/adminSettings';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
const MAX_SLOT_DAYS_COUNT_ALLOWED = 30;

const AdminSettings = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showSlotDatesCountTooltip, toggleShowSlotDatesCountTooltip] = useState(false);
    const [showAllowedBookingsTooltip, toggleShowAllowedBookingsTooltip] = useState(false);


    useEffect(() => {
        dispatch(getSlotDatesCount());
        dispatch(getBookingsAllowedCount());
    },[dispatch]);

    useEffect(() => {
        if(Object.keys(props.onUpdateSlotDatesCount).length) {
            const res = props.onUpdateSlotDatesCount;
            dispatch(updateSlotDatesCountAPIResponse({}));
            if(res.statusCode === '200') {
                props.snackbar("Updated Successfully!");
            } else if (res.statusCode === '403') {
                navigate('/forbidden');
            } else {
                props.snackbar(res.statusMessage, 'error', 5000);
            }
        }
    });

    useEffect(() => {
        if(Object.keys(props.onUpdateBookingsAllowedCount).length) {
            const res = props.onUpdateBookingsAllowedCount;
            dispatch(updateBookingsAllowedCountAPIResponse({}));
            if(res.statusCode === '200') {
                props.snackbar("Updated Successfully!");
            } else if (res.statusCode === '403') {
                navigate('/forbidden');
            } else {
                props.snackbar(res.statusMessage, 'error', 5000);
            }
        }
    });

    const { numberOfSlotDates, numberOfBookingsAllowed, numberOfBookingsAllowedStatus } = useSelector((state) => state.adminSettings);
    
    const onUpdateSlotDatesCount = () => {
        if(parseInt(numberOfSlotDates) > MAX_SLOT_DAYS_COUNT_ALLOWED){
            return props.snackbar(`Maximum allowed value is ${MAX_SLOT_DAYS_COUNT_ALLOWED}`, 'warning');
        }
        dispatch(updateSlotDatesCount(numberOfSlotDates));
    }

    const onUpdateInput = (e) => {
        const value = e.target.value < 0 ? 0 : e.target.value;
        dispatch(setSlotDatesCount(value));
    }

    const onUpdateBookingsAllowed = (e) => {
        dispatch(setBookingsAllowedCount({numberOfBookingsAllowed:e.target.value, numberOfBookingsAllowedStatus: numberOfBookingsAllowedStatus}));
    }

    const onUpdateBookingsAllowedStatus = (e) => {
        dispatch(setBookingsAllowedCount({numberOfBookingsAllowed: numberOfBookingsAllowed, numberOfBookingsAllowedStatus: e.target.checked}));

    }

    const onSaveBookingsAllowedCount = () => {
        dispatch(updateBookingsAllowedCount({numberOfBookingsAllowed: numberOfBookingsAllowed, numberOfBookingsAllowedStatus: numberOfBookingsAllowedStatus}));
    }

    return (
        <Grid >
            <Typography variant="h5" component="h2">
                Settings
            </Typography>
            <Grid container spacing={3} sx={{ paddingTop: '24px', alignItems: 'center' }}>
                <Grid item xs={12} sm={4}>
                    <FormControl sx={{ minWidth: 120,width: '100%' }}>
                        
                        <TextField
                            id="numberOfSlotDates"
                            label="Number of Open Slot Dates for Supplier"
                            size="small"
                            type="number"
                            inputProps={{min: 0}}
                            value={numberOfSlotDates}
                            onChange={onUpdateInput}
                        />
                    </FormControl>

                </Grid>  
                <Grid item xs={4} sm={3} md={2} lg={1}>
                    <Button variant="contained" onClick={onUpdateSlotDatesCount}
                    endIcon={<Tooltip 
                        onMouseOver={() => {toggleShowSlotDatesCountTooltip(true)}} 
                        onMouseOut={() => {toggleShowSlotDatesCountTooltip(false)}}
                        onClick={() => {toggleShowSlotDatesCountTooltip(true)}} 
     
                        open={showSlotDatesCountTooltip} 
                        title="Update number of open days available for supplier to schedule their booking.">
                            <InfoOutlinedIcon></InfoOutlinedIcon>
                        </Tooltip>}
                    >Update</Button>
                    
                </Grid>
            </Grid>
            <br></br>
            <br></br>
            <br></br>
            <Grid container spacing={3} sx={{ paddingTop: '24px', alignItems: 'center' }}>
                <Grid item xs={12} sm={4}>
                    <FormControl sx={{ minWidth: 120,width: '100%' }}>
                        <TextField
                            id="noOfBookingsAllowed"
                            label="Number of Bookings Allowed to Supplier per Day"
                            size="small"
                            type="number"
                            disabled={!numberOfBookingsAllowedStatus}
                            inputProps={{min: 0}}
                            value={numberOfBookingsAllowed}
                            onChange={onUpdateBookingsAllowed}
                        />
                    </FormControl>

                </Grid>

                
               
                <Grid item xs={4} sm={2.5} md={1.5} lg={1.5}>

                    <Button variant="contained" onClick={onSaveBookingsAllowedCount}
                    endIcon={<Tooltip 
                        onMouseOver={() => {toggleShowAllowedBookingsTooltip(true)}} 
                        onMouseOut={() => {toggleShowAllowedBookingsTooltip(false)}}
                        onClick={() => {toggleShowAllowedBookingsTooltip(true)}} 
     
                        open={showAllowedBookingsTooltip} 
                        title="Update number of slots allowed to a supplier for his bookings in a day">
                            <InfoOutlinedIcon></InfoOutlinedIcon>
                        </Tooltip>}
                    >Update
                    
                    </Button>
                </Grid>
                <Grid item xs={6} sm={3} >
                    <Switch checked={numberOfBookingsAllowedStatus} onChange={onUpdateBookingsAllowedStatus} />
                        {numberOfBookingsAllowedStatus ? 'Active' : 'Inactive'}
                </Grid>
            </Grid>
        </Grid> 
    )
}

const mapStateToProps = (state) => ({
    onUpdateSlotDatesCount: state.adminSettings.updateSlotDatesCountAPIResponse,
    onUpdateBookingsAllowedCount: state.adminSettings.updatenumberOfBookingsAllowedAPIResponse,

});

export default connect(mapStateToProps)(AdminSettings);