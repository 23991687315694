import { useEffect } from 'react';



import { useDispatch, useSelector } from 'react-redux';
import { getDeliveryForms } from '../../../store/reducers/deliveryForms';

import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

import Tooltip from '@mui/material/Tooltip';
import DeliveryForms from '../../shared/deliveryForms/deliveryForms';
import { useNavigate } from 'react-router-dom';
import FormatDate from '../../shared/FormatDate';


const SupplierDeliveryForms = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    useEffect(() => {
        dispatch(getDeliveryForms());
    },[dispatch]);

    const deliveryForms = useSelector((state) => state.deliveryForms);
    const cellTooltip = (value) => {
        return (
            value && value !== ''? 
            <Tooltip title={value} >
                <span className="MuiDataGrid-cellContent">{value}</span>
            </Tooltip> :
            <span className="MuiDataGrid-cellContent">-</span>
        )
    }

    const columns = [
        { field: 'deliveryOrderNumber', headerAlign:'center', headerName: 'Delivery Order No', width: 180, renderCell: (node) => cellTooltip(node.value) },
        { field: 'truckNumber', headerAlign:'center', headerName: 'Truck/Vehicle Plate Number', width: 230, renderCell: (node) => cellTooltip(node.value) },
        { field: 'cargoType',  headerAlign:'center', headerName: 'Cargo Type', width: 120, renderCell: (node) => cellTooltip(node.value) },
        { field: 'remarks', headerAlign:'center', headerName: 'Remarks', width: 120, renderCell: (node) => cellTooltip(node.value) },
        { field: 'status', headerAlign:'center', headerName: 'Status', width: 120, renderCell: (node) => cellTooltip(node.value) },
        { field: 'deliveryDate', headerAlign:'center', headerName: 'Delivery Date', width: 140, 
        renderCell: (node) => {
            return(
                <FormatDate value={node.value} />
            )
        } },
        { 
            field: 'slotStartTime', 
            headerAlign:'center',
            headerName: 'Time Slot', 
            width: 140, 
            sortable: false,
            renderCell: (node) => {

                const slotStart = node.row.slotStartTime ? node.row.slotStartTime.split(' ')[1] : '';
                const slotEnd = node.row.slotEndTime ? node.row.slotEndTime.split(' ')[1] : '';
                const value = `${slotStart.slice(0, -3)}-${slotEnd.slice(0, -3)}`;
                return (
                    <Tooltip title={value} >
                        <span className="MuiDataGrid-cellContent">{value}</span>
                    </Tooltip>
                )
            }
        },
        { 
            field: 'cancellationReason', 
            headerName: 'Cancellation Reason', 
            headerAlign:'center',
            width: 190,
            align: 'center',
            renderCell: (node) => cellTooltip(node.value),
        },
        {
          field: "Actions",
          width: 150,
          sortable: false,
          headerAlign:'center',
          renderCell: (node) => {
            return (
                <div style={{ cursor: 'pointer'}}>
                    {node.row.status === 'Draft' ? 
                    <Tooltip title="EDIT">
                        <EditRoundedIcon onClick={() => navigate('/supplier-edit-delivery/'+node.id)}  color="primary" />
                    </Tooltip> : 
                    <Tooltip title="VIEW">
                        <VisibilityRoundedIcon onClick={() => navigate('/supplier-view-delivery/'+node.id)}  color="primary" />
                    </Tooltip>}
                </div>
            );
          }
        }
    ];

    const onSearch = () => {
        dispatch(getDeliveryForms());
    }
    return (
            <DeliveryForms role="supplier" columns={columns} deliveryForms={deliveryForms} 
                search={onSearch} 
            />       
    )
}

export default SupplierDeliveryForms;