import { POST, GET } from '../requests/requests';

import { put, call } from 'redux-saga/effects';
import { addTemplateAPIResponse, setTemplateDetails, setTemplateFieldValidation } from '../../store/reducers/templateForm';

export function* handleAddTemplateRequest (params) {
    try {
        const data = params.payload;
        const url = `${process.env.REACT_APP_API_AUTH_URL}/saveTemplateAndSlots`;
        const response = yield call (POST, {url: url, data});
        // const { message, statusCode, statusMessage } = response.data;
        yield put (addTemplateAPIResponse(response.data));
    } catch ({response}) {
        yield put (addTemplateAPIResponse(response.data));
    }
}

export function* handleGetTemplateRequest (params) {
    try {
        const url = `${process.env.REACT_APP_API_AUTH_URL}/viewTemplateDetails?templateId=${params.payload}`;
        const response = yield call (GET, {url: url});
        const json = response.data.message[0];
        yield put (setTemplateDetails({...json, templateStatus: json.templateStatus === 'Active', templateState: json.templateState === 'Default', slotsInTemplate: json.slotsInTemplate.map((item) => {return {...item, timeSlotEnd: item.timeSlotEnd.length > 5 ? item.timeSlotEnd.slice(0,-3) : item.timeSlotEnd, timeSlotStart: item.timeSlotStart.length > 5 ? item.timeSlotStart.slice(0,-3) : item.timeSlotStart }})}));
        yield put (setTemplateFieldValidation({templateName: !(json.templateName && json.templateName.length > 0)}));

    } catch ({response}) {
        yield put (setTemplateDetails(response.data));
    }
}