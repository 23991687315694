
import { makeStyles } from '@material-ui/core/styles';
import { createFilterOptions } from '@mui/material/Autocomplete';

export const formatDate = (value, format) => {
    if(!value) {
        return;
    }
    const date = new Date(value);
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const yyyy = date.getFullYear();

    const s = date.getSeconds();
    const m = date.getMinutes();
    const h = date.getHours();

    switch (format) {
        case 'dd-mm-yyyy': {
            return `${dd}-${mm}-${yyyy}`;
        }
        case 'mm-dd-yyyy': {
            return `${mm}-${dd}-${yyyy}`;
        }
        case 'yyyy-mm-dd': {
            return `${yyyy}-${mm}-${dd}`;
        }
        case 'mm/dd/yyyy h:m:s': {
            return `${mm}/${dd}/${yyyy} ${h}:${m}:${s}`;
        }
        case 'mm/dd/yyyy': {
            return `${mm}/${dd}/${yyyy}`;
        }
        case 'mm-dd-yyyy-hh-mm-ss': {
            return `${mm}-${dd}-${yyyy}-${h}-${m}-${s}`;
        }        
        default:
            return `${dd}/${mm}/${yyyy}`;
    }
}

export const objectToQuery = (params) => {
    return Object.keys(params).map(function (key) {
        return key + '=' + (params[key] ? params[key] : '')
    }).join('&');
}

export const gridFocusNone = makeStyles({
    root: {
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: 'none',
        },
        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {
            outline: 'none',
        },
    }
});

export const MonthShortName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep','Oct','Nov','Dec'];

const SLOT_DAY_START_TIME = 6;
const SLOT_DAY_END_TIME = 21;

const getTimeFragment = (i) => {
    switch(i%2) {
        case 1: {
            return '00'
        }
        default: {
            return '30'
        }
    }
}

export const generateTimeSlots = () => {
    let arr = [];
    for(let i=SLOT_DAY_START_TIME; i<=SLOT_DAY_END_TIME; i++) {
        const index = i * 2;
        const limit = i < SLOT_DAY_END_TIME ? 2 : 1;
        for(let j=1; j<=limit; j++) {
            const slot = `${index < 20 ? '0' : ''}${Math.floor(index / 2)}:${
                getTimeFragment(j)
                             }`
            arr.push({
                title: slot,
                inputValue: slot
            }) 
        }
    }
    return arr;
}

export const filterOptions = (options, params) => {
    const filter = createFilterOptions();
    const filtered = filter(options, params);

    const { inputValue } = params;
    if(inputValue.match("^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$") != null){
        const isExisting = options.some((option) => inputValue === option.title);
        if (inputValue !== '' && !isExisting) {
        filtered.push({
            inputValue,
            title: `Add "${inputValue}"`,
        });
        }
    }
    
    return filtered;
}

export const getOptionLabel = (option) => {
    if (typeof option === 'string') {
      return option;
    }
    if (option.inputValue) {
      return option.inputValue;
    }
    return option.title;
}

export const STATION_ID = {
    "Weigh-In-Gross": 1,
    "Dumping-In": 2,
    "Dumping-Out": 3,
    "Weigh-In-Tare": 4
}