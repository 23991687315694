import React, { Component } from 'react';
import { QrReader } from 'react-qr-reader';

class SecurityScan extends Component {


    render() {

        return (
                // <Layout>
                        <div>
                            <QrReader videoStyle={{width: 'none'}} videoContainerStyle ={{height: '500px'}}  containerStyle={{ width: '100%', margin: 'auto', padding: '10px' }}
                                onResult={(result, error) => {
                                    if (!!result) {
                                        window.location.replace(result.text);
                                    }

                                    if (!!error) {
                                        console.info(error);
                                    }
                                    }}
                            />
                            </div>
                // </Layout>
        );
    }
}
 
export default SecurityScan;