import { GET, PUT } from '../requests/requests';
import { setSlotDatesCount, setBookingsAllowedCount, updateSlotDatesCountAPIResponse, updateBookingsAllowedCountAPIResponse } from '../../store/reducers/adminSettings';
import { put, call } from 'redux-saga/effects';


export function* handleSetSlotDatesCountRequest (params) {
    try {
        const url = `${process.env.REACT_APP_API_AUTH_URL}/configureSlotDatesCount`;
        const response = yield call (PUT, {url: url, data: params.payload});
        yield put(updateSlotDatesCountAPIResponse(response.data));
    } catch ({response}) {
        yield put(updateSlotDatesCountAPIResponse(response.data));
    }
}

export function* handleGetSlotDatesCountRequest () {
    try {
        const url = `${process.env.REACT_APP_API_AUTH_URL}/getSlotDatesCount`;
        const response = yield call (GET, {url: url});
        const {message} = response.data;
        yield put(setSlotDatesCount(message[0].numberOfSlotDates));
    } catch (err) {
        console.log(err);
    }
}

export function* handleGetBookingsAllowedCountRequest () {
    try {
        const url = `${process.env.REACT_APP_API_AUTH_URL}/bookingsAllowed`;
        const response = yield call (GET, {url: url});
        const {message} = response.data;
        yield put(setBookingsAllowedCount({numberOfBookingsAllowed: message[0].numberOfBookingsAllowed, numberOfBookingsAllowedStatus: message[0].status === 'Active'}));
    } catch (err) {
        console.log(err);
    }
}

export function* handleSetBookingsAllowedCountRequest (params) {
    try {
        const url = `${process.env.REACT_APP_API_AUTH_URL}/configureBookingDaysCount`;
        const response = yield call (PUT, {url: url, data: params.payload});
        yield put(updateBookingsAllowedCountAPIResponse(response.data));
    } catch ({response}) {
        yield put(updateBookingsAllowedCountAPIResponse(response.data));
    }
}