import React,{ useState, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import okta from './okta';

export default function PrivateRoute() {

    const axios = require('axios');
    const [isLoaded, setIsLoaded] = useState(false);  
    const navigate = useNavigate();
    const supplier_id = process.env.REACT_APP_SUPPLIER_ID
    const security_id = process.env.REACT_APP_SECURITY_GUARD_ID
    const driver_id = process.env.REACT_APP_DRIVER_ID

    const decodeToken = (accessToken) => {

      var base64Url = accessToken.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    }


    useEffect(() => {

      okta.signIn();
      const signIn = okta.oktaSignInClient;
      signIn.authClient.session.get().then((res) => {
        // Internal signin
        if (res.status === "ACTIVE") {
          const headers = {
            'Authorization': "Bearer "+localStorage.getItem("access_token")
          };
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_AUTH_URL+'/getGroupsByUserId',
            data: {         
            },headers: headers
          })
          .then((response) => {
            var userDetails = decodeToken(localStorage.getItem("access_token"));
            var userGroups = userDetails.groups;
            if(response.data.statusCode === '200'){
                if (window.location.pathname.includes('/supplier') && userGroups.includes("COPRA_PH_SlotBooking_Supplier")){
                  setIsLoaded(true)
                }else if(window.location.pathname.includes('/security') && userGroups.includes("COPRA_PH_SlotBooking_SGuard")){
                  setIsLoaded(true)
                }
                else if(window.location.pathname.includes('/driver') && userGroups.includes("COPRA_PH_SlotBooking_Driver")){
                  setIsLoaded(true)
                } 
                else if(window.location.pathname.includes('/monitor') && userGroups.includes("COPRA_PH_SlotBooking_Monitor")){
                  setIsLoaded(true)
                } 
                //TODO: Temporary fix for admin login in stage env, need permanent fix
                else if(window.location.pathname.includes('/admin') && userGroups.includes("COPRA_PH_SlotBooking_AM")){
                  setIsLoaded(true)
                }

                else if(window.location.pathname.includes('/scanner') && (userGroups.includes("COPRA_PH_SlotBooking_Scanner_Staff") || userGroups.includes("COPRA_PH_SlotBooking_SCANNER_STAFF"))){
                  setIsLoaded(true)
                }
                else if(window.location.pathname.includes('/shared')){
                  setIsLoaded(true)
                }
                // else if(window.location.pathname.includes('/driver')){
                //   setIsLoaded(true)

                // }
                // else if(window.location.pathname.includes('/security')){
                //   setIsLoaded(true)
                  
                // }
                else if(window.location.pathname === '/'){
                  if ((/@crgl/).test(userDetails.sub.toLowerCase()) || (userDetails.email && (/@cargill/).test(userDetails.email.toLowerCase()))) {
                    navigate("/admin-view-delivery");
                    setIsLoaded(true);
                    return;
                  } else {
                    var roleId = response.data.message[0].roleid;
                    switch(roleId) {
                      case process.env.REACT_APP_SUPPLIER_ID: {
                        navigate("/supplier-home");
                        break;
                      }
                      case process.env.REACT_APP_SECURITY_GUARD_ID: {
                        navigate("/security-home");
                        break;
                      }
                      case process.env.REACT_APP_DRIVER_ID: {
                        navigate("/driver-home");
                        break;
                      }
                      default:
                        navigate('/login');
                    }
                  }
                }
                else{
                  navigate('/forbidden');
                }
            }
          })
          .catch((error) => {
            okta.getAccountClosed();
            navigate('/login?fromUrl='+window.location.pathname);
          });
        } else {
          okta.getAccountClosed();
            navigate('/login?fromUrl='+window.location.pathname);
        }
      });
    },[axios, driver_id, navigate, security_id, supplier_id]);

        return (   
            <div>
                {(() => {
                  if (isLoaded){
                      return isLoaded ? <Outlet /> : <Navigate to={"/login?fromUrl="+window.location.pathname} />;
                  } else{
                    return (
                      <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ width: 1, height: "100vh" }}
                    >
                      <CircularProgress />
                    </Stack>
                      )
                  }                  
                })()}
            </div>
        )
}