const initialState = {
    isOpen: false
};

const SET_MENU_OPEN = 'SET_MENU_OPEN';

export const setMenuOpen = (flag) => {
    return {
        type: SET_MENU_OPEN,
        payload: flag
    }
}

export const leftNavMenuReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_MENU_OPEN: {
            return {
                ...state,
                isOpen: action.payload
            }
        }
        default:
            return state;
    }
}