import { useEffect } from 'react';
import { Grid, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getDeliveryForms } from '../../../store/reducers/deliveryForms';

import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';

import Tooltip from '@mui/material/Tooltip';
import DeliveryForms from '../../shared/deliveryForms/deliveryForms';
import { useNavigate } from 'react-router-dom';
import FormatDate from '../../shared/FormatDate';

const DriverDeliveryForms = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getDeliveryForms());
    },[dispatch]);

    const deliveryForms = useSelector((state) => state.deliveryForms);

    const cellTooltip = (value) => {
        return (
            value && value !== ''? 
            <Tooltip title={value} >
                <span className="MuiDataGrid-cellContent">{value}</span>
            </Tooltip> :
            <span className="MuiDataGrid-cellContent">-</span>
        )
    }

    const columns = [
        { field: 'deliveryOrderNumber', headerAlign:'center', headerName: 'Delivery Order No', width: 180, renderCell: (node) => cellTooltip(node.value) },
        { field: 'supplier', headerAlign:'center', headerName: 'Supplier', width: 120, renderCell: (node) => cellTooltip(node.value) },
        { field: 'truckNumber', headerAlign:'center', headerName: 'Truck/Vehicle Plate Number', width: 230, renderCell: (node) => cellTooltip(node.value) },
        { field: 'cargoType',  headerAlign:'center', headerName: 'Cargo Type', width: 120, renderCell: (node) => cellTooltip(node.value) },
        { field: 'remarks', headerAlign:'center', headerName: 'Remarks', width: 120, renderCell: (node) => cellTooltip(node.value) },
        { field: 'status', headerAlign:'center', headerName: 'Status', width: 120, renderCell: (node) => cellTooltip(node.value) },
        { field: 'deliveryDate', headerAlign:'center', headerName: 'Delivery Date', width: 140, renderCell: (node) => {
            return (
                    <FormatDate value={node.value} />
                
            )
        } },
        { 
            field: 'slotStartTime', 
            headerAlign:'center',
            headerName: 'Time Slot', 
            width: 140, 
            sortable: false,
            renderCell: (node) => {
                const slotStart = node.row.slotStartTime ? node.row.slotStartTime.split(' ')[1] : '';
                const slotEnd = node.row.slotEndTime ? node.row.slotEndTime.split(' ')[1] : '';
                const value = `${slotStart.slice(0, -3)}-${slotEnd.slice(0, -3)}`;
                return (
                    <Tooltip title={value} >
                        <span className="MuiDataGrid-cellContent">{value}</span>
                    </Tooltip>
                )
            }
        },
        { 
            field: 'cancellationReason', 
            headerName: 'Cancellation Reason', 
            headerAlign:'center',
            align: 'center',
            width: 200,
            renderCell: (node) => cellTooltip(node.value)
        },
        {
          field: "Actions",
          width: 150,
          headerAlign:'center',
          sortable: false,
          renderCell: (node) => {
            return (
                <div > 
                    <IconButton  aria-label="View">
                        <Tooltip title="VIEW">
                            <VisibilityRoundedIcon  onClick={() => navigate('/driver-view-delivery/'+node.id)}  color='primary' />
                        </Tooltip>
                    </IconButton>
                </div>
            );
          }
        }
    ];

    const onSearch = () => {
        dispatch(getDeliveryForms());
    }
    
    return (
            <Grid >
                <DeliveryForms role="driver" columns={columns} deliveryForms={deliveryForms} 
                    search={onSearch} 
                />
            </Grid>
        )
}

export default DriverDeliveryForms;