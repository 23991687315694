import React, { Component } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
// import Image from './truck.jpg';
import Image from '../../assets/images/COPRA.jpg';
import CssBaseline from '@mui/material/CssBaseline';
// import { Typography } from '@mui/material';
import { Hidden } from '@mui/material';

class AuthLayout extends Component {

    render() {   
        
        return (
                <Grid container component="main" sx={{ height: '100vh', overflow: 'hidden' }}>
                <CssBaseline />
                <Hidden smDown={true}>
                    <Grid item xs={false} sm={4} md={7}
                        sx={{
                            height: '100%',
                            backgroundImage: `url(${Image})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                            backgroundSize: 'cover',
                            backgroundPosition: 'bottom right',
                        }}>
                            {/* {RenderAppTitle()}  */}
                    </Grid>
                </Hidden>
                <Grid sx={{ height: '100%', overflowY: 'scroll' }} item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    {/* <Hidden smUp={true}> */}
                    {/* {RenderAppTitle('mobile')}  */}
                    {/* </Hidden> */}
                
                    { this.props.children }
                </Grid>
                </Grid>            
        );
    }
}
 
export default AuthLayout;