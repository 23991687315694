
import { GET, PUT } from '../requests/requests';
import { setDeliveryFormInfo, setDeliveryFormInfoErr, updateDeliveryFormStatusAPIResponse } from '../../store/reducers/scannerViewDelivery';
import { put, call } from 'redux-saga/effects';


export function* handleGetDeliveryFormDetailsRequest (params) {
    try {
        const url = `${process.env.REACT_APP_API_AUTH_URL}/viewDeliveryForm?objectId=${params.payload}`;
        const response = yield call (GET, {url: url});
        yield put (setDeliveryFormInfo(response.data.message[0]));
    } catch ({response}) {
        yield put (setDeliveryFormInfoErr(response));
    }
}

export function* handleUpdateDeliveryFormStatusRequest (params) {
    try {
        const url = `${process.env.REACT_APP_API_AUTH_URL}/updateDeliveryStatusAllowSkip?objectId=${params.payload.formId}&stationId=${params.payload.stationId}`;
        const response = yield call (PUT, {url: url});
        yield put (updateDeliveryFormStatusAPIResponse(response.data));
    } catch ({response}) {
        yield put (updateDeliveryFormStatusAPIResponse(response));
    }
}