import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { useLocation } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import AuthLayout from '../layout/AuthLayout';
import { LogoRenderer } from '../shared/Logo';

export default function RegisterSuccess() {
    const location = useLocation();

    return (
        <AuthLayout>
            <Container component="main" maxWidth="xs" style={{height: '500px'}}>
            <CssBaseline />
            <Box
                sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                }}
            >      
                <LogoRenderer /> 
                <Typography component="h1" variant="h5" display="flex" alignItems="center">
                Information 
                    <Avatar sx={{ m: 1, bgcolor: '#279989' }}>
                        <InfoIcon />
                    </Avatar>
                </Typography>                   
                <div>
                    <p>Check your inbox
                        We just emailed a link to {location.state.email}. Click the link in the email to complete your account set up.
                        Don't see the email? Search your spam folder for emails from customer_account@cargill.com.</p>
                </div>
            </Box>
            </Container>

        </AuthLayout>
    );
}