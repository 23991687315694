import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import axios from 'axios';
import {useLocation} from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import InfoIcon from '@mui/icons-material/Info';
import '../../App.css'


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
    overflow:'scroll',
    height: '400px',
    p: 4,
   
};

export default function BasicModal() {
  const [open, setOpen] = useState(true);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const location = useLocation();
  

  const [tableData, setTableData] = useState();
  const roleid = `${location.state.roleid}`;

      const handleSubmit = (e)=>{
        e.preventDefault();
      
        const headers = {
      'Authorization': "Bearer " + localStorage.getItem("access_token")
       };
       console.log("headers"+headers);
        axios({
          method: 'post',
          url: process.env.REACT_APP_API_AUTH_URL + `/users/factors?mobileNumber=${tableData}`,
          data: {
          }, headers: headers
        })
      // axios.post(process.env.REACT_APP_API_AUTH_URL+`/users/factors?mobileNumber=${tableData}`, { headers: headers })
     
    
      .then((response) =>{
        const userid = response.data.id;
         navigate("/shared-phonenumber-verify",{ state: {
              userId: userid, roleid:roleid}
            });
        // alert(response.data.id);
       
       })
      
      .catch((error) => {
          console.log('error ' + error);
      });
      }
     



  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow:'scroll', backgroundColor:'#279989' }}
      >
        <Box sx={style} component="form" onSubmit={handleSubmit}>
      
           <Typography variant="h6" gutterBottom>
                                Update Phone Number
                            </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <Grid container spacing={1}>
          <Grid item xs={12} sm={1}>
          <Avatar sx={{ m: 1, bgcolor: '#279989' }}>
                        <InfoIcon />
                    </Avatar>
                    </Grid>
                    <Grid item xs={12} sm={11}>
                    <p><h4>Please Update Your Mobile Phone Number</h4></p>
                    <p> As per our records, your mobile phone number was not updated during registration,
                      it is required to keep you updated with status of delivery, please enter your phone number below -</p>
                    </Grid>
                    </Grid>
          
          </Typography><br/>
                  <Grid container spacing={3}>  
           <Grid item xs={12} sm={12}>
                                <FormControl sx={{ m: 1, minWidth: 120,width: '100%' }}>

                                <TextField
                                    id="phonenumber"
                                    name="phonenumber"
                                    label="Phone Number"
                                    fullWidth
                                    autoComplete="phone-number"
                                    variant="standard"
                                    helperText="Please enter your phone number with prefix of your country code. E.g If your country is Philipines, enter  63XXXXXXXXXX"
                                    onChange={(e)=>setTableData(e.target.value)}
                                    required                   
                                />
                                </FormControl>

                        </Grid>
                         <Grid item xs={12} sm={11}>
                    <p></p>
                    
                    </Grid>
                        <Grid item xs={12} sm={12}>

                            <FormControl sx={{ m: 1 }}>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    
                                    >
                                    Update
                                </Button>
                                </FormControl>
                            </Grid>
                        </Grid>
                       
           </Box>
      </Modal>
    </div>
  );
}
