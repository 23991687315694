import { GET, POST, PUT } from '../requests/requests';
import { setPendingForms, setChangesSummary, updateResolveConflictsApiResponse } from '../../store/reducers/bulkEdit';
import { put, call } from 'redux-saga/effects';


export function* handleGetPendingFormsRequest () {
    try {
        const url = `${process.env.REACT_APP_API_AUTH_URL}/fetchUnhandledFormsOfCurentDay`;
        const response = yield call (GET, {url: url});
        yield put (setPendingForms({
            list: response.data.message,
            listAPIResponse: {statusCode: response.data.statusCode}
        }));

    } catch ({response}) {
        yield put (setPendingForms({
            list: [],
            listAPIResponse: {statusCode: response.data.statusCode}
        }));
    }
}

export function* handleGetChangesSummaryRequest (params) {
    try {
        const url = `${process.env.REACT_APP_API_AUTH_URL}/reArrangeAndCheckConflicts`;
        const response = yield call (POST, {url: url, data: params.payload});
        yield put (setChangesSummary({
            changesSummary: response.data.message[0].formsReArranged,
            conflicts: response.data.message[0].formsConflicted,
            nextDateToReArrangeUnhandledForms: response.data.nextDateToReArrangeUnhandledForms,
            changesSummaryAPIResponse: {statusCode: response.data.statusCode}
        }));

    } catch ({response}) {
        yield put (setChangesSummary({
            changesSummary: [],
            formsConflicted: [],
            nextDateToReArrangeUnhandledForms: "",
            changesSummaryAPIResponse: {statusCode: response.data.statusCode}
        }));
    }
}

export function* handleResolveConflictedSlotsRequest (params) {
    try {
        const { nextDayStartTime, nextDayEndTime, nextAvailableDate, request } = params.payload;
        const url = `${process.env.REACT_APP_API_AUTH_URL}/updateReArrangedAndConflictResolvedForms?reArrangedStartTime=${nextDayStartTime}&reArrangedEndTime=${nextDayEndTime}&&reArrangedDate=${nextAvailableDate}`;
        const response = yield call (PUT, {url: url, data: request});
        yield put (updateResolveConflictsApiResponse(response.data));
    } catch ({response}) {
        yield put (updateResolveConflictsApiResponse(response.data));

    }
}

