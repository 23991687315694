import { GET } from '../requests/requests';
import { setCargoTypes, setRemarks, setCancellationReasons, setFormStatuses, setCities, setProvinces, setVehiclePlateNumbers } from '../../store/reducers/utils';
import { put, call } from 'redux-saga/effects';

export function* handleGetCargoType () {
    try {
        const url = `${process.env.REACT_APP_API_AUTH_URL}/cargotypes`;
        const response = yield call (GET, {url: url});
        const { message } = response.data;
        yield put (setCargoTypes({cargoTypes: message}));
    } catch (err) {
        console.log(err);
    }
}

export function* handleGetRemarks () {
    try {
        const url = `${process.env.REACT_APP_API_AUTH_URL}/remarks`;
        const response = yield call (GET, {url: url});
        const { message } = response.data;
        yield put (setRemarks({remarks: message}));
    } catch (err) {
        console.log(err);
    }
}

export function* handleGetCancellationReasons () {
    try {
        const url = `${process.env.REACT_APP_API_AUTH_URL}/cancellationReasonsList`;
        const response = yield call (GET, {url: url});
        let { message } = response.data;
        message.push({ id: 99, name: "Others" });
        yield put (setCancellationReasons({cancellationReasons: message}));
    } catch (err) {
        console.log(err);
    }
}

export function* handleGetFormStatuses () {
    try {
        const url = `${process.env.REACT_APP_API_AUTH_URL}/statusList`;
        const response = yield call (GET, {url: url});
        const { message } = response.data;
        yield put (setFormStatuses({statusList: message}));
    } catch (err) {
        console.log(err);
    }
}

export function* handleGetCities (params) {
    try {
        const url = `${process.env.REACT_APP_API_AUTH_URL}/cities?provinceId=${params.payload}`;
        const response = yield call (GET, {url: url});
        const { message } = response.data;
        yield put (setCities({cities: message}));
    } catch (err) {
        console.log(err);
    }
}

export function* handleGetProvinces () {
    try {
        const url = `${process.env.REACT_APP_API_AUTH_URL}/provinces`;
        const response = yield call (GET, {url: url});
        const { message } = response.data;
        yield put (setProvinces({provinces: message}));
    } catch (err) {
        console.log(err);
    }
}

export function* handleGetRecentFiveVehicleNumbers (params) {
    try {
        const url = `${process.env.REACT_APP_API_AUTH_URL}/recentFiveTruckRegNos?userId=${params.payload}`;
        const response = yield call (GET, {url: url});
        const { message } = response.data;
        yield put (setVehiclePlateNumbers({vehiclePlateNumbers: message}));
    } catch (err) {
        console.log(err);
    }
}