import ViewTemplateForm from './viewTemplateForm';
import { getTemplateDetails } from '../../store/reducers/templateForm';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const ViewTemplate = (props) => {
    const dispatch = useDispatch();
    const params = useParams();

    useEffect(() => {
        dispatch(getTemplateDetails(params.id));
    });

    return (
        <ViewTemplateForm mode="edit" {...props} />
    )
}

export default ViewTemplate;