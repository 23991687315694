const dataValidation = {
    templateName: true
}

export const slotData = {
    timeSlotStart: '06:00',
    timeSlotEnd: '06:30',
    numberOfSlots: '1',
    cargoType: 'Bulk',
    slotStatus: 'Active'
}

const initialState = {
    data: {
        templateName: '',
        slotsInTemplate: [],
        templateStatus: true,
        templateState: false,
        validate: true
    },
    isChanged: false,
    addTemplateAPIResponse: {},
    validation: dataValidation,
    slotErrorIndex: null
}



export const ADD_TEMPLATE = 'ADD_TEMPLATE';
export const CLEAR_TEMPLATE = 'CLEAR_TEMPLATE';
export const SET_TEMPLATE_SLOT_FIELDS = 'SET_TEMPLATE_SLOT_FIELDS';
export const SET_TEMPLATE_FIELDS = 'SET_TEMPLATE_FIELDS';
export const SET_TEMPLATE_FIELD_VALIDATION = 'SET_TEMPLATE_FIELD_VALIDATION';
export const ADD_SLOT = 'ADD_SLOT';
export const REMOVE_SLOT = 'REMOVE_SLOT';
export const ADD_TEMPLATE_API_RESPONSE = 'ADD_TEMPLATE_API_RESPONSE';
export const SET_SLOT_ERROR_INDEX = 'SET_SLOT_ERROR_INDEX';
export const GET_TEMPLATE_DETAILS = 'GET_TEMPLATE_DETAILS';
export const SET_TEMPLATE_DETAILS = 'SET_TEMPLATE_DETAILS';

export const addTemplate = (data) => {
    data.templateState = data.templateState ? 'Default' : null;
    data.templateStatus = data.templateStatus ? 'Active' : 'Inactive';
    return {
        type: ADD_TEMPLATE,
        payload: data
    }
}

export const setTemplateFields = (data) => {
    return {
        type: SET_TEMPLATE_FIELDS,
        payload: data
    }
}

export const setTemplateFieldValidation = (data) => {
    return {
        type: SET_TEMPLATE_FIELD_VALIDATION,
        payload: data
    }
}

export const setSlotErrorIndex = (data) => {
    return {
        type: SET_SLOT_ERROR_INDEX,
        payload: data
    }
}


export const addTemplateAPIResponse = (data) => {
    return {
        type: ADD_TEMPLATE_API_RESPONSE,
        payload: data
    }
}

export const initTemplate = () => {
    return {
        type: CLEAR_TEMPLATE
    }
}

export const getTemplateDetails = (templateId) => {
    return {
        type: GET_TEMPLATE_DETAILS,
        payload: templateId
    }
}

export const setTemplateDetails = (data) => {
    return {
        type: SET_TEMPLATE_DETAILS,
        payload: data
    }
}

export const templateFormReducer = (state = initialState, action) => {
    switch(action.type) {
        case ADD_TEMPLATE_API_RESPONSE: {
           return {
            ...state,
            addTemplateAPIResponse: action.payload
           }
        }
        case SET_TEMPLATE_FIELDS: {
            return {
             ...state,
             data: action.payload,
             isChanged: true,
            }
        }
        case SET_TEMPLATE_FIELD_VALIDATION: {
            return {
             ...state,
             validation: action.payload
            }
        }
        case SET_SLOT_ERROR_INDEX: {
            return {
             ...state,
             slotErrorIndex: action.payload
            }
        }
        case CLEAR_TEMPLATE: {
            return {
             ...state,
             data: {...initialState.data, slotsInTemplate: [{...slotData}]},
             validation: dataValidation
            }
        }
        case SET_TEMPLATE_DETAILS: {
            return {
             ...state,
             data: action.payload,
             isChanged: false
            }
        }
        default:
            return state
    }
}