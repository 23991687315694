import React, { Component } from 'react';
import {Grid, Typography} from '@mui/material';
import ScanQrCode from '../../shared/scan'

class SecurityHome extends Component {

    constructor(props) {
        super(props);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleModalOpen = this.handleModalOpen.bind(this);
        this.state = {role: '', isModalOpen: false};
      }

    handleModalClose(){
        this.setState({isModalOpen: false})
    }

    handleModalOpen(){
        this.setState({isModalOpen: true})
    }

    onScan(result){
        window.location.replace(result.text);
    }
    render() {

        return (
                <>
                {/* <Layout> */}
                    <Grid >
                            <Typography  variant="h5" component="h2">
                                Welcome to Cargill Operations Planning Resource Application    
                            </Typography>
                            <ScanQrCode onScan={this.onScan} />
                        </Grid>

                {/* </Layout> */}
                {/* <Footer /> */}
                </>

        );
    }
}
 
export default SecurityHome;