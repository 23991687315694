import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const CopyrightRenderer = (props) => {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit">
          Cargill
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
}

export default CopyrightRenderer;