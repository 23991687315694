import * as React from 'react';
import { useState, useEffect  } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import AuthLayout from '../layout/AuthLayout';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import FormHelperText from '@mui/material/FormHelperText';
import validator from 'validator';
import { LogoRenderer } from '../shared/Logo';
import CopyrightRenderer from '../shared/Copyright';


export default function Register() {
  const axios = require('axios');
  const navigate = useNavigate();
  const [roleId, setRoleId] = useState('');
  const [roleName, setRoleName] = useState('');
  const [roleList, setRoleList] = useState([]);
  const [firstNameErr, setFirstNameErr] = useState(false);
  const [lastNameErr, setLastNameErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [roleErr, setRoleErr] = useState(false);

  const [formError, setFormError] = useState(false);
  const [formErrMsg, setFormErrMsg] = useState('We found some errors. Please review the form and make corrections.');
  const [formCargillError, setFormCargillError] = useState(false);
  const [formCargillErrMsg, setCargillFormErrMsg] = useState('You are using Cargill ID. Please signup using different mail id.');
  const [captchaError, setCaptchaError] = useState(false)
  const [captchaFormError, setCaptchaFormError] = useState(false)

  useEffect(() => {
    const headers = {
      'Authorization': process.env.REACT_APP_API_AUTHORIZATION
    };
    axios({
      method: 'post',
      url: process.env.REACT_APP_API_URL+'/getGroups',
      data: {
      },headers: headers
    })
    .then(response => setRoleList(response.data.message));
  },[axios]);
  
  const formValidation = (data) => {
    setFirstNameErr(false);
    setLastNameErr(false);
    setEmailErr(false);
    setRoleErr(false);

    let email=data.get('email');
    let regexp = /.+@(cargill|crgl-thirdparty)\.com$/;
    if(regexp.test(email.toLowerCase())){
    setFormCargillError(true);
    setCargillFormErrMsg('You are using Cargill ID. Please signup using different mail id.');
    return(false);
    }  

    if(data.get('firstName') === ''){
      setFirstNameErr(true);
    }
    if(data.get('lastName') === ''){
      setLastNameErr(true);
    }
    if(data.get('email') === '' || !validator.isEmail(data.get('email'))){
      setEmailErr(true);
    } 
    
    if(roleName === ''){
      setRoleErr(true);
    }     

    if(captchaError === true){
      setCaptchaFormError(false);
    }else{
      setCaptchaFormError(true);  
    }   

    if(data.get('firstName') !== '' && data.get('lastName') !== '' && data.get('email') !== '' && validator.isEmail(data.get('email')) && roleName !== '' && !captchaFormError){
      return true
    }else {
      setFormError(true)
      return false
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const headers = {
      'Authorization': process.env.REACT_APP_API_AUTHORIZATION,
    };

    const validation = formValidation(data);
    if(validation){
      axios({
        method: 'post',
        url: process.env.REACT_APP_API_URL+'/adduser',
        data: {
          "firstname": data.get('firstName'),
          "lastname": data.get('lastName'),
          "userid": data.get('email'),
          "userRole": [
              {
                  "rolename": roleName,
                  "roleid": roleId
              }
          ]
        },headers: headers
      })
        .then((response) => {
          if (response.data.statusCode === '200') {
            navigate('/register-success', { state: { email: data.get('email') } })
          } else if (response.data.statusCode === '500' && (response.data.statusMessage === 'User Already Exists in OKTA' || response.data.statusMessage === 'There is already an account registered with this email. Sign in')) {
            navigate('/welcom-login',
              {
                state: {
                  firstname: data.get('firstName'),
                  lastname: data.get('lastName'),
                  userid: data.get('email'),
                  rolename: roleName,
                  roleid: roleId
                }
              });
          } else if (response.data.statusCode === '500') {
            setFormErrMsg(response.data.statusMessage)
            setFormError(true)
          }
          //--end--
        })
      .catch((error) => {
        console.log('Error', error.message);
      });
    }
  };


  const recaptchaRef = React.createRef();

  function onChangeCapcha(value) {
    setCaptchaError(true)
  }

  function onChangeFirstName(event) {
    setFirstNameErr(false);
  }

  function onChangeLastName(event) {
    setLastNameErr(false);
  }

  function onChangeEmail(event) {
    if(validator.isEmail(event.target.value)){
      setEmailErr(false);
    } else if((emailErr || !validator.isEmail(event.target.value)) && formError) {
      setEmailErr(true);
    }
  }
  
  const onChangeRole = (event, child) => {
    setRoleErr(false)  
    setRoleId(child.props.id)
    setRoleName(event.target.value)
  };

  const policyLink = encodeURI("https://www.cargill.com/privacy");


  return (
    <AuthLayout>

          <Box 
            sx={{
              paddingTop: 5,
              paddingBottom: 5,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '78%',
              margin: 'auto',
            }}
          >
            {LogoRenderer(false)}
            <Typography sx={{
              lineHeight: '1.5',
              marginTop: '10px',
              fontFamily: '"montserrat"',
              width: "314px",
              color: '#5e5e5e'
            }} component="div" display="flex" alignItems="center">

              <h2 className="okta-form-title o-form-head">Cargill Operations Planning Resource Application</h2>
            </Typography>
            <Typography component="h4" variant="h5" display="flex" alignItems="center"
            sx={{
              fontFamily: '"montserrat"',
              fontSize: '1rem'
            }}>
              Sign up 
              <Avatar sx={{ m: 1, bgcolor: '#279989' }}>
                <LockOutlinedIcon />
              </Avatar>
            </Typography>



            <Box component="form" sx={{width: '100%'}} noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {formError && <Grid item xs={12} sm={12}>
                  <FormControl fullWidth error={formError}>
                  <FormHelperText>
                      {formError === true ? formErrMsg : '' }
                  </FormHelperText>   
                  </FormControl>    
                </Grid>}
              </Grid>  
              <br/>

              <Grid container spacing={2}>
                {formCargillError && <Grid item xs={12} sm={12}>
                  <FormControl fullWidth error={formCargillError}>
                  <FormHelperText>
                      {formCargillError === true ? formCargillErrMsg : '' }
                  </FormHelperText>   
                  </FormControl>    
                </Grid>}
              </Grid>  
              <br/>
              
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="given-name"
                    name="firstName"
                    required
                    size="small"
                    fullWidth
                    id="firstName"
                    label="First Name"
                    color='primary'
                    autoFocus
                    error={firstNameErr}
                    helperText={firstNameErr ? "This information is required." : null}
                    onChange={onChangeFirstName}
                  />
                  {/* <div data-se="o-form-fieldset" class="o-form-fieldset o-form-label-top margin-btm-5">
                  <div data-se="o-form-input-container" class="o-form-input">
                    <span data-se="o-form-input-username" class="o-form-input-name-username o-form-control okta-form-input-field input-fix">
                      <input type="text" placeholder="" name="username" id="okta-signin-username" value="" aria-label="" autocomplete="username" aria-required="true" required="" />
                    </span>
                  </div>
                  </div> */}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="lastName"
                    size="small"
                    label="Last Name"
                    name="lastName"
                    autoComplete="family-name"
                    error={lastNameErr}
                    helperText={lastNameErr ? "This information is required." : null}
                    onChange={onChangeLastName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    size="small"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    error={emailErr}
                    helperText={emailErr ? "Please provide valid Email." : null}   
                    onChange={onChangeEmail}
                  />
                </Grid>
                <Grid item xs={12}>
                      <FormControl fullWidth error={roleErr}  size="small">
                          <InputLabel id="role">Role</InputLabel>
                          <Select
                              required
                              id={roleId}
                              value={roleName}
                              label="Role"
                              onChange={onChangeRole}
                              error={roleErr}
                          >
                                  {(() => {
                                    if(roleList.length !== 0){
                                      return (roleList.map(function(value){
                                        return <MenuItem key={value.rolename} id={value.roleid} value={value.rolename}>{value.roledesc}</MenuItem>;
                                      }))
                                    }
                                  })()}
                          </Select>
                          <FormHelperText>
                             {roleErr === true ? 'This information is required.' : '' }
                          </FormHelperText>
                      </FormControl>
                </Grid>  
                <Grid item xs={12}>
                  <FormControl fullWidth error={captchaFormError}>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={process.env.REACT_APP_CAPTCHA_SECRET_KEY}
                      onChange={onChangeCapcha}
                    />
                    <FormHelperText>
                        {captchaFormError === true ? 'This information is required.' : '' }
                    </FormHelperText>  
                  </FormControl>                
                </Grid>            
                <Grid item xs={12}>
                  <Typography style={{fontSize: 12, textAlign: 'left'}}>
                    By registering to use our services, You agree to Cargill’s Terms of Use.You represent and warrant that in using our services You, Your employees or other authorised persons who communicate with us have authority to do so and enter into binding agreements with Us on behalf of the entity you hold yourself out as representing. You understand that Cargill will rely on this representation and warranty in allowing you to use these services
                  </Typography>
                </Grid>
              </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 1, mb: 1 }}
                >
                  Sign Up
                </Button>
              <Grid container justifyContent="flex-start">
                <Grid item>
                  <Link href="/login" variant="body2">
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
              <Grid container justifyContent="space-between" style={{minWidth: '300px', fontSize:'10px'}}>
          <Grid item>
            <Link>
              <div id="teconsent" style={{display: 'inline'}}>
              </div>
            </Link>  
          </Grid>
          <Grid item>
            <Link href={decodeURI(policyLink)} >
             Privacy Policy
            </Link> 
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-start" style={{width: '400px', minWidth: '300px', fontSize:'10px'}}>
        <Grid item sx={{'text-align': 'right'}}>
          <Link>
              <div id="trustarc-irm-placeholder" style={{display: 'inline'}}>
              </div>
              </Link>
          </Grid>
        </Grid>
            </Box>
          </Box>
          <CopyrightRenderer sx={{  }} />
    </AuthLayout>
  );
}

// function Copyright(props) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//       <Link color="inherit">
//         Cargill
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }