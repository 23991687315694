import { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import Tooltip from '@mui/material/Tooltip';
import { useNavigate, useParams } from 'react-router-dom';
import { getDeliveryFormInfo, completeDeliveryForms, completeAPIResponse, cancelAPIResponse } from '../../../store/reducers/editDeliveryForms';
import { getProvinces, getCities, getCargoTypes, getRemarks, getFormStatuses } from '../../../store/reducers/utils';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { Item } from '../view';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import CancelDeliveryForm from './cancelDeliveryForm';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { connect } from 'react-redux';
import FormatDate from '../../shared/FormatDate';

const AdminViewDeliveryForm = (props) => {
    console.log(props);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showCancelDeliveryModal, setShowCancelDeliveryModal] = useState(false);

    const params = useParams();


    useEffect(() => {
        dispatch(getDeliveryFormInfo(params.id));
        // return () => {
        //     dispatch(setDeliveryFormInfo({}));
        // }
    }, [dispatch, params.id]);

    useEffect(() => {
        dispatch(getProvinces());
        dispatch(getCargoTypes());
        dispatch(getRemarks());
        dispatch(getFormStatuses());
    }, [dispatch]);

    const { data } = useSelector((state) => state.editForm);

    useEffect(() => {
        if (data.provinceId) {
            dispatch(getCities({ provinceId: data.provinceId }));
        }
    }, [dispatch, data.provinceId]);

    const onCompleteDeliveryForm = (res) => {
        if(res.statusCode === '200') {
            props.snackbar("Form is completed.");
            dispatch(getDeliveryFormInfo(params.id));
        } else if(res.statusCode === '403') {
            navigate('/forbidden');
        } else {
            props.snackbar(res.statusMessage, 'error', 5000);
        }
    }

    const onCancelDeliveryForm = (res) => {
        if(res.statusCode === '200') {
            props.snackbar("Form is cancelled .");
            dispatch(getDeliveryFormInfo(params.id));
        } else if(res.statusCode === '403') {
            navigate('/forbidden');
        } else {
            props.snackbar(res.statusMessage, 'error', 5000);
        }
    }

    useEffect(() => {
        if(Object.keys(props.completeAPI).length) {
            dispatch(completeAPIResponse({}));
            onCompleteDeliveryForm(props.completeAPI);
        }
        if(Object.keys(props.cancelAPI).length) {
            dispatch(cancelAPIResponse({}));
            onCancelDeliveryForm(props.cancelAPI);
        }
    });

    const { provinces, cities, cargoTypes, remarks } = useSelector((state) => state.utils);

    const getLabel = (list, key) => {
        if (list.length && key !== '') {
            const keyType = typeof key;
            const index = list.findIndex(({ id, name }) => {
                return keyType === 'number' ? (id === key.toString()) : (id === key);
            });
            if(index !== -1) {
                return list[index].name;
            }
            return "";
        }
        return "";
    }

    const goBack = () => {
        navigate('../admin-view-delivery');
    }

    const onCancelDelivery = () => {
        setShowCancelDeliveryModal(true);
    }

    const onClose = (result) => {
        setShowCancelDeliveryModal(false);
        // if(result){
        //     props.snackbar('Cancelled successfully!');
        //     dispatch(setDeliveryFormInfo({...data, status: 'Cancelled', cancelReason: result}));
        // }
    }

    const completeDelivery = () => {
        dispatch(completeDeliveryForms({objectId: params.id}));
        // props.snackbar('Completed successfully!');
        // dispatch(setDeliveryFormInfo({...data, status: 'Truck-Out'}));
    }

    return (
        // <Layout>
        <>
            <Typography variant="h6" gutterBottom>
                <Tooltip title="Go Back">
                    <ArrowBackIosIcon color='primary' onClick={goBack} style={{ verticalAlign: 'text-bottom', cursor: 'pointer' }} />
                </Tooltip>

                                View Delivery Form
                            </Typography>
            <TableContainer>
                <Paper variant="outlined">
                    <Grid container spacing={2}>
                        <Grid item xs={5}>
                            <Item style={{ fontWeight: 'bold' }}>Supplier Name</Item>
                        </Grid>
                        <Grid item xs={7}>
                            <Item>{data.entryName}</Item>
                        </Grid>
                        <Grid item xs={5}>
                            <Item style={{ fontWeight: 'bold' }}>Driver's Mobile Number</Item>
                        </Grid>
                        <Grid item xs={7}>
                            <Item>{data.driverContactNo}</Item>
                        </Grid>
                        <Grid item xs={5}>
                            <Item style={{ fontWeight: 'bold' }}>Province</Item>
                        </Grid>
                        <Grid item xs={7}>
                            <Item>{getLabel(provinces, data.provinceId)}</Item>
                        </Grid>
                        <Grid item xs={5}>
                            <Item style={{ fontWeight: 'bold' }}>City</Item>
                        </Grid>
                        <Grid item xs={7}>
                            <Item>{getLabel(cities, data.cityId)}</Item>
                        </Grid>
                    </Grid>
                </Paper>

                <br />

                <Paper variant="outlined">
                    <Grid container spacing={2}>
                        <Grid item xs={5}>
                            <Item style={{ fontWeight: 'bold' }}>Truck/Vehicle Plate Number</Item>
                        </Grid>
                        <Grid item xs={7}>
                            <Item>{data.truckNo}</Item>
                        </Grid>
                        <Grid item xs={5}>
                            <Item style={{ fontWeight: 'bold' }}>Quantity (MT)</Item>
                        </Grid>
                        <Grid item xs={7}>

                            {/* <Item>{data.quantity}</Item> */}
                            <Item>{data.quantitystr}</Item>



                        </Grid>
                        <Grid item xs={5}>
                            <Item style={{ fontWeight: 'bold' }}>Type of Cargo</Item>
                        </Grid>
                        <Grid item xs={7}>
                            <Item>{getLabel(cargoTypes, data.cargoType)}</Item>
                        </Grid>
                        <Grid item xs={5}>
                            <Item style={{ fontWeight: 'bold' }}>Remarks</Item>
                        </Grid>
                        <Grid item xs={7}>
                            <Item>{getLabel(remarks, data.remarks)}</Item>
                        </Grid>
                    </Grid>
                </Paper>

                <br />
                <Paper variant="outlined">
                    <Grid container spacing={2}>
                        <Grid item xs={5}>
                            <Item style={{ fontWeight: 'bold' }}>Date</Item>
                        </Grid>
                        <Grid item xs={7}>
                            <Item><FormatDate value={data.slotDate}/></Item>
                        </Grid>
                        <Grid item xs={5}>
                            <Item style={{ fontWeight: 'bold' }}>Time Slot</Item>
                        </Grid>
                        <Grid item xs={7}>
                            <Item>{(data.slotStartTime?.slice(0, -3) || "") + "-" + (data.slotEndTime?.slice(0, -3) || "")}</Item>
                        </Grid>
                        <Grid item xs={5}>
                            <Item style={{ fontWeight: 'bold' }}>Status</Item>
                        </Grid>
                        <Grid item xs={7}>
                            <Item>{data.status}</Item>
                        </Grid>
                        {data.status === 'Cancelled' ?
                            <><Grid item xs={5}>
                                <Item style={{ fontWeight: 'bold' }}>Cancellation Reason</Item>
                            </Grid>
                                <Grid item xs={7}>
                                    <Item>{data.cancelReason}</Item>
                                </Grid></>
                            :
                            null
                        }
                    </Grid>
                </Paper>
            </TableContainer>

            <Grid item xs={12} sm={12}>

                {
                    data.status !== 'Cancelled' && data.status !== 'Truck-Out' &&
                    <FormControl sx={{ m: 1 }}>

                    <Button
                        
                        onClick={onCancelDelivery}
                        type="button"
                        variant="contained"
                        color="error"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        CANCEL DELIVERY FORM
                        <HighlightOffIcon sx={{ ml: 1 }} color="white" />
                    </Button>

                </FormControl>
                }
                
                {
                    data.status !== 'Cancelled' && data.status !== 'Truck-Out' &&
                    <FormControl sx={{ m: 1 }}>

                    <Button
                        onClick={completeDelivery}
                        type="button"
                        variant="contained"
                        color="success"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        COMPLETE
                        <CheckCircleOutlineIcon sx={{ ml: 1 }} color="white" />
                    </Button>

                </FormControl>
                }

            </Grid>
            {showCancelDeliveryModal && <CancelDeliveryForm show={showCancelDeliveryModal} objectId={data.objectId} closeModal={onClose}/>}
            </>
        // </Layout>
    )
}

const mapStateToProps = (state) => ({
    completeAPI: state.editForm.completeAPIResponse,
    cancelAPI: state.editForm.cancelAPIResponse,
});

export default connect(mapStateToProps)(AdminViewDeliveryForm);