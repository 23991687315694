import store from '../../store';
const initialState = {
    searchExpanded: false,
    totalCount: 0,
    list:  [],
    selectedForms: [],
    pagination: {
        currentPage: 1,
        offset: 0,
        pageSize: 15,
        pageCount: 0,
    },
    filters: {
        deliveryOrderNumber: "",
        truckNumber: "",
        cargoType: "",
        remarks: "",
        status: "",
        slotDateFrom: null,
        slotDateTo: null,
        cancellationReason: "",
        supplier: "",
        ctsCode: "",
        city: "",
        province: ""
        // ctsCodeName: "",
    },
    sort: {
        sortOrder: "desc",
        sortBy: "deliveryOrderNumber"
    },
    loading: false,
    newEntryCode: null
};
export const GET_DELIVERY_FORMS = "GET_DELIVERY_FORMS";
export const SET_DELIVERY_FORMS = "SET_DELIVERY_FORMS";
export const SET_PAGE_NUMBER = "SET_PAGE_NUMBER";
export const SET_FILTER = "SET_FILTER";
export const SET_SORT = "SET_SORT";
export const CLEAR_FILTER = "CLEAR_FILTER";
export const SET_LOADING = "SET_LOADING";
export const SET_SELECTED_FORMS = "SET_SELECTED_FORMS";
export const SET_SEARCH_PANEL_TOGGLE = "SET_SEARCH_PANEL_TOGGLE";


export const getDeliveryForms = (data) => {
    return {
        type: GET_DELIVERY_FORMS,
        payload: store.getState().deliveryForms
    }
}

export const setDeliveryForms = (data) => {
    return {
        type: SET_DELIVERY_FORMS,
        payload: {list: data.message, totalCount: data.totalCount, newEntryCode: data.code}
    }
}

export const setPageNumber = (pageNumber) => {
    return {
        type: SET_PAGE_NUMBER,
        payload: {currentPage: pageNumber}
    }
}

export const setFilter = (filter) => {
    return {
        type: SET_FILTER,
        payload: filter
    }
}

export const setSort = (sort) => {
    return {
        type: SET_SORT,
        payload: sort
    }
}

export const setLoading = (flag) => {
    return {
        type: SET_LOADING,
        payload: flag
    }
}

export const clearFilter = () => {
    return {
        type: CLEAR_FILTER
    }
}

export const setSelectedForms = (formIds) => {
    return {
        type: SET_SELECTED_FORMS,
        payload: formIds
    }
}

export const setSearchExpand = (flag) => {
    return {
        type: SET_SEARCH_PANEL_TOGGLE,
        payload: flag
    }
}

export const deliveryFormReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_DELIVERY_FORMS: {
            return {
                ...state,
                ...action.payload,
                pagination: {
                    ...state.pagination,
                    pageCount: Math.ceil(action.payload.totalCount / state.pagination.pageSize)
                }
            };
        }
        case SET_PAGE_NUMBER: {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    currentPage: action.payload.currentPage,
                    offset: action.payload.currentPage === 1 ? 0 : (action.payload.currentPage - 1) * state.pagination.pageSize
                }
            };
        }
        case SET_FILTER: {
            return {
                ...state,
                filters: action.payload
            }
        }
        case SET_SORT: {
            return {
                ...state,
                sort: action.payload
            }
        }
        case SET_LOADING: {
            return {
                ...state,
                loading: action.payload
            }
        }
        case CLEAR_FILTER: {
            return {
                ...state,
                filters: initialState.filters
            }
        }
        case SET_SELECTED_FORMS: {
            return {
                ...state,
                selectedForms: action.payload
            }
        }
        case SET_SEARCH_PANEL_TOGGLE: {
            return {
                ...state,
                searchExpanded: action.payload
            }
        }
        default:
            return state;
    }
}