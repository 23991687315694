import * as React from 'react';
import Box from '@mui/material/Box';
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

const axios = require('axios');

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

export default function ListTemplate() {
  const navigate = useNavigate();
  const [list, setList] = useState([]);

  useEffect(() => {
    const headers = {
      'Authorization': "Bearer " + localStorage.getItem("access_token")
    };
    axios.get(process.env.REACT_APP_API_AUTH_URL + '/listTemplates', { headers: headers })
      .then(response => {
        setList(response.data.message)
      })
      .catch(() => {
        setList([])
      });
  }, []);

  const cellTooltip = (value) => {
    return (
      value && value !== '' ?
        <Tooltip title={value} >
          <span className="MuiDataGrid-cellContent">{value}</span>
        </Tooltip> :
        <span className="MuiDataGrid-cellContent">-</span>
    )
  }


  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        pagination
        pageSize={5}
        rowsPerPageOptions={[5]}
        getRowId={(row) => row.templateId}
        disableColumnMenu={true}
        isRowSelectable={() => false}
        components={{
          Pagination: CustomPagination,
        }}
        columns={[
          {
            field: 'templateName', headerName: 'Template Name', width: 300,
            renderCell: (node) => cellTooltip(node.value)
          },
          { field: 'templateStatus', headerName: 'Template Status', width: 300 },
          {
            field: 'templateState', headerName: 'Template State', width: 300,
            renderCell: (node) => {
              return <span>{!node.row.templateState || node.row.templateState === 'null' ? '-' : node.row.templateState}</span>
            }
          },

          {
            field: "Actions",
            width: 200,
            sortable: false,
            renderCell: (node) => {
              return (
                <div >
                  <IconButton aria-label="View">
                    <Tooltip title="VIEW">
                      <VisibilityRoundedIcon
                        onClick={() => navigate('/admin-template-management/view/' + node.id)} color='primary' />
                    </Tooltip>
                  </IconButton>
                  <IconButton aria-label="Edit">
                    <Tooltip title="EDIT">
                      <EditRoundedIcon onClick={() => {
                        navigate('/admin-template-management/edit/' + node.id)
                      }
                      } color='primary' />
                    </Tooltip>
                  </IconButton>
                </div>
              );
            }
          }

        ]}
        rows={list}
      />
    </Box>
  );
}
