const initialState = {
    cargoTypes: [],
    remarks: [],
    cancellationReasons: [],
    statusList: [],
    provinces: [],
    cities: [],
    vehiclePlateNumbers: []
};

export const GET_CARGO_TYPES = 'GET_CARGO_TYPES';
export const GET_REMARKS = 'GET_REMARKS';
export const GET_CANCELLATION_REASONS = 'GET_CANCELLATION_REASONS';
export const GET_FORM_STATUSES = 'GET_FORM_STATUSES';
export const GET_PROVINCES = 'GET_PROVINCES';
export const GET_CITIES = 'GET_CITIES';
export const GET_VEHICLE_PLATE_NUMBERS = 'GET_VEHICLE_PLATE_NUMBERS';

export const SET_CARGO_TYPES = 'SET_CARGO_TYPES';
export const SET_REMARKS = 'SET_REMARKS';
export const SET_CANCELLATION_REASONS = 'SET_CANCELLATION_REASONS';
export const SET_FORM_STATUSES = 'SET_FORM_STATUSES';
export const SET_PROVINCES = 'SET_PROVINCES';
export const SET_CITIES = 'SET_CITIES';
export const SET_VEHICLE_PLATE_NUMBERS = 'SET_VEHICLE_PLATE_NUMBERS';



export const getCargoTypes = () => {
    return {
        type: GET_CARGO_TYPES
    }
}

export const setCargoTypes = (data) => {
    return {
        type: SET_CARGO_TYPES,
        payload: data.cargoTypes
    }
}

export const getRemarks = () => {
    return {
        type: GET_REMARKS
    }
}

export const setRemarks = (data) => {
    return {
        type: SET_REMARKS,
        payload: data.remarks
    }
}

export const setCancellationReasons = (data) => {
    return {
        type: SET_CANCELLATION_REASONS,
        payload: data.cancellationReasons
    }
}

export const getCancellationReasons = () => {
    return {
        type: GET_CANCELLATION_REASONS
    }
}

export const getFormStatuses = () => {
    return {
        type: GET_FORM_STATUSES
    }
}

export const setFormStatuses = (data) => {
    return {
        type: SET_FORM_STATUSES,
        payload: data.statusList
    }
}

export const getProvinces = () => {
    return {
        type: GET_PROVINCES
    }
}

export const setProvinces = (data) => {
    return {
        type: SET_PROVINCES,
        payload: data.provinces
    }
}

export const getCities = (data) => {
    return {
        type: GET_CITIES,
        payload: data.provinceId
    }
}

export const setCities = (data) => {
    return {
        type: SET_CITIES,
        payload: data.cities
    }
}

export const getVehiclePlateNumbers = (data) => {
    return {
        type: GET_VEHICLE_PLATE_NUMBERS,
        payload: data.userId
    }
}

export const setVehiclePlateNumbers = (data) => {
    return {
        type: SET_VEHICLE_PLATE_NUMBERS,
        payload: data.vehiclePlateNumbers
    }
}


export const utilsReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_CARGO_TYPES: {
            return {
                ...state,
                cargoTypes: action.payload
            }
        }
        case SET_REMARKS: {
            return {
                ...state,
                remarks: action.payload
            }
        }
        case SET_CANCELLATION_REASONS: {
            return {
                ...state,
                cancellationReasons: action.payload
            }
        }
        case SET_FORM_STATUSES: {
            return {
                ...state,
                statusList: action.payload
            }
        }
        case SET_CITIES: {
            return {
                ...state,
                cities: action.payload
            }
        }
        case SET_PROVINCES: {
            return {
                ...state,
                provinces: action.payload
            }
        }
        case SET_VEHICLE_PLATE_NUMBERS: {
            return {
                ...state,
                vehiclePlateNumbers: action.payload
            }
        }
        default:
            return state;
    }
}