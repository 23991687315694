import { Grid, Typography, Box, Modal, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect  } from 'react';
import { getCancellationReasons } from '../../../store/reducers/utils';
import { cancelDeliveryForms } from '../../../store/reducers/editDeliveryForms';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };



const CancelDeliveryForm = (props) => {
    const dispatch = useDispatch();
    const [cancelReason, setCancelReason] = useState("");
    const [otherReason, setOtherReason] = useState("");

    useEffect(() => {
        dispatch(getCancellationReasons());
    },[dispatch]);
    const { cancellationReasons } = useSelector((state) => state.utils);

    const onChangeReason = (e) => {
        setCancelReason(e.target.value.name);
    }

    const onConfirm = () => {
        dispatch(cancelDeliveryForms({objectId: props.objectId, reason: cancelReason === "Others" ? otherReason : cancelReason}));
        props.closeModal(cancelReason);
    }

    const onCancel = () => {
        props.closeModal();
    }

    return (
        <Modal
            open={props.show}
            onClose={props.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Please select a reason
                </Typography>
                <Grid item xs={12} sm={3}>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120,width: '100%' }}>
                            <InputLabel id="remarks-label">Cancellation Reason</InputLabel>
                            <Select
                                labelId="cancellationreason"
                                id="cancellationreason"            
                                label="Cancellation Reason"
                                onChange={onChangeReason}>
                                {cancellationReasons.map((item) => <MenuItem value={item} >{item.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                        {
                            cancelReason === "Others" && 
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120,width: '100%' }}>
                                <TextField
                                    required
                                    value={otherReason}
                                    onChange={(e) => { setOtherReason(e.target.value); }}
                                    fullWidth
                                    id="other-reasons"
                                    size="small"
                                    multiline
                                    rows={6}
                                    label="Please enter your reason"
                                    name="reason"
                            />
                            </FormControl>
                        }
                        
                    </Grid>
                    <Grid item xs={12} sm={12}>

                <FormControl sx={{ m: 1 }}>

                    <Button
                        size="small"
                        disabled={!cancelReason.length || (cancelReason === "Others" && !otherReason.length)}
                        onClick={onConfirm}
                        type="button"
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        CONFIRM
                    </Button>


                </FormControl>
                <FormControl sx={{ m: 1 }}>
                <Button size="small" onClick={onCancel} variant="outlined"  sx={{ mt: 3, mb: 2 }}>CLOSE</Button>

                    </FormControl>
            </Grid>

            </Box>

        </Modal>
    )
}

export default CancelDeliveryForm;