import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function Loading() {
    return (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress style={{padding: '20px', width: '100px', height: '100px', display: 'block', marginLeft: 'auto', marginRight: 'auto'}} />
        </Box>
      )
}