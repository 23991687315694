const initialState = {
    dates: [],
    updateBlockedDatesAPIResponse: {},
    changedDates: []
};

export const GET_BLOCKED_UNBLOCKED_DATES  = 'GET_BLOCKED_UNBLOCKED_DATES';
export const SET_BLOCKED_UNBLOCKED_DATES  = 'SET_BLOCKED_UNBLOCKED_DATES';
export const CHANGE_DATE = 'CHANGE_DATE';
export const SAVE_BLOCKED_UNBLOCKED_DATES = 'SAVE_BLOCKED_UNBLOCKED_DATES';
export const UPDATE_BLOCKED_DATES_API_RESPONSE = 'UPDATE_BLOCKED_DATES_API_RESPONSE';

export const getBlockedUnblockedDates = () => {
    return {
        type: GET_BLOCKED_UNBLOCKED_DATES
    }
}

export const setBlockedUnblockedDates = (data) => {
    return {
        type: SET_BLOCKED_UNBLOCKED_DATES,
        payload: data
    }
}

export const updateBlockedDatesAPIResponse = (data) => {
    return {
        type: UPDATE_BLOCKED_DATES_API_RESPONSE,
        payload: data
    }
}

export const changeDate = (data) => {
    return {
        type: CHANGE_DATE,
        payload: data
    }
}

export const saveChanges = (data) => {
    return {
        type: SAVE_BLOCKED_UNBLOCKED_DATES,
        payload: data
    }
}

export const BlockDatesReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_BLOCKED_UNBLOCKED_DATES: {
            return {
                ...state,
                dates: action.payload
            }
        }
        case UPDATE_BLOCKED_DATES_API_RESPONSE: {
            return {
                ...state,
                updateBlockedDatesAPIResponse: action.payload
            }
        }
        case CHANGE_DATE :{
            return {
                ...state,
                changedDates: action.payload
            }
        }
        default:
            return state;
    }
}