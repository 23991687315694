
import { Switch, Grid, Checkbox, Typography, IconButton, Button, Box, Autocomplete, TextField, FormControl, InputLabel, Select, MenuItem, Tooltip } from '@mui/material';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import React, { useState, useEffect } from 'react';
import RemoveCircleOutlinedIcon from '@mui/icons-material/RemoveCircleOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { setTemplateFields, slotData,initTemplate, addTemplate, addTemplateAPIResponse, setTemplateFieldValidation, setSlotErrorIndex  } from '../../store/reducers/templateForm';
import { getCargoTypes } from '../../store/reducers/utils';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';

import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import InfoIcon from '@mui/icons-material/Info';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { generateTimeSlots, filterOptions, getOptionLabel, formatDate } from '../../utils';

const TemplateForm = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { data, validation, slotErrorIndex, isChanged } = useSelector((state) => state.templateForm);
    const [formSubmit, setFormSubmit] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [validationInfo, setValidationInfo] = useState({});

    const isDefault = !isChanged ? data.templateState : false;

    useEffect(() => {
        if(isDefault){
            dispatch(setTemplateFields({...data, validate: true}));
        }
        if(props.mode !== 'edit') {
            dispatch(initTemplate()); 
        }
        dispatch(getCargoTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch, isDefault, props.mode]);
    const { cargoTypes } = useSelector((state) => state.utils);

    const timeSlots = generateTimeSlots();

    useEffect(() => {
        if(Object.keys(props.onAddTemplate).length) {
            const res = props.onAddTemplate;
            dispatch(addTemplateAPIResponse({}));
            if(res.statusCode === '200') {
                props.snackbar(`${props.mode === 'edit' ? 'Updated' : 'Created'} Successfully!`);
                dispatch(initTemplate());
                navigate('/admin-template-management');
            } else if(res.statusCode === '500'){
                if (res.showPopUpScreen === true){
                    setShowConfirmation(true)
                    setValidationInfo(res)
                    dispatch(setTemplateFields({...data, validate: false}));
                }else{
                    props.snackbar(res.statusMessage, 'error', 5000);
                    dispatch(setSlotErrorIndex(parseInt(res.code)));
                }
           
            } 
            else if(res.statusCode === '403') {
                navigate('/forbidden');
            } else {
                props.snackbar(res.statusMessage, 'error', 5000);
                dispatch(setSlotErrorIndex(parseInt(res.code)));
            }
        }
    }, [props, dispatch, navigate, data]);

    const addSlot = () => {
        // setSlots([...slots, {}])
        dispatch(setSlotErrorIndex(null));
        dispatch(setTemplateFields({...data, slotsInTemplate: [...data.slotsInTemplate, ...[{...slotData}]]}));
    }

    const removeSlot = (index) => {
        dispatch(setSlotErrorIndex(null));
        let slots = data.slotsInTemplate;
        slots.splice(index, 1);
        // setSlots([...slots]);
        dispatch(setTemplateFields({...data, slotsInTemplate: [...slots]}));

    }

    const onUpdateTemplateName = (name) => {
        dispatch(setTemplateFieldValidation({...validation, templateName: !(name && name.length > 0)}));
        dispatch(setTemplateFields({...data, templateName: name}));
    }

    const onUpdateField = (value, index, field) => {
        dispatch(setSlotErrorIndex(null));
        let slots = data.slotsInTemplate;
        slots[index][field] = value;
        dispatch(setTemplateFields({...data, slotsInTemplate: [...slots]}));
    }

    const onMarkDefault = (e) => {
        if(e.target.checked && data.templateStatus === false) {
            dispatch(setTemplateFields({...data, templateStatus: true, templateState: e.target.checked}));
        } else {
            dispatch(setTemplateFields({...data, templateState: e.target.checked, validate: true}));
        }

    }



    const handleConfirm = () =>{
        setFormSubmit(true);
        const errList = Object.values(validation);
        if(errList.indexOf(true) >= 0) {
            return;
        }

        dispatch(addTemplate({...data}));
    }
    const onClear = () => {
        setFormSubmit(false);
        dispatch(initTemplate());
        dispatch(setSlotErrorIndex(null));
    }

    const onChangeActive = (e) => { 
        if(e.target.checked === false && data.templateState) {
            dispatch(setTemplateFields({...data, templateState: false, templateStatus: e.target.checked}));
        } else {
            dispatch(setTemplateFields({...data, templateStatus: e.target.checked}));
        }
    }

      const handleClose = () => {
        setShowConfirmation(false);
      };

    return (
        <Box
            sx={{
                p: 1
            }}
        >
            <Typography sx={{mt: 1}} variant="h6" gutterBottom component="div">
                {props.mode === 'edit' ? 'Edit Template' : 'Create New Template'}
            </Typography>
            <Grid container spacing={3} sx={{ paddingTop: '24px' }}>
                <Grid item xs={12} sm={5}>
                    <FormControl sx={{ minWidth: 120,width: '100%' }}>
                        <TextField
                        
                            id="deliveryOrderNo"
                            label="Template Name"
                            value={data.templateName}
                            autoComplete="templateName"
                            size="small"
                            clearOnBlur
                            helperText={(formSubmit && validation.templateName) ? "Required" : null}                                    
                            error={formSubmit && validation.templateName}
                            onChange={(e) => { onUpdateTemplateName(e.target.value) }}
                        />
                    </FormControl>
                </Grid>
                
                <Grid item xs={6} sm={2} md={1} lg={1} sx={{display: 'flex'}}>
                    <FormControlLabel control={
                        <Checkbox disabled={props.mode === 'edit' && data.templateState && isDefault} checked={data.templateState} onChange={onMarkDefault}/>
                    } label="Default" />
                        {
                            props.mode === 'edit' && 
                            <FormControlLabel control={
                                <Switch disabled={props.mode === 'edit' && data.templateState && isDefault} checked={data.templateStatus} onChange={onChangeActive} />
                                } label={data.templateStatus ? 'Active' : 'Inactive'} />
                        }
                            
                    </Grid>

            </Grid>

            {data.slotsInTemplate.map((value, index) => {
                return <div key={index}>
                    <Grid container spacing={2} sx={{ paddingTop: '24px' }}>
                        <Grid item xs={12} sm={3}>
                            <Autocomplete
                                disableClearable
                                id="slot-start-time"
                                options={timeSlots}
                                size="small"
                                error
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                value={value.timeSlotStart}
                                onChange={(e, value) => onUpdateField(value.inputValue, index, 'timeSlotStart')}
                               
                                renderOption={(props, option) => <li {...props}>{option.title}</li>}
                                renderInput={(params) => <TextField 
                                    size="small" 
                                    {...params}
                                    error={slotErrorIndex === index} 
                                    label="Start Time" />}
                            />

                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Autocomplete
                                disableClearable
                                id="slot-end-time"
                                options={timeSlots}
                                size="small"
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                value={value.timeSlotEnd}
                                filterOptions={filterOptions}
                                getOptionLabel={getOptionLabel}
                                renderOption={(props, option) => <li {...props}>{option.title}</li>}
                                onChange={(e, value) => onUpdateField(value.inputValue, index, 'timeSlotEnd')}
                                renderInput={(params) => <TextField 
                                    size="small"
                                    error={slotErrorIndex === index}  
                                    {...params} 
                                    label="End Time" />}
                            />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <InputLabel size="small" id="demo-simple-select-label">Cargo Type</InputLabel>
                                <Select
                                labelId="type-of-cargo"
                                id="type-of-cargo"
                                label="Type of Cargo"
                                value={value.cargoType}
                                size="small"
                                error={slotErrorIndex === index} 
                                onChange={(e)=>onUpdateField(e.target.value, index, 'cargoType')}
                                >
                                {cargoTypes.map(({ id, name }) => <MenuItem value={name} >{name}</MenuItem>)}
                            </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <FormControl >
                                <TextField
                                    id="slot-count"
                                    label="#Slots"
                                    value={value.numberOfSlots}
                                    autoComplete="slots"
                                    size="small"
                                    error={slotErrorIndex === index} 
                                    onChange={(e) => onUpdateField(e.target.value, index, 'numberOfSlots') }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            
                            
                            {
                                data.slotsInTemplate.length > 1 && <Tooltip title="Remove Slot">
                                    <IconButton onClick={() => removeSlot(index)} sx={{ p: 0 }}>
                                        <RemoveCircleOutlinedIcon color="error" />
                                    </IconButton>
                                </Tooltip>
                            }
                            {   index === data.slotsInTemplate.length-1 && 
                                <Tooltip title="Add Slot">
                                    <IconButton onClick={addSlot} sx={{ p: 0 }}>
                                        <AddCircleOutlinedIcon color="success" />
                                    </IconButton>
                                </Tooltip>
                            }

                        </Grid>
                    </Grid>
                </div>
            })}
            
            <Grid container spacing={3} sx={{ paddingTop: '24px' }}>
                <Grid item xs={12}>
                    <Button
                        type="button"
                        variant="contained"
                        sx={{ marginRight:'10px' }}
                        
                        onClick={handleConfirm}>
                            {props.mode === 'edit' ? 'Update' : 'Submit'}
                    </Button>
                    {
                        props.mode !== 'edit' &&
                        <Button
                            type="button"
                            variant="outlined"
                            sx={{ marginRight:'10px' }}
                            
                            onClick={onClear}>
                            Clear
                        </Button>
                    }
                    {
                        props.mode === 'edit' &&
                        <Button
                            type="button"
                            variant="outlined"
                            sx={{ marginRight:'10px' }}
                            
                            onClick={() => {navigate('/admin-template-management');}}>
                            Close
                        </Button>
                    }
                    
                </Grid>   
            </Grid>
            <Dialog
                open={showConfirmation}
                // TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle display="flex" alignItems="center">
                    <Avatar sx={{ m: 1, bgcolor: '#279989' }}>
                        <InfoIcon />
                    </Avatar>
                {"Do you want to continue?"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    1. Template "<b>{validationInfo.templateName}</b>" is curently the default template, Do you want to override ?
                </DialogContentText>
                    {(() => {      
                        if(Object.keys(validationInfo).length){
                            if (validationInfo.message.length && validationInfo.message[0].datesWithBookings.length !== 0) {
                                return (
                                    <DialogContentText id="alert-dialog-slide-description">
                                        2. We already have booking on these days
                                        <Paper variant="outlined">
                                            {validationInfo.message.length && validationInfo.message[0].datesWithBookings.map((item, i) =>
                                                <Button  size="small" type="button" variant="contained" sx={{m: 1}} color='grey'>
                                                    <span >{formatDate(item.slotDate,'mm/dd/yyyy')}</span>
                                                </Button>
                                            )}
                                        </Paper>            
                                        We cannot overide template for these days, Hence current template would not be applicable for these days.
                                    </DialogContentText>
                                    )
                            } 
                        }
                    })()}
                    {(() => {      
                        if(Object.keys(validationInfo).length){
                            if (validationInfo.message.length && validationInfo.message[0].datesWithBlockedSlots.length !== 0) {
                                return (
                                    <DialogContentText id="alert-dialog-slide-description">
                                        3. We have blocked slots for following days
                                        <Paper variant="outlined">
                                            {validationInfo.message.length && validationInfo.message[0].datesWithBlockedSlots.map((item, i) =>
                                                <Button  size="small" type="button" variant="contained" sx={{m: 1}} color='grey'>
                                                    <span >{formatDate(item.slotDate,'mm/dd/yyyy')}</span>
                                                </Button>
                                            )}
                                        </Paper>            
                                        This would be override and you will have to again block them.
                                    </DialogContentText>
                                    )
                        } 

                        }
                    })()}
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleConfirm}>Confirm</Button>
                </DialogActions>
            </Dialog>

        </Box>
    )
}

const mapStateToProps = (state) => ({
    onAddTemplate: state.templateForm.addTemplateAPIResponse,
});

export default connect(mapStateToProps)(TemplateForm);
