
import { formatDate } from '../../utils';

const date = new Date() 
const tommorowDate = date.setDate(date.getDate() + 1);

const initialState = {
    filters: {
        deliveryOrderNumber: "",
        truckNumber: "",
        cargoType: "",
        remarks: "",
        status: 'Truck-Out',
        slotDateFrom: formatDate(new Date(), 'yyyy-mm-dd'),
        slotDateTo: formatDate(tommorowDate, 'yyyy-mm-dd'),
        cancellationReason: "",
        supplier: ""
    }
};

export const SET_REPORT_FILTER = "SET_REPORT_FILTER";
export const CLEAR_REPORT_FILTER = "CLEAR_REPORT_FILTER";

export const setReportFilter = (filter) => {
    return {
        type: SET_REPORT_FILTER,
        payload: filter
    }
}

export const clearReportFilter = () => {
    return {
        type: CLEAR_REPORT_FILTER
    }
}

export const reportFilterReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_REPORT_FILTER: {
            return {
                ...state,
                filters: action.payload
            }
        }
        
        case CLEAR_REPORT_FILTER: {
            return {
                ...state,
                filters: {...initialState.filters, status: "", slotDateFrom: null, slotDateTo: null}
            }
        }

        default:
            return state;
    }
} 