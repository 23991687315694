import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import {
  DataGrid,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import { darken, lighten } from '@mui/material/styles';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

const axios = require('axios');
export default function CustomPaginationGrid() {
  const [rows, setRows] = React.useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
 
  const columns = [
    {
      field: 'notification',
      editable: false,
      renderHeader: () => (
        <div>
          <NotificationsActiveIcon style={{ paddingTop: '15px', width: '30px', height: '28px'}}/>
          <strong>
            {'Notification '}
          </strong>
        </div>
      ),
      renderCell: (node) => {
      return (
          <div> 
              {node.value}
          </div>
      )
    },
      width: 450
    }

  ]

  const getNotification = () => {
    console.log("getnotification")
    const headers = {
        'Authorization': "Bearer "+localStorage.getItem("access_token")
      };
    axios.get(process.env.REACT_APP_API_AUTH_URL+'/fetchnotification?start='+0, { headers: headers })
    .then(response => {

        var rows = []
        // eslint-disable-next-line array-callback-return
        response.data.message.map(function(item, i){
          rows.push({ id: item.objectid, notification: item.notification_desc, status: item.notification_flag })
        })

        setRows(rows)
        const total = response.data.totalCount;
        setpageCount(Math.ceil(total / limit));
    })
    .catch((error) => {
        console.log('error ' + error);
    });
  }
  let limit = 7;

  useEffect(() => {
    getNotification()
    const interval = setInterval(() => {
      getNotification()
    }, 10000);
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const handleChange = (data,value)=>{
    let currentpage=value-1;
    setCurrentPage(value)
    // currentPage
    let startvalue= Math.ceil(currentpage*7); 
      
    const headers = {
      'Authorization': "Bearer "+localStorage.getItem("access_token")
    };
   axios.get(process.env.REACT_APP_API_AUTH_URL+`/fetchnotification?start=${startvalue}`, { headers: headers })
  
  
  .then((response) =>{
    var rows = []
    // eslint-disable-next-line array-callback-return
    response.data.message.map(function(item, i){
      rows.push({ id: item.objectid, notification: item.notification_desc, status: item.notification_flag  })
    })
    setRows(rows)
  
   })
  
  .catch((error) => {
      console.log('error ' + error);
  });
  }

  const CustomPagination = (pageCount, page) => {
    return (
      <Pagination 
      color="primary"
      count={pageCount}
      page={currentPage}
      onChange={handleChange}

    />
    )
}

const getBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);
  return (
    <Box sx={{ height: 480, width: '100%',
    '& .super-app-theme--UNREAD': {
      fontSize: '10px',
      bgcolor: (theme) =>
        getBackgroundColor(theme.palette.info.main, theme.palette.mode),
      '&:hover': {
        bgcolor: (theme) =>
          getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
      },
    },
    '& .super-app-theme--READ': {
      fontSize: '10px',
    },
    '& .super-app-theme--header': {
      fontStyle: 'bold'
    },
    '& .MuiDataGrid-cell--withRenderer': {
      whiteSpace: 'normal !important'
    }    
     }}>
      <DataGrid
        pagination
        pageSize={7}
        rowsPerPageOptions={[7]}
        disableColumnMenu={true}
        isRowSelectable={() => false}
        components={{
            Pagination: () => {return CustomPagination(pageCount,0)},
        }}
        isCellEditable= {false}

        rows={rows}
        columns={columns}
        getRowClassName={(params) => `rowStyle super-app-theme--${params.row.status}`}
      />
    </Box>
  );
}
