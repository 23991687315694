import React from "react";
import { Box } from "@mui/material";
import ScanQrCodePersona from "../../shared/scan/scannerPersona";
import { useNavigate } from 'react-router-dom';

const ScannerHome = () => {

  const navigate = useNavigate();


  const onScan = (result) => {
    let urlParts = result.text.split("/");
    let deliveryID = urlParts.pop();
    navigate('/scanner-view-delivery/' + deliveryID)
  }

  return (
    <Box>
      
        <ScanQrCodePersona
          onScan={onScan}
        />
    </Box>
  )
}


export default ScannerHome;
