import React, { Component } from 'react';
import Layout from '../layout'; 
// import DataTable from '../shared/tables/DataTable'
import { Outlet } from "react-router-dom";


class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {role: ''};
    }

    render() {

        return (
                <>
                    <Layout>
                        <Outlet/>
                    </Layout>
                 </>
        );
    }
}
 
export default Home;