const validation = {
    templateId: true,
    slotDates: true
};

const form = {
    templateId: "",
    slotDates: []
};

const initialState = {
    templates: [],
    form: form,
    validation: validation,
    assignTemplateAPIResponse: {},
    datewiseTemplates: []
};

export const GET_TEMPLATES = 'GET_TEMPLATES';
export const SET_TEMPLATES = 'SET_TEMPLATES';
export const SET_TEMPLATE_ID = 'SET_TEMPLATE_ID';
export const SET_FIELD_VALIDATION = 'SET_FIELD_VALIDATION';
export const SET_SLOT_DATES = 'SET_SLOT_DATES';
export const ASSIGN_TEMPLATE = 'ASSIGN_TEMPLATE';
export const RESET_FORM = 'RESET_FORM';
export const UPDATE_ASSIGN_TEMPLATE_API_RESPONSE = 'UPDATE_ASSIGN_TEMPLATE_API_RESPONSE';
export const GET_DATEWISE_TEMPLATES = 'GET_DATEWISE_TEMPLATES';
export const SET_DATEWISE_TEMPLATES = 'SET_DATEWISE_TEMPLATES';

export const getTemplates = () => {
    return {
        type: GET_TEMPLATES
    }
}

export const setDatewiseTemplates = (data) => {
    return {
        type: SET_DATEWISE_TEMPLATES,
        payload: data
    }
}

export const getDatewiseTemplates = () => {
    return {
        type: GET_DATEWISE_TEMPLATES
    }
}

export const setTemplates = (data) => {
    return {
        type: SET_TEMPLATES,
        payload: data
    }
}

export const setTemplateId = (id) => {
    return {
        type: SET_TEMPLATE_ID,
        payload: id
    }
}

export const setSlotDates = (data) => {
    return {
        type: SET_SLOT_DATES,
        payload: data
    }
}

export const assignTemplate = (data) => {
    return {
        type: ASSIGN_TEMPLATE,
        payload: data
    }
}

export const setFieldValidation = (data) => {
    return {
        type: SET_FIELD_VALIDATION,
        payload: data
    }
}

export const resetForm = () => {
    return {
        type: RESET_FORM
    }
}

export const updateAssignTemplateAPIResponse = (data) => {
    return {
        type: UPDATE_ASSIGN_TEMPLATE_API_RESPONSE,
        payload: data
    }
}

export const assignTemplateReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_TEMPLATES: {
            return {
                ...state,
                templates: action.payload 
            }
        }
        case SET_DATEWISE_TEMPLATES: {
            return {
                ...state,
                datewiseTemplates: action.payload 
            }
        }
        case SET_TEMPLATE_ID: {
            return {
                ...state,
                form: {...state.form, templateId: action.payload} 
            }
        }
        case SET_SLOT_DATES: {
            return {
                ...state,
                form: {...state.form, slotDates: action.payload} 
            }
        }
        case SET_FIELD_VALIDATION: {
            return {
                ...state,
                validation: action.payload
            }
        }
        case RESET_FORM: {
            return {
                ...state,
                form: form,
                validation: validation
            }
        }
        case UPDATE_ASSIGN_TEMPLATE_API_RESPONSE: {
            return {
                ...state,
                assignTemplateAPIResponse: action.payload
            }
        }
        default:
            return state;
    }
}
