import { Grid, Typography, Box, TableContainer, Paper } from '@mui/material';
import {
    useNavigate, useParams
} from 'react-router-dom';

import { getSuppliersInfo, setSuppliersInfo } from '../../store/reducers/viewCTS';
import { getCTSInfo, clearOrg  } from '../../store/reducers/addCTS';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Item } from '../delivery/view';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Tooltip from '@mui/material/Tooltip';

const ViewCTS = () => {

    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(getSuppliersInfo(params.cts));
        dispatch(getCTSInfo(params.cts));
    }, [dispatch, params.cts]);

    const { suppliersInfo } = useSelector((state) => state.viewCTS);
    const { ctsDetails } = useSelector((state) => state.addOrg);

    const goBack = () => {
        dispatch(setSuppliersInfo([]))
        dispatch(clearOrg())
        navigate('../');
    }

    return (
        <Box
            sx={{
                p: 2
            }}
        >
            <Grid >
                <Typography variant="h6" component="div">
                <Tooltip title="Go Back">
                    <ArrowBackIosIcon color='primary' onClick={goBack} style={{ verticalAlign: 'text-bottom', cursor: 'pointer' }} />
                </Tooltip>
                    View CTS Info
            </Typography>
                <Box component="div" sx={{
                    pt: 2
                }}>
                    
                    <TableContainer>
                        <Paper variant="outlined">
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Item >CTS Code Id</Item>
                                </Grid>
                                <Grid item xs={7}>
                                    <Item style={{ fontWeight: 'bold' }}>{ctsDetails.ctsCode}</Item>
                                </Grid>
                                <Grid item xs={3}>
                                    <Item >CTS Code Name</Item>
                                </Grid>
                                <Grid item xs={7}>
                                    <Item style={{ fontWeight: 'bold' }}>{ctsDetails.ctsCodeName}</Item>
                                </Grid>
                                <Grid item xs={3}>
                                    <Item >Province</Item>
                                </Grid>
                                <Grid item xs={7}>
                                    <Item style={{ fontWeight: 'bold' }}>{ctsDetails.provinceName}</Item>
                                </Grid>
                                <Grid item xs={3}>
                                    <Item >City</Item>
                                </Grid>
                                <Grid item xs={7}>
                                    <Item style={{ fontWeight: 'bold' }}>{ctsDetails.cityName}</Item>
                                </Grid>
                                <Grid item xs={3}>
                                    <Item >Supplier(s)</Item>
                                </Grid>
                                <Grid item xs={7}>
            {suppliersInfo.length === 0 ? '-' : suppliersInfo.map((item, index) => <Item style={{ fontWeight: 'bold' }}>{item.supplier} ({item.supplierName}){index === suppliersInfo.length - 1 ? '' : ','}</Item>)}
                                </Grid>
                            </Grid>
                        </Paper>
                    </TableContainer>
                </Box>
            </Grid>
        </Box>
    )
}

export default ViewCTS;