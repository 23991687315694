import { Grid, Button } from '@mui/material';
import {  Link } from 'react-router-dom';
import ListTemplate from './listTemplate'

const ManageTemplates = () => {

    return (
        <div>
            

            <Grid container spacing={2}>
                
                <Grid item xs={12}>
                    <Link to="./assign" >
                        <Button variant="contained" style={{ float: 'right' }}>Assign</Button>
                    </Link>
                    <Link to="./new" >
                        <Button variant="contained" style={{ float: 'right',marginRight:'10px' }}>Create New</Button>
                    </Link>
                </Grid>

                <Grid item xs={12}>
                    <ListTemplate/>
                </Grid>

            </Grid>

        </div>

    )
}

export default ManageTemplates;