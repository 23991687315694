import { Divider, Grid, Typography, Button, Box } from '@mui/material';
import React, { useState } from 'react';

import { Calendar } from "react-multi-date-picker"
import { useEffect } from 'react';
import './blockDates.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { getBlockedUnblockedDates, changeDate, saveChanges, updateBlockedDatesAPIResponse } from '../../../store/reducers/blockDates';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../../utils';

const BlockDates = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const minDate = new Date();
    minDate.setDate(minDate.getDate() + 1);

    const maxDate = new Date();
    maxDate.setDate(minDate.getDate() + 90); // Only allowed 90 days in advance

    useEffect(() => {
        dispatch(getBlockedUnblockedDates());
    },[dispatch]);

    const { dates, changedDates } = useSelector((state) => state.blockDates);

    const prepareDatesList = (dates) => {
        const list = {blockedDates: [], unblockedDates: [], bookedDates: []};
        dates.forEach((date) => {
            if(date.status === 'blocked') {
                list.blockedDates.push(date.slotDate.replaceAll('-', '/'));
            }
            if(date.status === 'unblocked') {
                list.unblockedDates.push(date.slotDate.replaceAll('-', '/'));
            }
            if(date.status === 'active-blocked') {
                list.bookedDates.push(date.slotDate.replaceAll('-', '/'));
            }
        });
        return list
    }

    const { blockedDates, unblockedDates, bookedDates } = prepareDatesList(dates);

    const [selectedDates, setselectedDates] = useState([]);
    const [monthsCount, setMonthsCount] = useState(1);

    const detectScreenWidth = () => {
        const width = window.innerWidth;
        if (width >= 890 ) {
            setMonthsCount(3);
        } else if (width >= 640 ) {
            setMonthsCount(2);
        } else {
            setMonthsCount(1);
        }
    }

    useEffect(() => {
        detectScreenWidth();
    })


    const MapDate = (e) => {

        const currentDate = e.date.format();
        const selectedDate = e.selectedDate.map((i) => i.format());
        const isWeekend = [0].includes(e.date.weekDay.index);


        if(!(selectedDates.every(item => selectedDate.includes(item)) && selectedDate.every(item => selectedDates.includes(item)))) {
            setselectedDates(selectedDate);
            let blockedDays = [], unblockedDays = [];
            selectedDate.forEach((date) => {
                const dayIndex = new Date(date).getDay();
                if ((blockedDates.includes(date) || [0].includes(dayIndex)) && !unblockedDates.includes(date)) {
                    unblockedDays.push({slotDate: date.replaceAll('-', '/'), status: 'unblocked', dayIndex: dayIndex})
                } else {
                    blockedDays.push({slotDate: date.replaceAll('-', '/'), status: 'blocked', dayIndex: dayIndex})
                }
            }) 
            dispatch(changeDate([...blockedDays, ...unblockedDays]))
        }

        if(e.date < e.today && e.date.format() !== e.today.format()) {
            return {
                disabled: true
            }

        } else if (bookedDates.includes(currentDate)) {
            return {
                disabled: true,
                style: { color: "white", backgroundColor: "grey" }
            }
        } else if(selectedDate.includes(currentDate) && ((blockedDates.includes(currentDate)) || isWeekend) && !unblockedDates.includes(currentDate)) {
            return {
                disabled: false,
                style: { color: "white", backgroundColor: "green" }
            }
        } else if ((blockedDates.includes(currentDate) || isWeekend) && !unblockedDates.includes(currentDate)) {
            return {
                disabled: false,
                style: { color: "white", backgroundColor: "lightgrey" }
            }
        } else if (selectedDate.includes(currentDate)) {
            return {
                disabled: false,
                style: { color: "white", backgroundColor: "red" }
            }
        }
    }

    const onConfirm = () => {
        dispatch(saveChanges(changedDates));
    }

    useEffect(() => {
        if(Object.keys(props.onConfirmBlockUnBlockDates).length) {
            const res = props.onConfirmBlockUnBlockDates;
            if(res.statusCode === '200') {
                props.snackbar(`Updated Successfully!`);
                dispatch(updateBlockedDatesAPIResponse({}));
                setselectedDates([]);
                dispatch(getBlockedUnblockedDates());
            } else if(res.statusCode === '403') {
                navigate('/forbidden');
            } else {
                props.snackbar(res.statusMessage, 'error', 5000);
            }
            
        }
    },[props, dispatch, navigate]);

    const Legends = () => {
        return (
            <Grid item xs={12} sm={12} md={8} lg={8}>
                    <Grid container sx={{fontSize: '11px'}}>
                        <Grid item xs={12} sm={3} md={3}  sx={{display: 'flex'}}>
                            <div className="badge" style={{backgroundColor: 'grey', }}></div>
                            <div >Booked</div>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}  sx={{display: 'flex'}}>
                            <div className="badge" style={{backgroundColor: 'lightgrey', }}></div>
                            <div >Blocked</div>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} sx={{display: 'flex'}}>
                            <div className="badge" style={{backgroundColor: 'red'}}></div>
                            <div>To be blocked</div>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} sx={{display: 'flex'}}>
                            <div className="badge" style={{backgroundColor: 'green'}}></div>
                            <div>To be unblocked</div>
                        </Grid>
                    </Grid>
                </Grid>
        )
    }

    const SelectedDates = ({dates, blockedDates, unblockedDates, onRemove}) => {

        return (
            dates.length ?
            <Card sx={{ minWidth: 275, boxShadow: '0 0 5px #8798ad' }}>
                <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Selected Dates
                </Typography>
                
                {/* <ul className="" style={{position: 'relative', width: '50%', height: '200px'}}>
                    {dates.map((date) => <li className={blockedDates.includes(date) || [0, 6].includes(new Date(date).getDay()) ? 'success chip' : 'danger chip'}>
                        <span style={{cursor: "pointer"}}>{date}</span>
                    </li>)} 
                </ul> */}
                {dates.map((date) => <div sx={{m: 2}} className={(blockedDates.includes(date) || [0].includes(new Date(date).getDay())) && !unblockedDates.includes(date) ? 'success chip' : 'danger chip'}>
                        <span>{formatDate(date, 'mm/dd/yyyy')}</span>
                    </div>)}
                </CardContent>
                </Card>
             : null
            
        )
    }

    return (
        <Box
            sx={{
                p: 1
            }}
        >
            <Typography sx={{ mt: 1 }} variant="h6" gutterBottom component="div">
                Block Dates
            </Typography>

            <Divider />
            
            <Grid container spacing={3} sx={{
                pt: 1
            }}>
                <Grid item xs={8}>
                    <Calendar
                    multiple
                    minDate={minDate}
                    maxDate={maxDate}
                    numberOfMonths={monthsCount}
                    mapDays={MapDate}
                    key={Object.values(props.onConfirmBlockUnBlockDates).length}
                    // mapDays={(e,a) => {
                    //     // console.log(new DateObject(e.date))
                    //     let isWeekend = [0, 6].includes(e.date.weekDay.index)
                        
                    //     // if (isWeekend) return {
                    //     //   disabled: false,
                    //     //   style: { color: "red" }
                    //     // }
                    //     if (!e.selectedDate.length) {
                    //         console.log(e.selectedDate);
                    //         if(isWeekend) {
                    //             return {
                    //                 disabled: false,
                    //                 style: { color: "white", backgroundColor: "red" }
                    //             }
                    //         } else {
                    //             const isBlockedAlready = blockedDates.includes(e.date.format());
                    //             if(isBlockedAlready) {
                    //                 return {
                    //                     disabled: false,
                    //                     style: { color: "white", backgroundColor: "red" }
                                    
                    //                 }
                    //             }
                    //         }
                    //     } else {
                    //         const clickedDate = e.date.format();
                    //         const selectedDate = e.selectedDate.map((i) => i.format());
                    //         if(selectedDate.includes(e.date.format()) && (blockedDates.includes(e.date.format()) || [0, 6].includes(e.date.weekDay.index))) {
                    //             return {
                    //                 disabled: false,
                    //                 style: { color: "white", backgroundColor: "green" }
                                
                    //             }
                    //         }
                    //     }
                        
                    //   }}
                        // panel
                        // plugins={[
                        //     // <DatePanel onClickDate={onClickDate} />
                        //     <Legends position="top"  />
                        // ]}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={11} lg={8}>
                    <Legends />
                </Grid>
                <Grid item xs={12}>
                    <SelectedDates  dates={selectedDates} blockedDates={blockedDates} unblockedDates={unblockedDates} />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    {
                        selectedDates.length ? 
                        <Button
                            type="button"
                            variant="contained"
                            onClick={onConfirm}
                            sx={{ marginRight:'10px' }}
                            
                            >
                            Confirm
                        </Button> : null
                    }
                    
                </Grid> 
                {/* <Grid item xs={12} sm={12} md={8} lg={8}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={4}  sx={{display: 'flex'}}>
                            <div className="badge" style={{backgroundColor: 'lightgrey', }}></div>
                            <div>Blocked</div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} sx={{display: 'flex'}}>
                            <div className="badge" style={{backgroundColor: 'red'}}></div>
                            <div>To be blocked</div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} sx={{display: 'flex'}}>
                            <div className="badge" style={{backgroundColor: 'green'}}></div>
                            <div>To be unblocked</div>
                        </Grid>
                    </Grid>
                </Grid> */}
                {/* <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={2} sm={2} md={1} lg={1}>
                            <div style={{width: '20px', height: '20px', backgroundColor: 'lightgrey', borderRadius: '50%'}}></div>
                        </Grid>
                        <Grid item xs={6}>
                            <span>Blocked</span>
                        </Grid>
                    </Grid>
                    <Grid container >
                        <Grid item xs={2} sm={2} md={1} lg={1}>
                            <div style={{width: '20px', height: '20px', backgroundColor: 'red', borderRadius: '50%'}}></div>
                        </Grid>
                        <Grid item xs={6}>
                            <span>To be blocked</span>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={2} sm={2} md={1} lg={1}>
                            <div style={{width: '20px', height: '20px', backgroundColor: 'green', borderRadius: '50%'}}></div>
                        </Grid>
                        <Grid item xs={6}>
                            <span>To be unblocked</span>
                        </Grid>
                    </Grid>
                </Grid> */}
                
                  
            </Grid>
            

        </Box>
    )
}

const mapStateToProps = (state) => ({
    onConfirmBlockUnBlockDates: state.blockDates.updateBlockedDatesAPIResponse,
});


export default connect(mapStateToProps)(BlockDates);