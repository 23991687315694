import { GET, PUT } from '../requests/requests';
import { setDeliveryForms, setLoading } from '../../store/reducers/deliveryForms';
import { setDeliveryFormInfo, setSlotsAvailable, setSlotsAvailableAPIResponse, setSlotDates, updateAPIResponse, completeAPIResponse, cancelAPIResponse, getFormAPIResponse } from '../../store/reducers/editDeliveryForms';
import { getDeliveryForms } from '../../store/reducers/deliveryForms';
import { put, call } from 'redux-saga/effects';
import { objectToQuery } from '../../utils';

export function* handleDeliveryFormsRequest (params) {
    try {
        const {filters, pagination, sort} = params.payload;
        const url = `${process.env.REACT_APP_API_AUTH_URL}/filterSortDelieveryForm?start=${pagination.offset}&${objectToQuery({...filters, ...sort})}`;
        yield put (setLoading(true));
        const response = yield call (GET, {url: url});
        const { message, totalCount, code } = response.data;
        yield put (setDeliveryForms({message, totalCount, code}));
        yield put (setLoading(false));
    } catch (err) {
        console.log(err);
    }
}


export function* handleGetDeliveryFormInfoRequest (params) {
    try {
        const url = `${process.env.REACT_APP_API_AUTH_URL}/viewDeliveryForm?objectId=${params.payload}`;
        const response = yield call (GET, {url: url});
        const { message } = response.data;
        yield put (getFormAPIResponse(response.data));
        yield put (setDeliveryFormInfo(message[0]));
    } catch (err) {
        console.log(err);
        yield put (getFormAPIResponse({statusCode: null}));
    }
}

export function* handleUpdateDeliveryFormInfoRequest (params) {
    try {
        const { data } = params.payload;
        const url = `${process.env.REACT_APP_API_AUTH_URL}/updateDeliveryForm?objectId=${data.objectId}`;
        const response = yield call (PUT, {url: url, data});
        // const { message, statusCode, statusMessage } = response.data;
        yield put (updateAPIResponse(response.data));
    } catch (err) {
        console.log(err);
    }
}

export function* handleGetSlotsAvailableRequest (params) {
    try {
        const { request } = params; 
        const url = `${process.env.REACT_APP_API_AUTH_URL}/slotsAvailable?${objectToQuery(request)}`;
        const response = yield call (GET, {url: url});
        const { message, statusCode, status } = response.data;
        yield put (setSlotsAvailable(statusCode === '200' ? message : []));
        yield put (setSlotsAvailableAPIResponse({statusCode: statusCode, status: status }));
    } catch (err) {
        yield put (setSlotsAvailable([]));
        yield put (setSlotsAvailableAPIResponse({statusCode: null, status: "" }));
        console.log(err);
    }
}

export function* handleGetSlotDatesRequest () {
    try {
        const url = `${process.env.REACT_APP_API_AUTH_URL}/slotDate`;
        const response = yield call (GET, {url: url});
        const { message } = response.data;
        yield put (setSlotDates(message));
    } catch (err) {
        console.log(err);
    }
}

export function* handleCancelDeliveryFormInfoRequest (params) {
    try {
        const url = `${process.env.REACT_APP_API_AUTH_URL}/cancelDeliveryStatus?objectId=${params.payload.objectId}&action=Cancelled&reason=${params.payload.reason}`;
        const response = yield call (PUT, {url: url});
        const { message } = response.data;
        console.log(message);
        yield put (getDeliveryForms());
        yield put(cancelAPIResponse(response.data));
    } catch (err) {
        console.log(err);
    }
}

export function* handleCompleteDeliveryFormRequest (params) {
    try {
        const url = `${process.env.REACT_APP_API_AUTH_URL}/updateDeliveryStatus?objectId=${params.payload.objectId}&action=Truck-Out`;
        const response = yield call (PUT, {url: url});
        const { message } = response.data;
        console.log(message);
        yield put (getDeliveryForms());
        yield put(completeAPIResponse(response.data));
    } catch (err) {
        console.log(err);
    }
}