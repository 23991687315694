import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import AuthLayout from '../layout/AuthLayout';
import { LogoRenderer } from '../shared/Logo';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';

export default function Forbidden() {
    const navigate = useNavigate();
    const onClickLink = () => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("user");
        navigate('/login?fromUrl=/');
    }   
    return (
        <AuthLayout>
            <Container component="main" maxWidth="xs" style={{height: '500px'}}>
            <CssBaseline />
            <Box
                sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                }}
            >      
                <LogoRenderer /> 
                <Typography component="h1" variant="h5" display="flex" alignItems="center">
                Information 
                    <Avatar sx={{ m: 1, bgcolor: '#279989' }}>
                        <InfoIcon />
                    </Avatar>
                </Typography>                   
                <div>
                    <p>You don't have permission to access this page</p>
                </div>
                <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link variant="body2" >
                    <p onClick={()=>onClickLink()}>Click Here to login</p>
                  </Link>
                </Grid>
              </Grid>

            </Box>
            </Container>

        </AuthLayout>
    );
}