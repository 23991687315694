import { GET, POST,PUT } from '../requests/requests';
import { setOrgAPIResponse, setCTSInfo, updateOrgAPIResponse } from '../../store/reducers/addCTS';
import { setLinkCTSAPIResponse, setCTSSearchResult, setSearchCTSAPIResponse } from '../../store/reducers/linkCTS';

import { put, call } from 'redux-saga/effects';
import { setAllCTS } from '../../store/reducers/listCTS';
import { objectToQuery } from '../../utils';

export function* handleaddCTSRequest (params) {
    try {
        const url = `${process.env.REACT_APP_API_AUTH_URL}/addOrganizationDetails`;
        const response = yield call (POST, {url: url, data: params.payload});

        yield put (setOrgAPIResponse(response.data));

    } catch ({response}) {
        yield put (setOrgAPIResponse(response.data));
    }
}

export function* handleSearchCTSbyCode (params) {
    try {
        const url = `${process.env.REACT_APP_API_AUTH_URL}/searchCtsCodeDetails?ctsCode=${params.payload}`;
        const response = yield call (GET, {url: url});
        if(response.data.message && response.data.message.length) {
            yield put (setCTSSearchResult(response.data.message));
        } else {
            yield put (setCTSSearchResult([{}]));
        }
       
        yield put (setSearchCTSAPIResponse(response.data.statusMessage));

    } catch ({response}) {
        yield put (setSearchCTSAPIResponse(response.data.statusMessage));
    }
}

export function* handleLinkCTSSupplierRequest (params) {
    try {
        const url = `${process.env.REACT_APP_API_AUTH_URL}/saveSupplierCtsCode`;
        const response = yield call (POST, {url: url, data: params.payload});
        console.log(response);
        yield put (setLinkCTSAPIResponse(response.data));
    } catch ({response}) {
        yield put (setLinkCTSAPIResponse(response.data));
    }
}

export function* handleGetAllCTSRequest (params) {
    try {
        const { filters, pagination, sort } = params.payload;
        const url = `${process.env.REACT_APP_API_AUTH_URL}/filterSortCtsCodeDetails?start=${pagination.offset}&${objectToQuery({...filters, ...sort})}`;
        const response = yield call (GET, {url: url});
        const { message, totalCount, code } = response.data;
        yield put (setAllCTS({message, totalCount, code}));
    } catch (err) {
        console.log(err);
    }
}

export function* handleGetCTSbyCode (params) {
    try {
        const url = `${process.env.REACT_APP_API_AUTH_URL}/searchCtsCodeDetails?ctsCode=${params.payload}`;
        const response = yield call (GET, {url: url});
        yield put (setCTSInfo(response.data.message[0]));
    } catch (err) {
        console.log(err);
    }
}

export function* handleEditCTSRequest (params) {
    try {
        const url = `${process.env.REACT_APP_API_AUTH_URL}/updateCtsCodeDetails`;
        const response = yield call (PUT, {url: url, data: params.payload});

        yield put (updateOrgAPIResponse(response.data));

    } catch ({response}) {
        yield put (updateOrgAPIResponse(response.data));
    }
}