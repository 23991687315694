import { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import NavBar from './Header';
import { useNavigate } from 'react-router-dom';
import Menu from './Menu';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setMenuOpen } from '../../store/reducers/LeftNav';
import Footer from './Footer';
import Tooltip from '@mui/material/Tooltip';


const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function Layout(props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const roleId = localStorage.getItem('roleId');
  const menuList = Menu[roleId];
  const location = useLocation();
  const [currentRoute, setCurrentRoute] = useState(location.pathname);
  const dispatch = useDispatch();

  const { isOpen } = useSelector((state) => state.leftNavMenu);

  const navigateTo = (item) => {
    navigate(item.route);
    setCurrentRoute(item.route);
  }

  return (
    <>
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <NavBar openStatus={isOpen} drawerOpen={() => dispatch(setMenuOpen(true))} />
      <Drawer variant="permanent" open={isOpen}>
        <DrawerHeader>
          <IconButton onClick={() => dispatch(setMenuOpen(false))}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuList.map((item, i) =>{
            return (
              <ListItem onClick={() => navigateTo(item)} selected={item.route === currentRoute} button key={item.label}>
                <Tooltip title={item.label}>
                  <ListItemIcon>
                    <item.icon />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary={item.label} />
              </ListItem>
            )
          }
          )}
        </List>
        <Divider />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3, pb: 6 }}>
        <DrawerHeader />
        { props.children }
        <Footer />
      </Box>
      
    </Box>
    </>
  );
}
