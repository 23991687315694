import React, { useEffect, } from "react";
import {
  Box,
  Typography,
  TableContainer,
  Paper,
  Grid,
  Tooltip,
} from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { styled } from "@mui/material/styles";
import FormatDate from "../../shared/FormatDate";
import { getDeliveryFormInfo, setDeliveryFormInfoErr, setFormStatusLoading, updateDeliveryFormStatus, updateDeliveryFormStatusAPIResponse } from '../../../store/reducers/scannerViewDelivery';
import { useDispatch, useSelector } from 'react-redux';
import { getProvinces, getCities, getCargoTypes, getRemarks, getFormStatuses } from '../../../store/reducers/utils';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import { connect } from 'react-redux';
import { STATION_ID } from '../../../utils'
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";

const ScannerViewDelivery = (props) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState('');

  const params = useParams();

  useEffect(() => {
    dispatch(getDeliveryFormInfo(params.id));
  }, [dispatch, params.id]);

  useEffect(() => {
    dispatch(getProvinces());
    dispatch(getCargoTypes());
    dispatch(getRemarks());
    dispatch(getFormStatuses());
  }, [dispatch]);

  const { formDetails, isStatusLoading } = useSelector((state) => state.scannerViewDelivery);
  const { provinces, cities, cargoTypes, remarks, statusList } = useSelector((state) => state.utils);

  useEffect(() => {
    if (formDetails.provinceId) {
      dispatch(getCities({ provinceId: formDetails.provinceId }));
    }
  }, [dispatch, formDetails.provinceId]);

  useEffect(() => {
    if(Object.keys(props.getFormDetailsAPI).length) {
        props.snackbar("Something went wrong, Scan again!", "error");
        dispatch(setDeliveryFormInfoErr({}));
        navigate('/scanner-home');
    }
  });

  useEffect(() => {
    if(Object.keys(props.updateFormStatusAPI).length) {
        const res = props.updateFormStatusAPI;
        dispatch(setFormStatusLoading(false));
        dispatch(updateDeliveryFormStatusAPIResponse({}));
        if(res.statusCode === '200') {
            props.snackbar("Status updated successfully!");
            dispatch(getDeliveryFormInfo(params.id));
            navigate('/scanner-home');
        } else if(res.statusCode === '403') {
            navigate('/forbidden');
        } else {
            props.snackbar(res.statusMessage, 'error', 5000);
        }
    }
});

  const getLabel = (list, key) => {
    if (list.length && key !== '') {
      const keyType = typeof key;
      const index = list.findIndex(({ id, name }) => {
        return keyType === 'number' ? (id === key.toString()) : (id === key);
      });
      if (index !== -1) {
        return list[index].name;
      }
      return "";
    }
    return "";
  }

  const updateStatus = (statusName) => {
    setSelectedStatus(statusName);
    const stationId = STATION_ID[statusName];
    dispatch(setFormStatusLoading(true));
    dispatch(updateDeliveryFormStatus({formId: params.id, stationId: stationId}));
  }

  const GetNextStatus = (currentStatus) => {
    if(statusList.length && currentStatus && (currentStatus === 'Truck-In' || currentStatus ===  'Weigh-In-Gross' || currentStatus === 'Dumping-In' || currentStatus ===  'Dumping-Out')) {
      
      const currentIndex = statusList.findIndex((status) => status.name === currentStatus);
      const nextIndex = currentIndex + 1;

      return statusList.map((status, index) => {
        return (
          index >= nextIndex && status.name !== 'Truck-Out' && 
          <Grid item xs={12} sm={6} md={3}>
            <LoadingButton
          sx={{width: '170px', display: 'flex',
          justifyContent: 'space-between'}}
          variant="contained"
          loading={isStatusLoading && status.name === selectedStatus}
          size="small"
          onClick={() => {updateStatus(status.name)}}
          color="success"><div>{status.name}</div>
          <div><ArrowCircleRightRoundedIcon sx={{ ml: 1, verticalAlign: 'top' }} />
            </div></LoadingButton>
          </Grid>
          
        )
      })
      
    }
    return <></>
  }

  return (
    <div>
      <Typography variant="h6" gutterBottom>
          <Tooltip title="Go Scan QR Code">
              <ArrowBackIosIcon color='primary' onClick={() => {navigate('/scanner-home')}} style={{verticalAlign: 'text-bottom', cursor: 'pointer'}} />
          </Tooltip>
          Go Scan QR Code
      </Typography>
      <TableContainer>
        <Paper variant="outlined" sx={{ marginBottom: 3, padding: 2 }}>
          <Grid container spacing={0}>
            <Grid container spacing={3} xs={12} sm={6}>
              <Grid item xs={5}>
                <Item style={{ fontWeight: "bold" }}>Supplier Name</Item>
              </Grid>
              <Grid item xs={7}>
                <Item>{formDetails.entryName}</Item>
              </Grid>
            </Grid>
            <Grid container spacing={3} xs={12} sm={6}>
              <Grid item xs={5}>
                <Item style={{ fontWeight: "bold" }}>
                  Driver's Mobile Number
                </Item>
              </Grid>
              <Grid item xs={7}>
                <Item>{formDetails.driverContactNo}</Item>
              </Grid>
            </Grid>
            <Grid container spacing={3} xs={12} sm={6}>
              <Grid item xs={5}>
                <Item style={{ fontWeight: "bold" }}>Province</Item>
              </Grid>
              <Grid item xs={7}>
                <Item>{getLabel(provinces, formDetails.provinceId)}</Item>
              </Grid>
            </Grid>
            <Grid container spacing={3} xs={12} sm={6}>
              <Grid item xs={5}>
                <Item style={{ fontWeight: "bold" }}>City</Item>
              </Grid>
              <Grid item xs={7}>
                <Item>{getLabel(cities, formDetails.cityId)}</Item>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper variant="outlined" sx={{ marginBottom: 3, padding: 2 }}>
          <Grid container spacing={0}>
            <Grid container spacing={2} xs={12} sm={6}>
              <Grid item xs={5}>
                <Item style={{ fontWeight: "bold" }}>
                  Truck/Vehicle Plate Number
                </Item>
              </Grid>
              <Grid item xs={7}>
                <Item>{formDetails.truckNo}</Item>
              </Grid>
            </Grid>
            <Grid container spacing={2} xs={12} sm={6}>
              <Grid item xs={5}>
                <Item style={{ fontWeight: "bold" }}>Quantity (MT)</Item>
              </Grid>
              <Grid item xs={7}>
                <Item>{formDetails.quantitystr}</Item>
              </Grid>
            </Grid>
            <Grid container spacing={2} xs={12} sm={6}>
              <Grid item xs={5}>
                <Item style={{ fontWeight: "bold" }}>Type of Cargo</Item>
              </Grid>
              <Grid item xs={7}>
                <Item>{getLabel(cargoTypes, formDetails.cargoType)}</Item>
              </Grid>
            </Grid>
            <Grid container spacing={2} xs={12} sm={6}>
              <Grid item xs={5}>
                <Item style={{ fontWeight: "bold" }}>Remarks</Item>
              </Grid>
              <Grid item xs={7}>
                <Item>{getLabel(remarks, formDetails.remarks)}</Item>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper variant="outlined" sx={{ marginBottom: 3, padding: 2 }}>
          <Grid container spacing={0}>
            <Grid container spacing={2} xs={12} sm={6}>
              <Grid item xs={5}>
                <Item style={{ fontWeight: "bold" }}>Date</Item>
              </Grid>
              <Grid item xs={7}>
                <Item>
                  <FormatDate value={formDetails.slotDate} />
                </Item>
              </Grid>
            </Grid>
            <Grid container spacing={2} xs={12} sm={6}>
              <Grid item xs={5}>
                <Item style={{ fontWeight: "bold" }}>Time Slot</Item>
              </Grid>
              <Grid item xs={7}>
                <Item>
                  {formDetails.slotStartTime?.slice(0, -3) +
                  "-" +
                  formDetails.slotEndTime?.slice(0, -3)}
                </Item>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </TableContainer>
      <div style={{ textAlign: "center" }}>
      <Box sx={{ pb: 3, pt: 2 }}>
        <Typography>
          Current Status:
          <span style={{ fontWeight: "bold" }}> {formDetails.status}</span>
        </Typography>
      </Box>
      <Grid sx={{textAlign: '-webkit-center', justifyContent: 'center'}} container spacing={3}>
        
          {GetNextStatus(formDetails.status)}
        
      </Grid>
      
        </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  getFormDetailsAPI: state.scannerViewDelivery.errObj,
  updateFormStatusAPI: state.scannerViewDelivery.updateStatusAPIResponse
});

export default connect(mapStateToProps)(ScannerViewDelivery);

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));
