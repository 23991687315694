import { combineReducers } from 'redux';
import { deliveryFormReducer } from './deliveryForms';
import { editDeliveryFormReducer } from './editDeliveryForms';
import { leftNavMenuReducer } from './LeftNav';
import { utilsReducer } from './utils';
import { templateFormReducer } from './templateForm';
import { AdminSettingsReducer } from './adminSettings';
import { BlockDatesReducer } from './blockDates';
import { BlockSlotsReducer } from './blockSlots';

import { assignTemplateReducer } from './assignTemplate';
import { bulkEditReducer } from './bulkEdit';
import { addOrgReducer } from './addCTS';
import { listSupplierReducer } from './listSuppliers';
import { linkCTSReducer } from './linkCTS';

import { reportFilterReducer } from './reportFilter';
import { listCTSReducer } from './listCTS';
import { ViewCTSReducer } from './viewCTS';
import { scannerViewDeliveryReducer } from './scannerViewDelivery';

const reducers = combineReducers({
    deliveryForms: deliveryFormReducer,
    utils: utilsReducer,
    editForm: editDeliveryFormReducer,
    leftNavMenu: leftNavMenuReducer,
    templateForm: templateFormReducer,
    adminSettings: AdminSettingsReducer,
    blockDates: BlockDatesReducer,
    assignTemplate: assignTemplateReducer,
    blockSlots: BlockSlotsReducer,
    bulkEdit: bulkEditReducer,
    addOrg: addOrgReducer,
    listSupplier: listSupplierReducer,
    linkCTS: linkCTSReducer,
    reportFilter: reportFilterReducer,
    listCTS: listCTSReducer,
    viewCTS: ViewCTSReducer,
    scannerViewDelivery: scannerViewDeliveryReducer,
});

export default reducers;