import React, { Component } from "react";
import { Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { QrReader } from "react-qr-reader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  width: "40%",
  minWidth: 400,
  p: 2
};

class ScanQrCode extends Component {
  constructor(props) {
    super(props);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.state = { isModalOpen: false };
  }

  handleModalClose() {
    this.setState({ isModalOpen: false });
  }

  handleModalOpen() {
    this.setState({ isModalOpen: true });
  }

  render() {
    return (
      <div style={{ textAlign: "center" }}>
          <Button
            style={{ textTransform: "none" }}
            variant="contained"
            onClick={this.handleModalOpen}
          >
            SCAN QR CODE
          </Button>
        <Modal
          open={this.state.isModalOpen}
          onClose={this.handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ overflow: "scroll" }}
        >
          <Box sx={style}>
            <Typography
              variant="h5"
              component="h5"
              style={{ textAlign: "center" }}
            >
              Show QR Code to Camera
            </Typography>
            <Box style={{ height: "calc(100% - 50px)" }}>
              <QrReader
                className="scan"
                videoStyle={{ paddingLeft: "30px", paddingRight: "30px" }}
                constraints={{
                  facingMode: "environment",
                }}
                onResult={(result, error) => {
                  if (!!result) {
                    this.handleModalClose();
                    this.props.onScan(result);
                  }

                  if (!!error) {
                    console.info(error);
                  }
                }}
              />
            </Box>
            <Grid>
              <Button
                style={{ textTransform: "none", float: "right" }}
                variant="contained"
                onClick={this.handleModalClose}
              >
                CLOSE
              </Button>
            </Grid>
          </Box>
        </Modal>
      </div>
    );
  }
}

export default ScanQrCode;
