import axios from 'axios';

export const GET = (params) => {
    return axios.request({
        method: 'GET',
        url: params.url,
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
    });
}

export const POST = async (params) => {
    // try {
        return await axios.request({
            method: 'POST',
            url: params.url,
            data: params.data,
            headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
        });
        // return await axios.post(params.url, params.data, {headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }})
    // }  catch({response}) {
    //     return response;
    // }
}

export const PUT = (params) => {
    return axios.request({
        method: 'PUT',
        url: params.url,
        data: params.data,
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
    });
}