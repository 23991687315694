
const initialState = {
    numberOfSlotDates: '',
    numberOfBookingsAllowed: '',
    numberOfBookingsAllowedStatus: true,
    updateSlotDatesCountAPIResponse: {},
    updatenumberOfBookingsAllowedAPIResponse: {},
};
export const GET_SLOT_DATES_COUNT = 'GET_SLOT_DATES_COUNT';
export const UPDATE_SLOT_DATES_COUNT = 'UPDATE_SLOT_DATES_COUNT';
export const SET_SLOT_DATES_COUNT = 'SET_SLOT_DATES_COUNT';
export const UPDATE_SLOT_DATES_COUNT_API_RESPONSE = 'UPDATE_SLOT_DATES_COUNT_API_RESPONSE';

export const GET_BOOKINGS_ALLOWED_COUNT = 'GET_BOOKINGS_ALLOWED_COUNT';
export const UPDATE_BOOKINGS_ALLOWED_COUNT = 'UPDATE_BOOKINGS_ALLOWED_COUNT';
export const SET_BOOKINGS_ALLOWED_COUNT = 'SET_BOOKINGS_ALLOWED_COUNT';
export const UPDATE_BOOKINGS_ALLOWED_COUNT_API_RESPONSE = 'UPDATE_BOOKINGS_ALLOWED_COUNT_API_RESPONSE';


export const getSlotDatesCount = (data) => {
    return {
        type: GET_SLOT_DATES_COUNT,
    }
}

export const setSlotDatesCount = (data) => {
    return {
        type: SET_SLOT_DATES_COUNT,
        payload: data
    }
}

export const updateSlotDatesCount = (data) => {
    return {
        type: UPDATE_SLOT_DATES_COUNT,
        payload: {numberOfSlotDates: data}
    }
}

export const updateSlotDatesCountAPIResponse = (data) => {
    return {
        type: UPDATE_SLOT_DATES_COUNT_API_RESPONSE,
        payload: data
    }
}

export const getBookingsAllowedCount = (data) => {
    return {
        type: GET_BOOKINGS_ALLOWED_COUNT,
    }
}

export const setBookingsAllowedCount = (data) => {
    return {
        type: SET_BOOKINGS_ALLOWED_COUNT,
        payload: data
    }
}

export const updateBookingsAllowedCount = (data) => {
    return {
        type: UPDATE_BOOKINGS_ALLOWED_COUNT,
        payload: {numberOfBookingsAllowed: data.numberOfBookingsAllowed, status: data.numberOfBookingsAllowedStatus ? 'Active' : 'Inactive'}
    }
}

export const updateBookingsAllowedCountAPIResponse = (data) => {
    return {
        type: UPDATE_BOOKINGS_ALLOWED_COUNT_API_RESPONSE,
        payload: data
    }
}

export const AdminSettingsReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_SLOT_DATES_COUNT: {
            return {
                ...state,
                numberOfSlotDates: action.payload
            }
        }
        case UPDATE_SLOT_DATES_COUNT_API_RESPONSE: {
            return {
                ...state,
                updateSlotDatesCountAPIResponse: action.payload
            }
        }
        case SET_BOOKINGS_ALLOWED_COUNT: {
            return {
                ...state,
                numberOfBookingsAllowed: action.payload.numberOfBookingsAllowed,
                numberOfBookingsAllowedStatus: action.payload.numberOfBookingsAllowedStatus

            }
        }
        case UPDATE_BOOKINGS_ALLOWED_COUNT_API_RESPONSE: {
            return {
                ...state,
                updatenumberOfBookingsAllowedAPIResponse: action.payload
            }
        }

        default:
            return state
    }
}