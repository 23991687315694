import logo from '../../assets/images/logo.png';
import { Hidden } from '@mui/material';


export const LogoRenderer = (padding) => {
    return (
        <div style={{width: '400px', minWidth: '300px', paddingBottom: padding ? '15px' : '0px'}}>
            <Hidden smDown={true}><img style={{float: 'right'}} alt="Cargill" src={logo} className="org-logo" /></Hidden>
            <Hidden smUp={true}><img alt="Cargill" src={logo} className="org-logo" /></Hidden>
        </div>  
    )
}