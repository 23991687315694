import CreateIcon from '@mui/icons-material/Create';
import ListAltIcon from '@mui/icons-material/ListAlt';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import SettingsIcon from '@mui/icons-material/Settings';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LockClockIcon from '@mui/icons-material/LockClock';
import DownloadIcon from '@mui/icons-material/Download';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

const SUPPLIER_ROLE_ID = process.env.REACT_APP_SUPPLIER_ID
const DRIVER_ROLE_ID = process.env.REACT_APP_DRIVER_ID
const SECURITY_GUARD_ROLE_ID = process.env.REACT_APP_SECURITY_GUARD_ID
const ADMIN_ROLE_ID = 'admin'
const MONITOR_ROLE_ID = 'monitor'
const SCANNER_ROLE_ID = 'scanner'


const Menu = {}

Menu[MONITOR_ROLE_ID] = [
    {
        label: "Monitor Trucks",
        route: "/monitor-trucks",
        active: false,
        icon: ListAltIcon,
    }
]

Menu[SCANNER_ROLE_ID] = [
    {
        label: "SCAN QR CODE",
        route: "/scanner-home",
        active: true,
        icon: QrCodeScannerIcon,
    }
]

Menu[SUPPLIER_ROLE_ID] = [
    {
        label: "New Delivery",
        route: "/supplier-new-delivery",
        active: false,
        icon: CreateIcon,
    },
    {
        label: "List Delivery Forms",
        route: "/supplier-home",
        active: false,
        icon: ListAltIcon,
    }
]

Menu[DRIVER_ROLE_ID] = [
    {
        label: "List Delivery Forms",
        route: "/driver-home",
        active: false,
        icon: ListAltIcon,
    }
]

Menu[SECURITY_GUARD_ROLE_ID] = []

Menu[ADMIN_ROLE_ID] = [
    {
        label: "List Delivery Forms",
        route: "/admin-view-delivery",
        active: false,
        icon: ListAltIcon,
    },
    {
        label: "Manage Templates",
        route: "/admin-template-management",
        active: false,
        icon: DynamicFeedIcon,
    },
    {
        label: "Block Dates",
        route: "/admin-block-dates",
        active: false,
        icon: CalendarMonthIcon,
    },
    {
        label: "Block Slots",
        route: "/admin-block-slots",
        active: false,
        icon: LockClockIcon,
    },
    {
        label: "Manage CTS Codes",
        route: "/admin-cts",
        active: false,
        icon: CorporateFareIcon,
    },
    {
        label: "Map Suppliers to CTS",
        route: "/admin-suppliers-mapping",
        active: false,
        icon: ManageAccountsIcon,
    },
    {
        label: "Report",
        route: "/admin-report-list",
        active: false,
        icon: DownloadIcon,
    },
    {
        label: "Settings",
        route: "/admin-settings",
        active: false,
        icon: SettingsIcon,
    },
]

export default Menu;