import React, { Component } from 'react';
import Typography from '@mui/material/Typography';
 

import Loading from '../shared/Loading';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import Link from '@mui/material/Link';
import FormControl from '@mui/material/FormControl';
import { useParams, useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Tooltip from '@mui/material/Tooltip';
import FormatDate from '../shared/FormatDate';
const withRouter = WrappedComponent => props => {
    const params = useParams();
    const navigate = useNavigate();

    // etc... other react-router-dom v6 hooks
  
    return (
      <WrappedComponent
        {...props}
        params={params}


        navigate={navigate}
 
        // etc...
      />
    );
  };
const axios = require('axios');
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    height: '60%',
    p: 4,
  };

  export const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    boxShadow: 'none'
  }));

class SupplierViewDetails extends Component {

    constructor(props) {
        super(props);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleGenerateQrCode = this.handleGenerateQrCode.bind(this);
        this.goBack = this.goBack.bind(this);
        this.state = {details: null, provinceName: null, cityName: null,cargoTypeName: null, remarksName: null,
            isModalOpen: false,
            imageDate: null
        };
    }

    componentDidMount(){
        this.getDeliveryDetails()
    }

    getDeliveryDetails(){

        const headers = {
            'Authorization': "Bearer "+localStorage.getItem("access_token")
        };
        const params = {objectId: this.props.params.id}
        axios.get(process.env.REACT_APP_API_AUTH_URL+'/viewDeliveryForm', { headers: headers, params: params })
        .then(response => {
            if (response.data.statusCode === '200'){
                this.setState({details: response.data.message[0]})
                this.getProvinceDetails(response.data.message[0].provinceId)
                this.getCityDetails(response.data.message[0].provinceId, response.data.message[0].cityId)
                this.getCargiType(response.data.message[0].cargoType)
                this.getRemarksName(response.data.message[0].remarks)
            
            } else if(response.data.statusCode === '403' ){
                window.location.replace('/forbidden');
            } else {
                this.props.snackbar(response.data.statusMessage, 'error', 10000);
                this.setState({details: {}});
            }
        })
        .catch((error) => {
            console.log('error ' + error);
        });
    }
    getRemarksName(remarksId){
        const headers = {
            'Authorization': "Bearer "+localStorage.getItem("access_token")
        };
        const params = {remarksId: remarksId}
        axios.get(process.env.REACT_APP_API_AUTH_URL+'/remarks', { headers: headers, params: params })
        .then(response => {
            this.setState({remarksName: response.data.message[0].name})
        })
        .catch((error) => {
            console.log('error ' + error);
        });
    }
    getCargiType(cargoTypeId){
        const headers = {
           'Authorization': "Bearer "+localStorage.getItem("access_token")
        };
        const params = {cargoTypeId: cargoTypeId}
        axios.get(process.env.REACT_APP_API_AUTH_URL+'/cargotypes', { headers: headers, params: params })
        .then(response => {
            this.setState({cargoTypeName: response.data.message[0].name})
        })
        .catch((error) => {
            console.log('error ' + error);
        });
    }
    getCityDetails(provinceId, cityId){
        const headers = {
            'Authorization': "Bearer "+localStorage.getItem("access_token")
        };
        const params = {provinceId: provinceId,cityId: cityId}
        axios.get(process.env.REACT_APP_API_AUTH_URL+'/cities', { headers: headers, params: params })
        .then(response => {
            this.setState({cityName: response.data.message[0].name})
        })
        .catch((error) => {
            console.log('error ' + error);
        });
    }
    getProvinceDetails(provinceId){
        const headers = {
            'Authorization': "Bearer "+localStorage.getItem("access_token")
        };
        const params = {provinceId: provinceId}
        axios.get(process.env.REACT_APP_API_AUTH_URL+'/provinces', { headers: headers, params: params })
        .then(response => {
            this.setState({provinceName: response.data.message[0].name})
        })
        .catch((error) => {
            console.log('error ' + error);
        });
    }
    handleModalClose(){
        this.setState({isModalOpen: false})
    }

    handleGenerateQrCode(){

        const headers = {
            'Authorization': "Bearer "+localStorage.getItem("access_token")
        };
        const params = {objectId: this.props.params.id}

        axios.get(process.env.REACT_APP_API_AUTH_URL+'/generateQRCodeDF', { headers: headers, params: params })
        .then(response => {
            this.setState({isModalOpen: true, imageData: response.data.qrcodedata})

        })
        .catch((error) => {
            console.log('error ' + error);
        });        
    }

    goBack(){
        this.props.navigate('../supplier-home');
    }

    render() {
            if(this.state.details !== null){
                const imageData = encodeURI(`data:image/jpg;base64,${this.state.imageData}`)
                return (
                        // <Layout> 
                        <>                            
                            <Typography variant="h6" gutterBottom>
                                <Tooltip title="Go Back">
                                    <ArrowBackIosIcon color='primary' onClick={this.goBack} style={{verticalAlign: 'text-bottom', cursor: 'pointer'}} />
                                </Tooltip>

                                View Delivery Form 
                            </Typography>
                            <TableContainer>
                                <Paper variant="outlined">
                                    <Grid container spacing={2}>
                                        <Grid item xs={5}>
                                         <Item style={{fontWeight: 'bold'}}>Supplier Name</Item>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Item>{this.state.details.entryName}</Item>                                        
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Item style={{fontWeight: 'bold'}}>Driver's Mobile Number</Item>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Item>{this.state.details.driverContactNo}</Item>                                        
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Item style={{fontWeight: 'bold'}}>Province</Item>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Item>{this.state.provinceName}</Item>                                        
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Item style={{fontWeight: 'bold'}}>City</Item>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Item>{this.state.cityName}</Item>                                        
                                        </Grid>                                                                                                            
                                    </Grid>
                                </Paper>

                                <br/>

                                <Paper variant="outlined">
                                    <Grid container spacing={2}>
                                        <Grid item xs={5}>
                                            <Item style={{fontWeight: 'bold'}}>Truck/Vehicle Plate Number</Item>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Item>{this.state.details.truckNo}</Item>                                        
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Item style={{fontWeight: 'bold'}}>Quantity (MT)</Item>
                                        </Grid>
                                        <Grid item xs={7}>

                                            {/* <Item>{this.state.details.quantity}</Item> */}
                                             <Item>{this.state.details.quantitystr}</Item>                                              

                                                                                    

                                        </Grid>
                                        <Grid item xs={5}>
                                            <Item style={{fontWeight: 'bold'}}>Type of Cargo</Item>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Item>{this.state.cargoTypeName}</Item>                                        
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Item style={{fontWeight: 'bold'}}>Remarks</Item>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Item>{this.state.remarksName}</Item>                                        
                                        </Grid>                                                                                                            
                                    </Grid>
                                </Paper>                            
                    
                                    <br/>
                                    <Paper variant="outlined">
                                    <Grid container spacing={2}>
                                        <Grid item xs={5}>
                                            <Item style={{fontWeight: 'bold'}}>Date</Item>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Item><FormatDate value={this.state.details.slotDate}/></Item>                                        
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Item style={{fontWeight: 'bold'}}>Time Slot</Item>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Item>{(this.state.details.slotStartTime.slice(0, -3) || '')+'-'+ (this.state.details.slotEndTime.slice(0, -3) || '')}</Item>                                        
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Item style={{fontWeight: 'bold'}}>Status</Item>
                                        </Grid>
                                        <Grid item xs={7}>
                                                 <Item>{this.state.details.status}</Item>                                        
                                        </Grid>

                                            {this.state.details.status === 'Cancelled' ?
                                                <><Grid item xs={5}>
                                                    <Item style={{ fontWeight: 'bold' }}>Cancellation Reason</Item>
                                                </Grid>
                                                    <Grid item xs={7}>
                                                        <Item>{this.state.details.cancelReason}</Item>
                                                    </Grid></>
                                                :
                                                null
                                            }
                                                {this.state.details.status === 'Submitted' &&
                                                    <>
                                                    <Grid item xs={5}>
                                                        <Item style={{fontWeight: 'bold'}}>Actions</Item>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        <FormControl sx={{ m: 0 }}>
                                                            <Item><Button variant="contained" onClick={this.handleGenerateQrCode}>Generate QR Code</Button></Item> 
                                                        </FormControl>  
                                                        <FormControl sx={{ m: 0 }}>
                                                            <Item>
                                                                <Link href={'/supplier-home'}>
                                                                    <Button variant="contained">Close</Button>
                                                                </Link>
                                                            </Item>  
                                                        </FormControl> 
                                                    </Grid>     
                                                    </>
                                                }                                                                         
                                    </Grid>
                                </Paper>                                 
                            </TableContainer> 
                            <Modal
                                open={this.state.isModalOpen}
                                onClose={this.handleModalClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                style={{ overflow:'scroll' }}
                            >
                            <Box sx={style}>                         
                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    Scan QR Code
                                    <IconButton onClick={this.handleModalClose}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>                                    
                                </Typography>
                                <Typography id="modal-modal-description">
                                            <Box
                                                component="img"
                                                alt="QR Code"
                                                src={decodeURI(imageData)}
                                            />                                            
                                </Typography>
                            </Box>
                        </Modal>
                        </>
                        //</Layout>
                );
            } else {
                return(
                    // <Layout>
                        <Loading/>
                    // </Layout>
                );
            }
    }
}
 
export default withRouter(SupplierViewDetails);
