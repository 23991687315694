import React, { useEffect, useRef } from 'react';
import { useOktaAuth } from '@okta/okta-react';
// import OktaSignIn from '@okta/okta-signin-widget';
// eslint-disable-next-line no-use-before-define
// import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import AuthLayout from '../layout/AuthLayout';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { LogoRenderer } from '../shared/Logo';
import CopyrightRenderer from '../shared/Copyright';
import okta from './okta';
import { useNavigate } from 'react-router-dom';


// const Config = require('../../config.json')
const Login = ({ setCorsErrorModalOpen }) => {
  const { oktaAuth } = useOktaAuth();
  const widgetRef = useRef();
  const location = window && window.location && window.location.origin;
  const cargillLoginUrl = encodeURI(`${process.env.REACT_APP_CARGILL_LOGIN_URL}?fromURI=${location}%2Flogin/callback`);
  const navigate = useNavigate();
  
  const url_string = window.location.href
  var url = new URL(url_string);
  var fromURI = url.searchParams.get("fromUrl");

  useEffect(() => {
    if (!widgetRef.current) {
      return false;
    }

    okta.signIn();
    const signIn = okta.oktaSignInClient;
   

    signIn.authClient.session.get().then((res) => {
      // Internal signin
      // console.log("RES===", res)
      if(res.status === 'ACTIVE') {
        navigate('/');
      } else {
        // okta.getAccountClosed();
      }
    });

    signIn.showSignInAndRedirect(
      { el: widgetRef.current }
    ).then((response) => {
      console.log("login response",response)
    }).catch(function(error) {
      
      // This function is invoked with errors the widget cannot recover from:
      // Known errors: CONFIG_ERROR, UNSUPPORTED_BROWSER_ERROR
    });

    const isCorsError = (err) => (err.name === 'AuthApiError' && !err.statusCode);

    signIn.on('afterError', (_context, error) => {
      if (isCorsError(error)) {
        setCorsErrorModalOpen(true);
      }
    });

    return () => signIn.remove();
  }, [fromURI, oktaAuth, setCorsErrorModalOpen, navigate]);

  const policyLink = encodeURI("https://www.cargill.com/privacy");
  return (
    <AuthLayout>
                <Box className="custom-auth-container"
            sx={{
              padding: 5,
              paddingBottom: 0,
              paddingTop: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
           {LogoRenderer(true)}
      <div ref={widgetRef} />
        <Grid container justifyContent="space-between" style={{width: '360px', minWidth: '300px'}}>
          <Grid item>
            <Link href="/register" variant="body2">
              Don't have an account? Sign up
            </Link>
          </Grid>
          <Grid item>
            <Link href={decodeURI(cargillLoginUrl)} variant="body2">
              Sign in with Cargill ID
            </Link>
          </Grid>
        </Grid>
        {/* <Grid container justifyContent="flex-end" style={{width: '400px', minWidth: '300px'}}>
          
        </Grid> */}

        <Grid container justifyContent="space-between" style={{width: '360px', minWidth: '300px', fontSize:'10px'}}>
          <Grid item>
            <Link>
              <div id="teconsent" style={{display: 'inline'}}>
              </div>
            </Link>  
          </Grid>
          <Grid item>
            <Link href={decodeURI(policyLink)} >
             Privacy Policy
            </Link> 
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-start" style={{width: '360px', minWidth: '300px', fontSize:'10px'}}>
        <Grid item sx={{'text-align': 'right'}}>
          <Link>
              <div id="trustarc-irm-placeholder" style={{display: 'inline'}}>
              </div>
              </Link>
          </Grid>
        </Grid>
             
      </Box>
      

      <CopyrightRenderer sx={{ mt: 1 }} />
    </AuthLayout>
  );
};

export default Login;

// function Copyright(props) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//       <Link color="inherit">
//         Cargill
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }
