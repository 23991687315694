import store from '../../store';

const initialState = {
    totalCount: 0,
    list:  [],
    pagination: {
        currentPage: 1,
        offset: 0,
        pageSize: 10,
        pageCount: 0,
    },
    filters: {
        ctsCode: "",
        ctsCodeName: "",
    },
    sort: {
        sortOrder: "asc",
        sortBy: "ctsCodeName"
    }
}

export const GET_ALL_CTS = 'GET_ALL_CTS';
export const SET_ALL_CTS = 'SET_ALL_CTS';
export const SET_ALL_CTS_PAGE_NUMBER = 'SET_ALL_CTS_PAGE_NUMBER';
export const SET_ALL_CTS_FILTER = 'SET_ALL_CTS_FILTER';
export const CLEAR_ALL_CTS_FILTER = 'CLEAR_ALL_CTS_FILTER';
export const SET_ALL_CTS_SORT = 'SET_ALL_CTS_SORT';

export const getAllCTS = () => {
    return {
        type: GET_ALL_CTS,
        payload: store.getState().listCTS
    }
}

export const setAllCTS = (data) => {
    return {
        type: SET_ALL_CTS,
        payload: {list: data.message, totalCount: data.totalCount}
    }
}

export const setPageNumber = (pageNumber) => {
    return {
        type: SET_ALL_CTS_PAGE_NUMBER,
        payload: {currentPage: pageNumber}
    }
}

export const setSort = (sort) => {
    return {
        type: SET_ALL_CTS_SORT,
        payload: sort
    }
}

export const setFilter = (filter) => {
    return {
        type: SET_ALL_CTS_FILTER,
        payload: filter
    }
}

export const clearFilter = () => {
    return {
        type: CLEAR_ALL_CTS_FILTER
    }
}

export const listCTSReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_ALL_CTS: {
            return { 
                ...state, 
                ...action.payload,
                pagination: {
                    ...state.pagination,
                    pageCount: Math.ceil(action.payload.totalCount / state.pagination.pageSize)
                }
            };
        }
        case SET_ALL_CTS_PAGE_NUMBER: {
            return { 
                ...state, 
                pagination: {
                    ...state.pagination,
                    currentPage: action.payload.currentPage,
                    offset: action.payload.currentPage === 1 ? 0 : (action.payload.currentPage - 1) * state.pagination.pageSize
                }
            };
        }
        case SET_ALL_CTS_SORT: {
            return {
                ...state,
                sort: action.payload
            }
        }
        case SET_ALL_CTS_FILTER: {
            return {
                ...state,
                filters: action.payload
            }
        }
        case CLEAR_ALL_CTS_FILTER: {
            return {
                ...state,
                filters: initialState.filters
            }
        }
        default:
            return state;
    }
}