const initialState = {
    list: [],
    listAPIResponse: {},
    changesSummary: [],
    conflicts: [],
    changesSummaryAPIResponse: {},
    resolveConflictsAPIResponse: {},
    nextDateToReArrangeUnhandledForms: ""
};

export const GET_PENDING_FORMS_TODAY = 'GET_PENDING_FORMS_TODAY';
export const SET_PENDING_FORMS_TODAY = 'SET_PENDING_FORMS_TODAY';
export const GET_CHANGES_SUMMARY = 'GET_CHANGES_SUMMARY';
export const SET_CHANGES_SUMMARY = 'SET_CHANGES_SUMMARY';
export const UPDATE_RESOLVE_CONFLICTED_SLOTS = 'UPDATE_RESOLVE_CONFLICTED_SLOTS';
export const UPDATE_RESOLVE_CONFLICTED_SLOTS_API_RESPONSE = 'UPDATE_RESOLVE_CONFLICTED_SLOTS_API_RESPONSE';


export const getPendingForms = (data) => {
    return {
        type: GET_PENDING_FORMS_TODAY,
        payload: data
    }
}

export const updateResolveConflicts = (data) => {
    return {
        type: UPDATE_RESOLVE_CONFLICTED_SLOTS,
        payload: data
    }
}

export const updateResolveConflictsApiResponse = (data) => {
    return {
        type: UPDATE_RESOLVE_CONFLICTED_SLOTS_API_RESPONSE,
        payload: data
    }
}

export const setPendingForms = (data) => {
    return {
        type: SET_PENDING_FORMS_TODAY,
        payload: data
    }
}

export const getChangesSummary = (data) => {
    return {
        type: GET_CHANGES_SUMMARY,
        payload: data
    }
}

export const setChangesSummary = (data) => {
    return {
        type: SET_CHANGES_SUMMARY,
        payload: data
    }
}


export const bulkEditReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_PENDING_FORMS_TODAY: {
            return {
                ...state,
                list: action.payload.list,
                listAPIResponse: action.payload.apiResponse
            }
        }

        case SET_CHANGES_SUMMARY: {
            return {
                ...state,
                changesSummary: action.payload.changesSummary,
                conflicts: action.payload.conflicts,
                nextDateToReArrangeUnhandledForms: action.payload.nextDateToReArrangeUnhandledForms,
                changesSummaryAPIResponse: action.payload.changesSummaryAPIResponse
            }
        }

        case UPDATE_RESOLVE_CONFLICTED_SLOTS_API_RESPONSE: {
            return {
                ...state,
                resolveConflictsAPIResponse: action.payload,
            }
        }
        
        default: {
            return state;
        }
    }
}