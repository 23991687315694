import { Divider, Grid, Typography, Button, Box, Card, CardContent } from '@mui/material';
import { Calendar } from "react-multi-date-picker";
import React, { useState, useEffect } from 'react';
import '../blockDates/blockDates.css';
import { getBlockedUnblockedDates } from '../../../store/reducers/blockDates';
import { useDispatch, useSelector } from 'react-redux';
import { getSlots, setSlots, updateSlots, updateSlotsAPIResponse, getSlotsAPIResponse } from '../../../store/reducers/blockSlots';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MonthShortName } from '../../../utils';
import { formatDate } from '../../../utils';


const BlockSlots = (props) => {
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const minDate = new Date();

    const maxDate = new Date();
    maxDate.setDate(minDate.getDate() + 90); // Only allowed 90 days in advance

    const [monthsCount, setMonthsCount] = useState(1);
    const [isChanged, setIsChanged] = useState(false);
    const [selectedDate, setSelectedDate] = useState("");



    const detectScreenWidth = () => {
        const width = window.innerWidth;
        if (width >= 890 ) {
            setMonthsCount(3);
        } else if (width >= 640 ) {
            setMonthsCount(2);
        } else {
            setMonthsCount(1);
        }
    }

    useEffect(() => {
        detectScreenWidth();
    })

    useEffect(() => {
        dispatch(getBlockedUnblockedDates());
        dispatch(setSlots({slots: [], originalSlots: [], templateName: ''}));
    },[dispatch]);

    useEffect(() => {
        if(Object.keys(props.onUpdateSlots).length) {
            const res = props.onUpdateSlots;
            dispatch(updateSlotsAPIResponse({}));
            if(res.statusCode === '200') {
                props.snackbar(`Updated Successfully!`);
                dispatch(getBlockedUnblockedDates());
            } else if(res.statusCode === '403') {
                navigate('/forbidden');
            } else {
                props.snackbar(res.statusMessage, 'error', 3000);
            }
            
        }
    });

    useEffect(() => {
        if(Object.keys(props.onFetchSlots).length) {
            const res = props.onFetchSlots;
            dispatch(getSlotsAPIResponse({}));
            if(res.statusCode === '403') {
                navigate('/forbidden');
            } else {
                dispatch(setSlots({slots: [], originalSlots: [], templateName: '',templateId: ''}));
                props.snackbar(res.statusMessage, 'error', 3000);
            }
        }
    });

    useEffect(() => {
        if(Object.keys(props.onUpdateSlots).length) {
            const res = props.onUpdateSlots;
            dispatch(updateSlotsAPIResponse({}));
            if(res.statusCode === '200') {
                props.snackbar(`Updated Successfully!`);
                dispatch(getBlockedUnblockedDates());
                fetchSlotsByDate(new Date(selectedDate));
            } else if(res.statusCode === '403') {
                navigate('/forbidden');
            } else {
                props.snackbar(res.statusMessage, 'error', 3000);
            }
            
        }
    });

    const { dates } = useSelector((state) => state.blockDates);

    const { slots, originalSlots, templateName, templateId } = useSelector((state) => state.blockSlots);

    const prepareDatesList = (dates) => {
        const list = {blockedDates: [], unblockedDates: [], bookedDates: []};
        dates.forEach((date) => {
            if(date.status === 'blocked') {
                list.blockedDates.push(date.slotDate.replaceAll('-', '/'));
            }
            if(date.status === 'unblocked') {
                list.unblockedDates.push(date.slotDate.replaceAll('-', '/'));
            }
            if(date.status === 'active-blocked') {
                list.bookedDates.push(date.slotDate.replaceAll('-', '/'));
            }
        });
        return list
    }

    const { blockedDates, unblockedDates, bookedDates } = prepareDatesList(dates);

    const selectSlot = (slotId) => {
        setIsChanged(true);
        const slotIndex  = slots.findIndex((slot) => slot.slotId === slotId);
        slots[slotIndex].blockedStatus = slots[slotIndex].blockedStatus === 'blocked' ? 'unblocked' : 'blocked';
        dispatch(setSlots({slots: [...slots], originalSlots: [...originalSlots], templateName: templateName, templateId: templateId}));
    }

    const MapDate = (e) => {

        const currentDate = e.date.format();
        const isWeekend = [0].includes(e.date.weekDay.index);


        if(e.date < e.today && e.date.format() !== e.today.format()) {
            return {
                disabled: true
            }

        } else if (bookedDates.includes(currentDate)) {
            return {
                disabled: false,
                style: { color: "white", backgroundColor: "grey" }
            }
        } else if ((blockedDates.includes(currentDate) || isWeekend) && !unblockedDates.includes(currentDate)) {
            return {
                disabled: true,
                style: { color: "white", backgroundColor: "lightgrey" }
            }
        }
    }

    const onSelectDate = (date) => {
        setSelectedDate(date.format());
        const selectedDate = new Date(date.format());
        fetchSlotsByDate(selectedDate);
    }

    const fetchSlotsByDate = (date) => {
        setIsChanged(false);
        const formattedDate = `${date.getDate()}-${MonthShortName[date.getMonth()]}-${date.getFullYear()}`;
        dispatch(getSlots(formattedDate));
    }

    const onConfirm = () => {
        let request = {
            slotDate: selectedDate,
            daySlotsStatusList: [],
            templateId: templateId
        }
        let blockedSlots = 0;
        slots.forEach((slot) => {
            const actual = originalSlots.find((s) => s.slotId === slot.slotId);
            
            if(slot.blockedStatus !== null && actual.blockedStatus !== slot.blockedStatus) {
                request.daySlotsStatusList.push({
                    slotId: slot.slotId,
                    timeSlotStart: slot.timeSlotStart,
                    timeSlotEnd: slot.timeSlotEnd,
                    status: slot.blockedStatus,
                })
            }
            if(slot.blockedStatus === 'blocked') {
                blockedSlots++;
            }
        });
        
        if(request.daySlotsStatusList.length === 0) {
            props.snackbar(`No changes found!`, 'error', 3000);
        } else if (blockedSlots === originalSlots.length) {
            props.snackbar(`If you would like to block all the slots, please navigate to "Block Dates" page!`, 'info', 15000);
        } else {
            dispatch(updateSlots(request));
        }
    }

    const Legends = () => {
        return (
            <Grid item xs={12} sm={12} md={8} lg={8}>
                    <Grid container sx={{fontSize: '11px'}}>
                        <Grid item xs={12} sm={3} md={3}  sx={{display: 'flex'}}>
                            <div className="badge" style={{backgroundColor: 'grey', }}></div>
                            <div >Booked</div>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}  sx={{display: 'flex'}}>
                            <div className="badge" style={{backgroundColor: 'lightgrey', }}></div>
                            <div >Blocked</div>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} sx={{display: 'flex'}}>
                            <div className="badge" style={{backgroundColor: 'red'}}></div>
                            <div>To be blocked</div>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} sx={{display: 'flex'}}>
                            <div className="badge" style={{backgroundColor: 'green'}}></div>
                            <div>To be unblocked</div>
                        </Grid>
                    </Grid>
                </Grid>
        )
    }

    const getClass = (slot) => {
        if(originalSlots.length && slots.length) {
            const actual = originalSlots.find((s) => s.slotId === slot.slotId);
            const current = slots.find((s) => s.slotId === slot.slotId);

            if(slot.activeBooking !== '0') {
                return 'disabled-slot';
            } else if(actual.blockedStatus === 'blocked' && current.blockedStatus === 'blocked') {
                return 'blocked-slot';
            } else if (actual.blockedStatus === 'blocked' && current.blockedStatus === 'unblocked') {
                return 'unblocked-slot';
            }  else if (actual.blockedStatus === 'unblocked' && current.blockedStatus === 'blocked') {
                return 'newly-unblocked-slot';
            }
        }
        
    }

    return (
        <Box
            sx={{
                p: 1
            }}
        >
            <Typography sx={{ mt: 1 }} variant="h6" gutterBottom component="div">
                Block Slots
            </Typography>

            <Divider />

            <Grid container spacing={3} sx={{
                pt: 1
            }}>
                <Grid item xs={8}>
                    <Calendar 
                    mapDays={MapDate} 
                    numberOfMonths={monthsCount}
                    onChange={onSelectDate}
                    minDate={minDate}
                    maxDate={maxDate}>
                    </Calendar>
                </Grid>
                <Grid item xs={12} sm={12} md={11} lg={8}>
                    <Legends />
                </Grid>
                {
                    slots.length > 0 && 
                    <Grid item xs={12}>
                        <Card sx={{ minWidth: 275, boxShadow: '0 0 5px #8798ad'}}>
                            <CardContent>
                            <Typography sx={{ fontSize: 14, display: 'flex', justifyContent: 'space-between' }} color="text.bold" gutterBottom>
                            <b>{templateName}</b> <b>{formatDate(new Date(selectedDate), 'mm/dd/yyyy')}</b>
                            </Typography>
                            {slots.map((slot) => <Button className={getClass(slot)} disabled={slot.activeBooking !== '0'} size="small" type="button" variant="contained" onClick={() => selectSlot(slot.slotId)} sx={{m: 1}} color='grey'>
                <span >{slot.timeSlotStart + ' - ' + slot.timeSlotEnd}</span>
                            </Button>)}
                            </CardContent>
                        </Card>
                    </Grid>
                    
                }
                { isChanged &&
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Button
                        type="button"
                        variant="contained"
                        sx={{ marginRight:'10px' }}
                        onClick={onConfirm}
                        >
                            Confirm
                    </Button>
                </Grid> 
                }
                
                
            </Grid>
            
        </Box>
    )
}

const mapStateToProps = (state) => ({
    onUpdateSlots: state.blockSlots.updateSlotsAPIResponse,
    onFetchSlots: state.blockSlots.getSlotsAPIResponse,
});


export default connect(mapStateToProps)(BlockSlots);