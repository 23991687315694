
import { Typography, Box, Grid, TextField, Modal, Button, IconButton, FormControl, Autocomplete, Tooltip } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import Icon from "react-multi-date-picker/components/icon"

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getCargoTypes, getRemarks, getCancellationReasons, getFormStatuses } from '../../../../store/reducers/utils';
import { getDeliveryForms } from '../../../../store/reducers/deliveryForms';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import DatePicker from "react-multi-date-picker"

import { getBlockedUnblockedDates } from '../../../../store/reducers/blockDates';
import { getPendingForms, getChangesSummary, setChangesSummary, updateResolveConflicts, updateResolveConflictsApiResponse } from '../../../../store/reducers/bulkEdit';
import { getSlotsAvailable } from '../../../../store/reducers/editDeliveryForms';

import { connect } from 'react-redux';
import { gridFocusNone, generateTimeSlots, filterOptions, getOptionLabel, formatDate } from '../../../../utils';
import { DataGrid } from '@mui/x-data-grid';
import { darken, lighten } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import React from 'react';



export const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflow:'scroll',
    p: 4,
  };

const BulkEdit = (props) => {
    const classes = gridFocusNone();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showSummary, setShowSummary] = useState(false);
    const [selectedSlots, setSelectedSlots] = useState([]);
    const [nextDayStartTime,setNextDayStartTime] = useState("");

    const minDate = new Date();
    minDate.setDate(minDate.getDate() + 1);

    const maxDate = new Date();
    maxDate.setDate(minDate.getDate() + 90);

    useEffect(() => {
        // dispatch(setFilter({...deliveryForms.filters, slotDateFrom: formatDate(minDate, 'yyyy-mm-dd'), slotDateTo: formatDate(maxDate, 'yyyy-mm-dd') }));
        dispatch(getDeliveryForms());
        dispatch(getCargoTypes());
        dispatch(getRemarks());
        dispatch(getCancellationReasons());
        dispatch(getFormStatuses());
        dispatch(getBlockedUnblockedDates());
        dispatch(getPendingForms());
        return () => {
            dispatch(setChangesSummary(
                {
                    changesSummary: [],
                    conflicts: [],
                    changesSummaryAPIResponse: {},
                    nextDateToReArrangeUnhandledForms: ""
                }
            ))
        }
    }, [dispatch]);

    useEffect(() => {
        if(Object.keys(props.resolveAPIResponse).length) {
            const res = props.resolveAPIResponse;
            if(res.statusCode === '200') {
                dispatch(updateResolveConflictsApiResponse({}));
                props.snackbar("Changes saved successfully!");
                navigate('/admin-view-delivery');
            } else if(res.statusCode === '403') {
                navigate('/forbidden');
            } else {
                dispatch(updateResolveConflictsApiResponse({}));
                props.snackbar(res.statusMessage ? res.statusMessage : "Error", 'error', 5000);
            }
        }
    });

    const { dates } = useSelector((state) => state.blockDates);
    const { list, changesSummary, conflicts, changesSummaryAPIResponse, nextDateToReArrangeUnhandledForms } = useSelector((state) => state.bulkEdit);
    const { slotsAvailable } = useSelector((state) => state.editForm);


    const populateReArrangedSlots = (oldSlots, newSlots) => {
        if(oldSlots.length && newSlots.length) {
            newSlots.forEach((newSlot) => {
                const oldSlot = oldSlots.find((i) => newSlot.deliveryOrderNumber === i.deliveryOrderNumber);
                newSlot.oldSlotDateTime = `${oldSlot.slotDate} ${oldSlot.slotStartTime.split(' ')[1].slice(0, -3)} - ${oldSlot.slotEndTime.split(' ')[1].slice(0, -3)}`
                newSlot.newSlotDateTime = `${newSlot.slotDate} ${newSlot.slotStartTime} - ${newSlot.slotEndTime}`
            });
            return newSlots;
        }
        return [];
    }

    const reArrangedSlots = populateReArrangedSlots(list, changesSummary);

    console.log(reArrangedSlots);

    const cellTooltip = (value) => {
        return (
            value && value !== ''? 
            <Tooltip title={value} >
                <span className="MuiDataGrid-cellContent">{value}</span>
            </Tooltip> :
            <span style={{margin: 'auto'}} className="MuiDataGrid-cellContent">-</span>
        )
    }

    const columns = [
        { field: 'deliveryOrderNumber', headerAlign:'center', headerName: 'Delivery Order No', width: 180, renderCell: (node) => cellTooltip(node.value) },
        { 
            field: 'slotStartTime', 
            headerAlign:'center',
            headerName: 'Time Slot', 
            width: 140, 
            sortable: false,
            renderCell: (node) => {
                const slotStart = node.row.slotStartTime ? node.row.slotStartTime.split(' ')[1] : '';
                const slotEnd = node.row.slotEndTime ? node.row.slotEndTime.split(' ')[1] : '';
                const value = `${slotStart.slice(0, -3)} - ${slotEnd.slice(0, -3)}`;
                return (
                    <Tooltip title={value} >
                        <span className="MuiDataGrid-cellContent">{value}</span>
                    </Tooltip>
                )
            }
        },
        { field: 'status', headerAlign:'center', headerName: 'Status', width: 120, renderCell: (node) => cellTooltip(node.value) },
        { field: 'cargoType',  headerAlign:'center', headerName: 'Cargo Type', width: 120, renderCell: (node) => cellTooltip(node.value) },
        { field: 'truckNumber', headerAlign:'center', headerName: 'Truck/Vehicle Plate Number', width: 230, renderCell: (node) => cellTooltip(node.value) },
        { field: 'supplier', headerAlign:'center', headerName: 'Supplier', width: 120, renderCell: (node) => cellTooltip(node.value) },
        { field: 'remarks', headerAlign:'center', headerName: 'Remarks', width: 120, renderCell: (node) => cellTooltip(node.value) },

    ];

    const changesSummaryColumns = [
        { field: 'deliveryOrderNumber', align: 'center', headerAlign:'center', headerName: 'Delivery Order No', renderCell: (node) => cellTooltip(node.value),  flex: 2 },
        { field: 'cargoType', align: 'center', headerAlign:'center', headerName: 'Cargo Type', renderCell: (node) => cellTooltip(node.value),  flex: 1 },
        { 
            field: 'oldSlotDateTime', align: 'center', headerAlign:'center', headerName: 'Original Date & Slot',
            flex: 3,
            renderCell: (node) => {
                console.log(node)
                const splits = node.formattedValue.split(' ');
                const value = `${formatDate(new Date(splits[0]), 'mm/dd/yyyy')} ${splits[1]} ${splits[2]} ${splits[3]}`
                return (
                    <Tooltip title={value} >
                        <span className="MuiDataGrid-cellContent">{value}</span>
                    </Tooltip>
                )
            } 
        },
        { 
            field: 'newSlotDateTime', align: 'center', headerAlign:'center', headerName: 'New Date & Slot',  
            flex: 3,
            renderCell: (node) => {
                console.log(node)

                const value = `${formatDate(new Date(node.row.slotDate), 'mm/dd/yyyy')} ${node.row.slotStartTime} - ${node.row.slotEndTime}`
                return (
                    <Tooltip title={value} >
                        <span className="MuiDataGrid-cellContent">{value}</span>
                    </Tooltip>
                )
            }  
        },
    ];

    const prepareDatesList = (dates) => {
        const list = {blockedDates: [], unblockedDates: [], bookedDates: []};
        dates.forEach((date) => {
            if(date.status === 'blocked') {
                list.blockedDates.push(date.slotDate.replaceAll('-', '/'));
            }
            if(date.status === 'unblocked') {
                list.unblockedDates.push(date.slotDate.replaceAll('-', '/'));
            }
            if(date.status === 'active-blocked') {
                list.bookedDates.push(date.slotDate.replaceAll('-', '/'));
            }
        });
        return list
    }

    const { blockedDates, unblockedDates, bookedDates } = prepareDatesList(dates);

    const handleModalClose = () => {

    }

    const timeSlots = generateTimeSlots();

    const MapDate = (e) => {

        const currentDate = e.date.format();
        const isWeekend = [0].includes(e.date.weekDay.index);


        if(e.date < e.today && e.date.format() !== e.today.format()) {
            return {
                disabled: true
            }

        } else if (bookedDates.includes(currentDate)) {
            return {
                disabled: false,
            }
        } else if ((blockedDates.includes(currentDate) || isWeekend) && !unblockedDates.includes(currentDate)) {
            return {
                disabled: true,
            }
        }
    }

    const [conflictIndex, setConflictIndex] = useState(0);
    const [isTomorrow, setIsTomorrow] = useState(false);


    const [newSlot, setNewSlot] = useState({});

    const onSelectTimeSlot = (e, value, isCustomSlot) => {
        let list = conflicts;
        list[conflictIndex]['newSlotStart'] = value.timeSlotStart.length > 5 ? value.timeSlotStart.slice(0,-3) :  value.timeSlotStart;
        list[conflictIndex]['newSlotEnd'] = value.timeSlotEnd.length > 5 ?value.timeSlotEnd.slice(0,-3) : value.timeSlotEnd;
        list[conflictIndex]['validateSlotTiming'] = isCustomSlot;

        // setConflictSlots([...list]);
        dispatch(setChangesSummary(
            {
                changesSummary: changesSummary,
                conflicts: list,
                changesSummaryAPIResponse: changesSummaryAPIResponse,
                nextDateToReArrangeUnhandledForms: nextDateToReArrangeUnhandledForms
            }
        ))
        setIsModalOpen(false);
    }

    const onSelectChangeDate = (index, date) => {
        let list = conflicts;
        list[index]['selectedDate'] = date;
        dispatch(setChangesSummary(
            {
                changesSummary: changesSummary,
                conflicts: list,
                changesSummaryAPIResponse: changesSummaryAPIResponse,
                nextDateToReArrangeUnhandledForms: nextDateToReArrangeUnhandledForms
            }
        ))
        getSlots(date, list[index].cargoTypeId, list[index].truckNumber, list[index].slotStartTime.split(' ')[1], list[index].slotEndTime.split(' ')[1]);
    }

    const formatDateWithName = (value) => {
        var date = new Date(value)
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = date.toLocaleString('en-us', { month: 'short' }); 
        var yyyy = date.getFullYear();

        var formatedDate = dd + '-' + mm + '-' + yyyy;
        return formatedDate
    }

    const getSlots = (newDate, cargoTypeId, truckno, reArrangedStartTime, reArrangedEndTime) => {
        const params = {
            date: formatDateWithName(newDate), 
            cargoTypeId: cargoTypeId, 
            truckno: truckno,
            reArrangedStartTime: reArrangedStartTime,
            reArrangedEndTime: reArrangedEndTime,
            nextAvailableDate: formatDateWithName(new Date(nextDateToReArrangeUnhandledForms))
        }
        dispatch(getSlotsAvailable(params));
    }

    const getBackgroundColor = (color, mode) =>
      mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);
    
    const getHoverBackgroundColor = (color, mode) =>
      mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

    const getChangesSummaryAndConflicts = () => {
       
        let slots = [];
        selectedSlots.forEach((delNo) => {
            const slot = list.find((i) => delNo === i.deliveryOrderNumber);
            if(slot) {
                slots.push(slot);
            }
        });
        let request = {
            unhandledFormsToReArrange: slots,
            nextDayPlantStartTime: nextDayStartTime
        }
        console.log(request);
        dispatch(getChangesSummary(request));
        setShowSummary(true);
    }

    const onSubmit = () => {
        
        if(conflicts.map((i) => i.selectedDate).indexOf(undefined) > -1) {
            return props.snackbar("Please select alternate dates for conflicted slots!", "error")
        }

        let slots = [];
        conflicts.forEach((conflict) => {
            const date = formatDateWithName(conflict.selectedDate)
            slots.push({
                "slotDate": date,
                "slotStartTime": `${date} ${conflict.newSlotStart}`,
                "slotEndTime": `${date} ${conflict.newSlotEnd}`,
                "objectId": conflict.deliveryOrderNumber,
                "validateSlotTiming": conflict.validateSlotTiming,

            })
        });
        changesSummary.forEach((slot) => {
            const date = formatDateWithName(new Date(slot.slotDate));
            slots.push({
                "slotDate": date,
                "slotStartTime": `${date} ${slot.slotStartTime}`,
                "slotEndTime": `${date} ${slot.slotEndTime}`,
                "objectId": slot.deliveryOrderNumber
            })
        });
        const request = {
            request: {reArrangedAndConflictResolvedFormsForUpdate: slots},
            nextDayStartTime: nextDayStartTime,
            nextDayEndTime: changesSummary[changesSummary.length - 1].slotEndTime,
            nextAvailableDate: changesSummary[0].slotDate,
        };
        dispatch(updateResolveConflicts(request));
    }

    const disableSlotStartTime = (option) => {
        if(newSlot.timeSlotEnd) {
            const startSlotIndex = timeSlots.findIndex((slot) => slot.inputValue === newSlot.timeSlotEnd)
            const currentIndex = timeSlots.findIndex((slot) => slot.inputValue === option.inputValue);
            return currentIndex >= startSlotIndex;
        } else {
            return false;
        }
       
    }

    const disableSlotEndTime = (option) => {
        if(newSlot.timeSlotStart) {
            const startSlotIndex = timeSlots.findIndex((slot) => slot.inputValue === newSlot.timeSlotStart)
            const currentIndex = timeSlots.findIndex((slot) => slot.inputValue === option.inputValue);
            return currentIndex <= startSlotIndex;
        } else {
            return false;
        }
    }

    return (
        <Box
            sx={{
                p: 1
            }}
        >
            <Grid container spacing={3} sx={{}}>
                {
                    !showSummary && <>
                        <Grid item xs={12}>
                        <Typography sx={{ mt: 1 }} variant="h6" gutterBottom component="div">
                Today's Pending Deliveries
            </Typography>
                        <Box
                    sx={{
                        height: 400,
                        width: 1,
                        '& .super-app-theme--Submitted': {
                        bgcolor: (theme) =>
                            getBackgroundColor(theme.palette.info.main, theme.palette.mode),
                        '&:hover': {
                            bgcolor: (theme) =>
                            getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
                        },
                        },

                    }}
                    >   
                        <DataGrid
                            checkboxSelection={true}
                            className={classes.root} 
                            getRowId={(row) => row.deliveryOrderNumber}
                            rows={list}
                            columns={columns}
                            disableColumnMenu={true}
                            rowsPerPageOptions={[]}
                            onSelectionModelChange={(slots) => {
                                setSelectedSlots(slots);
                              }}
                              selectionModel={selectedSlots}
                           
                    />
                    </Box>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Autocomplete
                        disableClearable
                        id="slots"
                        options={timeSlots}
                        size="small"
                        error
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys                        
                        filterOptions={filterOptions}
                        getOptionLabel={getOptionLabel}
                        renderOption={(props, option) => <li {...props}>{option.title}</li>}
                        onChange={
                            (e,value) => setNextDayStartTime(value.inputValue)
                        }
                        renderInput={(params) => <TextField 
                            size="small" 
                            {...params}
                            label="Start Time" />}
                            />
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={3}>
                    <FormControl >

                        <Button disabled={!list.length || nextDayStartTime.length === 0}
                            onClick={getChangesSummaryAndConflicts}
                            type="button"
                            variant="contained"
                            
                        >
                            Merge to next day
                            </Button>
                    </FormControl>
                </Grid>
                    </>
                    
                }
                {
                    showSummary && <>
                        <Grid item xs={12}>
                    <Typography sx={{ mt: 1 }} variant="h6" gutterBottom component="div">
                        Changes Summary
                    </Typography>
                    <Box
                    sx={{
                        height: 400,
                        width: 1,
                        '& .super-app-theme--Submitted': {
                        bgcolor: (theme) =>
                            getBackgroundColor(theme.palette.info.main, theme.palette.mode),
                        '&:hover': {
                            bgcolor: (theme) =>
                            getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
                        },
                        },

                    }}
                    >
                    <DataGrid
                        className={classes.root} 
                        getRowId={(row) => row.deliveryOrderNumber}
                        rows={changesSummary}
                        columns={changesSummaryColumns}
                        disableColumnMenu={true}
                        rowsPerPageOptions={[]}
                    />
                    </Box>
                </Grid>
                </>
                }
                {
                    conflicts.length > 0 && 
                    <Grid item xs={12}>
                    <Typography sx={{ mt: 1 }} variant="h6" gutterBottom component="div">
                        Conflicts
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Delivery Order Numbers</TableCell>
                                    <TableCell align="left">Cargo Type</TableCell>
                                    <TableCell align="left">Conflicted Slots</TableCell>
                                    <TableCell align="left" >Change Date / Slot</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                            {conflicts.map((item, index) => {
                                return (
                                    <TableRow>
                                    <TableCell>{item.deliveryOrderNumber}</TableCell>
                                    <TableCell>{item.cargoType}</TableCell>
                                    <TableCell>{formatDate(new Date(item.slotDate), 'mm/dd/yyyy')} {item.slotStartTime.split(' ')[1].slice(0,-3)} - {item.slotEndTime.split(' ')[1].slice(0,-3)}</TableCell>
                                    <TableCell>
                                        <DatePicker
                                            render={(value, openCalendar) => {
                                                return (
                                                    <TextField 
                                                        value={item.selectedDate ? formatDate(new Date(item.selectedDate), 'mm/dd/yyyy') : ''} 
                                                        placeholder="Select Date" 
                                                        onClick={openCalendar}
                                                        size="small"
                                                        sx={{width: 150}}
                                                        
                                                        error={!item.selectedDate || !item.newSlotStart || !item.newSlotEnd}

                                                        InputProps={{
                                                            style: { fontSize: 13, height: 28, paddingRight: 0 },
                                                            endAdornment: <InputAdornment position="end"><Icon height={20} /></InputAdornment>,
                                                          }}
                                                        // className={item.selectedDate && item.newSlotStart && item.newSlotEnd ? 'rmdp-input' : 'rmdp-input error'}
                                                    />
                                                )
                                            }}
                                            
                                            // render={<InputIcon className={item.selectedDate && item.newSlotStart && item.newSlotEnd ? 'rmdp-input' : 'rmdp-input error'}  defaultValue={formatDate(new Date(item.selectedDate), 'mm/dd/yyyy')} placeholder="Select Date" />}
                                            // render={<CustomComponent item={item} />}
                                            // value={item.selectedDate}
                                            label="Select Date"
                                            
                                             
                                            mapDays={MapDate} 
                                            onChange={(e) => {
                                                setIsModalOpen(true);
                                                setNewSlot({});
                                                setConflictIndex(index);
                                                const selectedDate = new Date(e.format());
                                                const isTommorrow = (new Date(changesSummary[0].slotDate).toDateString() === selectedDate.toDateString());
                                                setIsTomorrow(isTommorrow);
                                                onSelectChangeDate(index, selectedDate);
                                            }}
                                            minDate={minDate}
                                            maxDate={maxDate}>
                                            </DatePicker>
                                            <div style={{display: 'inline', verticalAlign: 'sub', whiteSpace: 'nowrap'}}>&nbsp;{item.newSlotStart} - {item.newSlotEnd}</div>
                                    </TableCell>
                                </TableRow>
                                )
                            })}

                            
                            
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                }
                {
                    showSummary && 
                    <Grid item xs={4} sm={4} md={4} lg={3}>
                    <FormControl >

                        <Button
                            onClick={onSubmit}
                            type="button"
                            variant="contained"
                            
                        >
                            { conflicts.length ? 'Resolve' : 'Apply Changes'}
                            </Button>
                    </FormControl>
                </Grid>
                }
                
                    

            <Modal
                open={isModalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ overflow: 'scroll' }}
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Select Time Slot
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <Paper elevation={3}>

                            {(() => {
                                if (slotsAvailable.length !== 0) {
                                    return (
                                        <Grid container spacing={2} style={{ paddingLeft: '15px', paddingRight: '15px', mariginTop: '15px', mariginBottom: '15px' }}>
                                            {slotsAvailable.map((value, i) =>
                                                <Grid item xs={12} sm={4}>
                                                    <Button
                                                        variant="contained"
                                                        disabled={(parseInt(value.slotsAvailable) > 0 && parseInt(value.slotsAvailableForTruck) !== 1) ? false : true}
                                                        onClick={(event) => {
                                                            setConflictIndex(i) 
                                                            onSelectTimeSlot(event, value, false)
                                                        }}

                                                        sx={{ mt: 0, mb: 2, width: '100%' }}
                                                    >
                                                        <AccessTimeIcon />
                                                        {value.timeSlotStart.slice(0, -3) + "-" + value.timeSlotEnd.slice(0, -3)}
                                                    </Button>
                                                </Grid>
                                                
                                            )}
                                           
                                        </Grid>
                                    )
                                }
                            })()}

                                            
                        </Paper>
                    </Typography>

                    {
                        isTomorrow ? <><Typography id="modal-modal-title" variant="h6" sx={{m: 3}}>
                        Or, Add a New Slot
                        </Typography>
                <Grid container spacing={2} style={{ paddingLeft: '15px', paddingRight: '15px', mariginTop: '15px', mariginBottom: '15px' }}>

                        <Grid item xs={12} sm={12} md={3} lg={3}>
                        
                                        <Autocomplete
                                disableClearable
                                id="slots-conflict"
                                options={timeSlots}
                                size="small"
                                error
                                sx={{width: '120px'}}
                                selectOnFocus
                                clearOnBlur
                                onChange={(e, value) => setNewSlot({...newSlot, timeSlotStart: value.inputValue})}
                                handleHomeEndKeys                        
                                filterOptions={filterOptions}
                                getOptionLabel={getOptionLabel}
                                getOptionDisabled={disableSlotStartTime}
                                renderOption={(props, option) => <li {...props}>{option.title}</li>}
                                renderInput={(params) => <TextField 
                                    size="small" 
                                    {...params}
                                    label="Start Time" />}
                                    /> 
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <Autocomplete
                                disableClearable
                                id="slots-conflict"
                                options={timeSlots}
                                size="small"
                                error
                                sx={{width: '120px'}}
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys      
                                onChange={(e, value) => setNewSlot({...newSlot, timeSlotEnd: value.inputValue})}                  
                                filterOptions={filterOptions}
                                getOptionLabel={getOptionLabel}
                                getOptionDisabled={disableSlotEndTime}
                                renderOption={(props, option) => <li {...props}>{option.title}</li>}
                                renderInput={(params) => <TextField 
                                    size="small" 
                                    {...params}
                                    label="End Time" />}
                                    />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3} lg={3}>
                                        <IconButton disabled={!newSlot.timeSlotStart || !newSlot.timeSlotEnd}  onClick={() => {
                                            const slot = slotsAvailable.find((slot) => slot.timeSlotStart === `${newSlot.timeSlotStart}:00` && slot.timeSlotEnd === `${newSlot.timeSlotEnd}:00`)
                                            
                                            if(!slot || (slot && (parseInt(slot.slotsAvailable) > 0 && parseInt(slot.slotsAvailableForTruck) !== 1))) {
                                                onSelectTimeSlot(null, newSlot, true);
                                            } else {
                                                return props.snackbar("Selected slot is not available!", "error", 2000);
                                            }
                                        }} sx={{ p: 0 }}>
                                            <AddCircleOutlinedIcon color="success" />
                                        </IconButton>

                                        </Grid>
                                       

                </Grid></>
                :
                <></>

                    }

                    
                        <Button sx={{float: 'right', marginTop: '20px'}} variant="contained" onClick={() => {

                        setIsModalOpen(false);

                        }}>Cancel</Button>        
                </Box>
                  
            </Modal>
                
            </Grid>

            

        </Box>
    )
}

const mapStateToProps = (state) => ({
    listPendingFormsAPI: state.bulkEdit.listAPIResponse,
    resolveAPIResponse: state.bulkEdit.resolveConflictsAPIResponse,

});

export default connect(mapStateToProps)(BulkEdit);
