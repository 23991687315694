const initialState = {
    suppliersInfo: []
}

export const GET_SUPPLIERS_INFO = "GET_SUPPLIERS_INFO";
export const SET_SUPPLIERS_INFO = "SET_SUPPLIERS_INFO";

export const getSuppliersInfo = (ctsCodeId) => {
    return {
        type: GET_SUPPLIERS_INFO,
        payload: ctsCodeId
    }
}

export const setSuppliersInfo = (data) => {
    return {
        type: SET_SUPPLIERS_INFO,
        payload: data
    }
}

export const ViewCTSReducer  = (state = initialState, action) => {
    switch(action.type) {
        case SET_SUPPLIERS_INFO: {
            return {
                ...state,
                suppliersInfo: action.payload
            }
        }
        default:
            return state 
    }
}