const initialState = {
    // ctsList: [],
    ctsInfo: {},
    searchText: '',
    searchCTSResponse: '',
    linkCTSResponse: {},
    showWarning: false 
}

export const SEARCH_CTS = 'SEARCH_CTS';
export const SET_CTS_SEARCH_RESULT = 'SET_CTS_SEARCH_RESULT';
export const SET_CTS_SEARCH_TEXT = 'SET_CTS_SEARCH_TEXT';
export const LINK_CTS = 'LINK_CTS';
export const SET_LINK_CTS_API_RESPONSE = 'SET_LINK_CTS_API_RESPONSE';
export const SET_SEARCH_CTS_API_RESPONSE = 'SET_SEARCH_CTS_API_RESPONSE';
export const CLEAR_LINK_CTS_FORM = 'CLEAR_LINK_CTS_FORM';
export const CLEAR_SELECTED_CTS = 'CLEAR_SELECTED_CTS';
export const TOGGLE_CTS_LINK_WARN_MSG = 'TOGGLE_CTS_LINK_WARN_MSG';


export const searchCTSByCodeId = (ctsCodeId) => {
    return {
        type: SEARCH_CTS,
        payload: ctsCodeId
    }
}

export const setCTSSearchResult = (ctsList) => {
    return {
        type: SET_CTS_SEARCH_RESULT,
        payload: ctsList[0]
    }
}

export const linkCTSToSupplier = (data) => {
    return {
        type: LINK_CTS,
        payload: data
    }
}

export const setLinkCTSAPIResponse = (data) => {
    return {
        type: SET_LINK_CTS_API_RESPONSE,
        payload: data
    }
}

export const setSearchCTSAPIResponse = (data) => {
    return {
        type: SET_SEARCH_CTS_API_RESPONSE,
        payload: data
    }
}

export const setCTSSearchText = (text) => {
    return {
        type: SET_CTS_SEARCH_TEXT,
        payload: text
    }
}

export const clearCTSForm = (text) => {
    return {
        type: CLEAR_LINK_CTS_FORM,
        payload: text
    }
}

export const clearSelectedCTS = () => {
    return {
        type: CLEAR_SELECTED_CTS
    }
}

export const toggleWarnMsg = (flag) => {
    return {
        type: TOGGLE_CTS_LINK_WARN_MSG,
        payload: flag
    }
}

export const linkCTSReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_CTS_SEARCH_RESULT: {
            return { 
                ...state, 
                ctsInfo: action.payload
            };
        }

        case SET_LINK_CTS_API_RESPONSE: {
            return { 
                ...state, 
                linkCTSResponse: action.payload
            };
        }

        case SET_SEARCH_CTS_API_RESPONSE: {
            return { 
                ...state, 
                searchCTSResponse: action.payload
            };
        }

        case SET_CTS_SEARCH_TEXT: {
            return { 
                ...state, 
                searchText: action.payload
            };
        }

        case CLEAR_LINK_CTS_FORM: {
            return { 
                ...initialState
            };
        }

        case CLEAR_SELECTED_CTS: {
            return { 
                ...state,
                ctsInfo: {ctsCodeName: '', ctsCode: ''} 
            };
        }

        case TOGGLE_CTS_LINK_WARN_MSG: {
            return { 
                ...state,
                showWarning: action.payload
            };
        }

        default:
            return state;
    }
}