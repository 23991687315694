import OktaSignIn from '@okta/okta-signin-widget';
const Config = require('../../config.json')

let oktaSignInClient = {};


const clientId = process.env.REACT_APP_CLIENT_ID
const redirectUri = window.location.origin+process.env.REACT_APP_REDIRECT_URI

const okta = {
    oktaSignInClient,
    signIn() {

      const url_string = window.location.href
      var url = new URL(url_string);
      var fromURI = url.searchParams.get("fromUrl");
        
      this.oktaSignInClient = new OktaSignIn({
        baseUrl: process.env.REACT_APP_OKTA_BASEURL,
        authParams: {
          issuer: process.env.REACT_APP_OKTA_ISSUER,
          pkce: false,
          responseType: ["id_token", "token"],
          scopes: ["openid", "email", "profile"],
          display: "page",
          state: fromURI || '/'
        },
        useClassicEngine: true,
        clientId,
        redirectUri,
        helpLinks: {
          help: false
        },
        colors: {
          brand: '#279989'
        },
        features: {
          selfServiceUnlock: true,
          smsRecovery: true
        },
        i18n: {
          'en': Config.okta.i18n
        }
      });
    },
    getAccountClosed(){
        this.signIn();
    
        this.oktaSignInClient.authClient.session.exists().then(async (exists) => {
          if (exists) {
            await this.oktaSignInClient.authClient.session.close();
          }
        //   window.location.href = "/Login";
          localStorage.clear();
        });
    },
    getSignInClient() {
      this.signIn();
      return this.oktaSignInClient;
    }
  };

  export default okta;