import { Grid, Typography, Box, TextField, Button, FormControl, Modal } from '@mui/material';
import {
    useNavigate, useParams
} from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCTSSearchText, searchCTSByCodeId, clearCTSForm, setCTSSearchResult, toggleWarnMsg, setLinkCTSAPIResponse, linkCTSToSupplier, setSearchCTSAPIResponse, clearSelectedCTS } from '../../store/reducers/linkCTS';
import { connect } from 'react-redux';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const LinkCTS = (props) => {
    const params = useParams();
    const navigate = useNavigate();
    const currentCTS = params.cts;
    const currentCTSId = params.old;

    const { ctsInfo, showWarning, searchText } = useSelector((state) => state.linkCTS);
    const dispatch = useDispatch();

    // const [placeholder, setPlaceholder] = useState('Enter CTS Code Id');

    // const [open, setOpen] = useState(true);
    // const [disableSearch, setDisableSearch] = useState(false);

    // const [showWarning, setShowWarning] = useState(false);

    // const [deliveryForms, setDeliveryForms] = useState([]);

    const deliveryForms = useRef([]);

    useEffect(() => {
        dispatch(clearSelectedCTS());
        dispatch(setCTSSearchText(''));
    },[dispatch]);

    useEffect(() => {
        if (props.searchCTSAPI && props.searchCTSAPI.length) {
            props.snackbar(props.searchCTSAPI, 'error');
            dispatch(setSearchCTSAPIResponse(''))
        }
    });

    useEffect(() => {
        if (Object.keys(props.linkCTSAPI).length) {
            const res = props.linkCTSAPI;
            if (res.status === 'Warn') {
                deliveryForms.current = res.message.map((forms) => forms.deliveryOrderNumber)
                // setShowWarning(true)
                dispatch(toggleWarnMsg(true));
            } else {
                dispatch(toggleWarnMsg(false));
                if (res.statusCode === '200') {
                    dispatch(setLinkCTSAPIResponse({}));
                    props.snackbar("Linked successfully!");
                    dispatch(clearCTSForm());
                    dispatch(clearSelectedCTS());
                    navigate('/admin-suppliers-mapping');
                } else if (res.statusCode === '403') {
                    navigate('/forbidden');
                    dispatch(clearCTSForm());
                    dispatch(clearSelectedCTS());
                } else {
                    dispatch(setLinkCTSAPIResponse({}));
                    props.snackbar(res.statusMessage, 'error', 5000);
                }
            }
            dispatch(setLinkCTSAPIResponse({}));
        }
    });

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '60%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        // overflow: 'scroll',
        // height: '300px',
        p: 4,

    };

    const onLink = () => {
        if(ctsInfo.status !== 'Active') {
            return props.snackbar('Selected CTS code is inactive', 'error');
        }
        const payload = {
            supplier: params.id,
            ctsCode: ctsInfo.ctsCode,
            ctsCodeName: ctsInfo.ctsCodeName,
            validateCtsCodeChange: 'true'
        }
        dispatch(linkCTSToSupplier(payload))
    }

    const onForceLink = () => {
        const payload = {
            supplier: params.id,
            ctsCode: ctsInfo.ctsCode,
            ctsCodeName: ctsInfo.ctsCodeName,
            validateCtsCodeChange: 'false'
        }
        dispatch(linkCTSToSupplier(payload))
    }

    return (
        <Box
            sx={{
                p: 2
            }}
        >
            <Grid >
                <Typography variant="h6" component="div">
                    Link CTS Code
            </Typography>
                <Box component="div" sx={{
                    pt: 2
                }}>
                    <Grid container spacing={3} sx={{ paddingTop: '24px' }}>
                        <Grid item xs={12} sm={4}>
                            <FormControl sx={{ minWidth: 120, width: '100%' }}>
                                <TextField
                                    inputProps={{ type: "search" }}

                                    id="ctsCode"
                                    name="ctsCode"
                                    label="Enter CTS Code Id"
                                    fullWidth
                                    autoComplete={false}
                                    clear
                                    // onKeyDown={() => {
                                    //     if (ctsInfo.ctsCode && ctsInfo.ctsCode.length > 0)
                                    //         dispatch(clearSelectedCTS());
                                    // }}
                                    size="small"
                                    onChange={(e,a,b) => {
                                        // if (e.target.value.length) {
                                        // } else {
                                        //     dispatch(clearSelectedCTS());
                                        // }
                                        dispatch(setCTSSearchText(e.target.value))
                                        dispatch(setCTSSearchResult([{}]));            
                                    }}
                                    value={searchText}
                                />
                            </FormControl>
                            {/* <Autocomplete
                        id="asynchronous-demo"
                        sx={{ width: '100%' }}
                        open={open}
                        size="small"
                        onOpen={() => {
                            setOpen(true);
                        }}
                        // onClose={() => {
                        //     setOpen(false);
                        // }}
                        // isOptionEqualToValue={(option, value) => option.title === value.title}
                        getOptionLabel={(option) => option.ctsCodeName}
                       
                        options={ctsList}
                        onChange={(e, value, reason) => {
                            if(reason === 'selectOption') {
                                setSelectedCTS(value)
                                dispatch(setCTSSearchText(value.ctsCodeName));
                                setDisableSearch(true);
                                setOpen(false)
                                setPlaceholder('CTS Code Id')
                            }
                            if(reason === 'clear') {
                                dispatch(setCTSSearchText(''));
                                setDisableSearch(false);
                                setOpen(false)
                                setPlaceholder('Enter CTS Code Id')
                            } 
                            dispatch(setCTSSearchResult([]));
                        }}
                        clearText
                        inputValue={searchText}
                        // noOptionsText="Type CTS Code and hit Search button"
                        freeSolo={true}
                        // loading={loading}
                        // renderOption={(props, option) => <li {...props}>{option.ctsCodeName}</li>}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                value={searchText}
                                onChange={(e) => {
                                    dispatch(setCTSSearchText(e.target.value))
                                    setOpen(true)
                                    setPlaceholder('Select CTS Code')
                                }}
                                label={placeholder}
                                InputProps={{
                                    ...params.InputProps
                                }}
                            />
                        )}
                    /> */}
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Button
                                type="button"
                                // disabled={disableSearch}
                                variant="contained"
                                onClick={() => {
                                    if (searchText.length > 0) {
                                        dispatch(searchCTSByCodeId(searchText))
                                        // dispatch(setCTSSearchText(''));
                                    } else {
                                        props.snackbar("Please enter CTS Code Id", "error");
                                    }
                                }}>
                                Search
                    </Button>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{mt: 2}}>
                        {
                            ctsInfo.ctsCodeName && ctsInfo.ctsCodeName.length && 
                            <Typography variant="span" color="primary" component="h2">
                                {ctsInfo.ctsCodeName} <Typography component="span">({ctsInfo.ctsCode})</Typography>
                            </Typography>
                        }
                        
                        
                    </Grid>

                    <br />
                    <Grid item xs={12}>
                        <Button
                            disabled={!ctsInfo.ctsCode}
                            type="button"
                            size="small"
                            variant="contained"
                            onClick={onLink}>
                            Link
                    </Button>
                        <Button
                            size="small"
                            type="button"
                            sx={{ marginLeft: '10px' }}
                            variant="outlined"
                            onClick={() => { navigate('/admin-suppliers-mapping') }}>
                            BACK
                    </Button>
                    </Grid>

                </Box>
            </Grid>
            <Modal
                open={showWarning}
                // onClose={handleClose}
                style={{ overflow: 'scroll', backgroundColor: '#279989' }}
            >
                <Box sx={style} component="div">

                    <Typography variant="h5" sx={{ textAlign: 'center' }} >
                        Warning! <InfoOutlinedIcon sx={{ verticalAlign: 'middle' }} color="warning" />
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                        <Grid container spacing={1}>

                            <Grid item xs={12} >
                                <p>
                            {deliveryForms.current.length ? 
                                <span>Remapping the supplier <Typography  color="primary" component="span">{params.id}</Typography> from <Typography color="primary" component="span">{currentCTS}</Typography> <strong>({currentCTSId})</strong> to <Typography color="primary" component="span">{ctsInfo.ctsCodeName}</Typography> <strong>({ctsInfo.ctsCode})</strong> will <Typography component="span" sx={{color: 'red'}}>CANCEL</Typography> the following delivery forms. Are you sure want to proceed?</span> :
                                        <span>Are you sure want to move this supplier <Typography  color="primary" component="span">{params.id}</Typography> from <Typography color="primary" component="span">{currentCTS}</Typography>({currentCTSId}) to <Typography color="primary" component="span">{ctsInfo.ctsCodeName}</Typography> ({ctsInfo.ctsCode})?</span>}
                                </p>
                                <p>
                                    {
                                        deliveryForms.current.map((formId, index) => <Typography variant="h6" color="primary" component="span">{formId}{index === deliveryForms.current.length - 1 ? '' : ', '}</Typography>)
                                    }
                                </p>
                            </Grid>
                        </Grid>

                    </Typography>
                    <br />
                    <Button sx={{ float: 'right' }} variant="outlined"
                        onClick={() => {
                            dispatch(toggleWarnMsg(false));
                        }}>
                        Cancel
                    </Button>
                    <Button sx={{ float: 'right', marginRight: '10px' }} variant="contained"
                        onClick={() => {
                            onForceLink()
                        }}>
                        Proceed
                    </Button>


                </Box>
            </Modal>
        </Box>
    )
}

const mapStateToProps = (state) => ({
    searchCTSAPI: state.linkCTS.searchCTSResponse,
    linkCTSAPI: state.linkCTS.linkCTSResponse,
});

export default connect(mapStateToProps)(LinkCTS);
