import * as React from 'react';
import Box from '@mui/material/Box';
import AuthLayout from '../layout/AuthLayout';
import { LogoRenderer } from '../shared/Logo';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import {useLocation} from 'react-router-dom';
function WelcomLogin() {
  const axios = require('axios');

  const navigate = useNavigate();
  const location = useLocation();

  const firstname = `${location.state.firstname}`;
  const lastname = `${location.state.lastname}`;
  const userid = `${location.state.userid}`;
  const rolename = `${location.state.rolename}`;
  const roleid = `${location.state.roleid}`;


  const handleSubmit = (event) => {
   event.preventDefault();
   const headers = {
     'Authorization': process.env.REACT_APP_API_AUTHORIZATION,
   };

  
     axios({
       method: 'post',
       url: process.env.REACT_APP_API_URL+'/createExistingUser',
       data: {
         "firstname": firstname,
         "lastname": lastname,
         "userid": userid,
         "userRole": [
             {
                "rolename": rolename,
                 "roleid": roleid
             }
         ]
       },headers: headers
     })
     .then((response) => {
       if(response.data.statusCode === '200'){
         navigate('/login');
       }
     })
     .catch((error) => {
       console.log('Error', error.message);
     });
  };
    return (
        <>
             <AuthLayout>
                <Box component="form" onSubmit={handleSubmit} className="custom-auth-container"
            sx={{
              padding: 5,
              paddingBottom: 0,
              paddingTop: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
          {LogoRenderer(true)}
          <div style={{display: 'block'}}><br/>
            <h1>Dear Cargill Registered User</h1>
            <p>We identify you have got a cargill account already.</p>
            <p>  Please click "Accept" to accept signing in using the
                existing email adderss and password.</p>
                <p>    If you want to register a new account, you can go back to sign up with a different email.
            </p>
            <Button
            type="submit"
            variant="contained"
            sx={{ mt: 2, mb: 2, float:'right' }}
            >Accept and Sign in</Button>
          </div>
      </Box>
    </AuthLayout>
           
        </>
        

        );
};

export default WelcomLogin;
