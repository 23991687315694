import Box from '@mui/material/Box';

import {Grid, Button, Pagination} from '@mui/material';
import TextField from "@mui/material/TextField";
import FormControl from '@mui/material/FormControl';
import { useState, useEffect  } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { setPageNumber, setFilter, setSort, clearFilter, setSelectedForms, setSearchExpand } from '../../../store/reducers/deliveryForms';
import { getCargoTypes, getRemarks, getCancellationReasons, getFormStatuses, getProvinces, getCities } from '../../../store/reducers/utils';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { darken, lighten } from '@mui/material/styles';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { formatDate, gridFocusNone } from '../../../utils';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import FileSaver from 'file-saver'
// import { objectToQuery } from '../../utils';
import { objectToQuery } from '../../../utils';
import store from '../../../store';
import PrintDeliveryForms from './printDeliveryForms';

const DeliveryForms = (props) => {

    const dispatch = useDispatch();
    const [minDate, setMinDate] = useState();
    const [maxDate, setMaxDate] = useState();
    const classes = gridFocusNone();

    // const deliveryForms = useSelector((state) => state.deliveryForms);
    const deliveryForms = props.deliveryForms;
    const utils = useSelector((state) => state.utils);

    useEffect(() => {
   //     dispatch(clearFilter());
        dispatch(getCargoTypes());
        dispatch(getRemarks());
        dispatch(getCancellationReasons());
        dispatch(getFormStatuses());
        dispatch(getProvinces());

    },[dispatch]);

    // useEffect(() => {
    //     if (deliveryForms.filters.province) {
    //         dispatch(getCities({ provinceId: deliveryForms.filters.province }));
    //     }
    // }, [dispatch, deliveryForms.filters.province, deliveryForms.filters.city]);
    const onProvinceChange = (e) => {
        // deliveryForms.filters.province = e.target.value.name;
        console.log(e);
   //     setValue(e);
        dispatch(setFilter({...deliveryForms.filters, province: e.target.value.name }));
       dispatch(getCities({ provinceId: e.target.value.id }));
    }

    // const onCityChange = (e) => {
    //     const value = e.target.value;
    //     dispatch(setFilter({ ...deliveryForms.filters, city: value }));
    // }

    const onPageChange = (data, value) => {
        dispatch(setPageNumber(value));
        props.search();
    }



    const CustomPagination = (pagination) => {
        return (
          <Pagination
          color="primary"
          count={pagination.pageCount}
          page={pagination.currentPage}
          onChange={onPageChange}
        />
        )
    }

    const search = () => {
        props.search();
    }

    const clearFilters = () => {
        setMinDate(null);
        setMaxDate(null);
        dispatch(clearFilter())
        props.search();
    }




    const generateReport = () => {
        const headers = {
            'Authorization': "Bearer "+localStorage.getItem("access_token")
          };
          axios.get(process.env.REACT_APP_API_AUTH_URL+`/filterSortDelieveryFormReport1?${objectToQuery(store.getState().deliveryForms.filters)}`, { headers: headers,responseType: 'arraybuffer' })
          .then(response => {

            var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, `Report_${formatDate(new Date(), 'mm-dd-yyyy-hh-mm-ss')}.xlsx`);

          })
          .catch((error) => {
              console.log('error ' + error);
          });


    }

    const onStartDateChange = (value) => {
        setMinDate(value);
        dispatch(setFilter({...deliveryForms.filters, slotDateFrom: formatDate(value, 'yyyy-mm-dd') }));
    }

    const onEndDateChange = (value) => {
        setMaxDate(value);
        dispatch(setFilter({...deliveryForms.filters, slotDateTo: formatDate(value, 'yyyy-mm-dd') }));
    }


    const [sortModel, setSortModel] = useState([{ field: 'deliveryOrderNumber', sort: 'desc' }]);

    const handleSortModelChange = (newModel) => {
        setSortModel(newModel);
        dispatch(setSort({sortOrder: newModel[0].sort, sortBy: newModel[0].field}));
        props.search();
      };


    const getBackgroundColor = (color, mode) =>
      mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

    const getHoverBackgroundColor = (color, mode) =>
      mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

    return (
        <div>
            <Box style={{paddingTop:'10px'}}>

                <Accordion expanded={deliveryForms.searchExpanded} onChange={(e, expanded) => { dispatch(setSearchExpand(expanded)) }}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography style={{display: 'flex', justifyContent: 'space-between'}}>
                        <SearchIcon/>
                        <span>Search</span>
                    </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={3}>
                                <FormControl sx={{ m: 1, minWidth: 120,width: '100%'}}>
                                    <TextField
                                        id="deliveryOrderNo"
                                        label="Delivery Order Number"
                                        autoComplete="deliveryOrderNo"
                                        value={deliveryForms.filters.deliveryOrderNumber}
                                        variant="standard"
                                        onChange={(e)=>dispatch(setFilter({...deliveryForms.filters, deliveryOrderNumber: e.target.value }))}
                                    />
                                </FormControl>
                            </Grid>
                            {props.role === 'admin' &&
                            <Grid item xs={12} sm={2}>
                                <FormControl sx={{ m: 1, minWidth: 120,width: '100%' }}>
                                    <TextField
                                        id="supplier"
                                        label="Supplier"
                                        value={deliveryForms.filters.supplier}
                                        autoComplete="supplier"
                                        variant="standard"
                                        onChange={(e)=>dispatch(setFilter({...deliveryForms.filters, supplier: e.target.value }))}
                                    />
                                </FormControl>
                            </Grid>}
                            <Grid item xs={12} sm={3}>
                                <FormControl sx={{ m: 1, minWidth: 120,width: '100%' }}>
                                    <TextField
                                        id="truck-details"
                                        label="Truck/Vehicle Plate Number"
                                        value={deliveryForms.filters.truckNumber}
                                        autoComplete="vehicle details"
                                        variant="standard"
                                        onChange={(e)=>dispatch(setFilter({...deliveryForms.filters, truckNumber: e.target.value }))}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={props.role === 'admin' ? 2 : 3}>
                                <FormControl sx={{ m: 1, minWidth: 120,width: '100%' }} variant="standard">
                                    <InputLabel id="type-of-cargo-label">Type of Cargo</InputLabel>
                                    <Select
                                        labelId="type-of-cargo"
                                        id="type-of-cargo"
                                        label="Type of Cargo"
                                        value={deliveryForms.filters.cargoType}
                                        onChange={(e)=>dispatch(setFilter({...deliveryForms.filters, cargoType: e.target.value }))}
                                        >
                                        {utils.cargoTypes.map(({ id, name }) => <MenuItem value={name} >{name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {
                                props.role === 'admin' &&
                                <Grid item xs={12} sm={3}>
                                <FormControl sx={{ m: 1, minWidth: 120,width: '100%' }} variant="standard">
                                    <InputLabel id="province-select-standard-label">Province</InputLabel>
                                    <Select
                                        labelId="province-select-standard-label"
                                        id="province"
                                        label="province"
                                        onChange={onProvinceChange}
                                        defaultValue={deliveryForms.filters.province}

                                        >
                                        {utils.provinces.map((provinceObject) => <MenuItem value={provinceObject} >{provinceObject.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>
                            }
                           {
                            props.role === 'admin' &&
                            <Grid item xs={12} sm={3}>
                                <FormControl sx={{ m: 1, minWidth: 120,width: '100%' }} variant="standard">
                                    <InputLabel id="city-select-standard-label">City</InputLabel>
                                    <Select
                                        labelId="city-select-standard-label"
                                        id="city"
                                        label="city"
                                        value={deliveryForms.filters.city}
                                        onChange={(e)=>dispatch(setFilter({...deliveryForms.filters, city: e.target.value }))}
                                        >
                                        {utils.cities.map((  {id,name}) => <MenuItem value={name} >{name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>
                            }

                            <Grid item xs={12} sm={props.role === 'admin' ? 2 : 3}>
                                <FormControl variant="standard" sx={{ m: 1, minWidth: 120,width: '100%' }}>
                                    <InputLabel id="remarks-label">Remarks</InputLabel>
                                    <Select
                                        labelId="remarks"
                                        id="remarks"
                                        label="Remarks"
                                        value={deliveryForms.filters.remarks}
                                        onChange={(e)=>dispatch(setFilter({...deliveryForms.filters, remarks: e.target.value }))}>
                                        {utils.remarks.map(({ id, name }) => <MenuItem value={name} >{name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <FormControl variant="standard" sx={{ m: 1, minWidth: 120,width: '100%' }}>
                                    <InputLabel id="remarks-label">Cancellation Reason</InputLabel>
                                    <Select
                                        labelId="cancellationreason"
                                        id="cancellationreason"
                                        label="Cancellation Reason"
                                        onChange={(e)=>dispatch(setFilter({...deliveryForms.filters, cancellationReason: e.target.value }))}
                                        value={deliveryForms.filters.cancellationReason}>
                                        {/* {utils.cancellationReasons.map(({ id, name }) => <MenuItem value={id} >{name}</MenuItem>)} */}

                                        {utils.cancellationReasons.map(({ id, name }) => <MenuItem value={name} >{name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <FormControl variant="standard"  sx={{ m: 1, minWidth: 120,width: '100%' }}>
                                    <InputLabel id="status-label">Status</InputLabel>
                                    <Select
                                        labelId="status"
                                        id="status"
                                        label="Status"
                                        value={deliveryForms.filters.status}
                                        onChange={(e)=>dispatch(setFilter({...deliveryForms.filters, status: e.target.value }))}>
                                        {props.role === 'admin' ?
                                        /** Hide Draft for Admin */
                                        utils.statusList.filter(({id, name}) => name !== 'Draft').map(({ id, name }) =><MenuItem value={name} >{name}</MenuItem>)
                                        :
                                        utils.statusList.map(({ id, name }) =><MenuItem value={name} >{name}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        value={deliveryForms.filters.slotDateFrom}
                                        label="Start Date"
                                        onChange={onStartDateChange}
                                        maxDate={maxDate}
                                        renderInput={(params) => <TextField  style={{paddingTop: '8px', width: '100%'}} variant="standard" {...params} />}
                                    />
                                </LocalizationProvider>

                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="End Date"
                                        value={deliveryForms.filters.slotDateTo}
                                        onChange={onEndDateChange}
                                        minDate={minDate}
                                        renderInput={(params) => <TextField style={{paddingTop: '8px', width: '100%'}} variant="standard" {...params} />}
                                    />
                                </LocalizationProvider>

                            </Grid>
                            {
                                props.role === 'admin' &&
                                <Grid item xs={12} sm={3}>
                                    <FormControl sx={{ m: 1, minWidth: 120,width: '100%' }}>
                                        <TextField
                                            id="cts-code"
                                            label="CTS Code ID"
                                            value={deliveryForms.filters.ctsCode}
                                            autoComplete="cts code"
                                            variant="standard"
                                            onChange={(e)=>dispatch(setFilter({...deliveryForms.filters, ctsCode: e.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                            }
                            {/* <Grid item xs={12} sm={3}>
                                <FormControl sx={{ m: 1, minWidth: 120,width: '100%' }}>
                                    <TextField
                                        id="cts-code"
                                        label="CTS Code Name"
                                        value={deliveryForms.filters.ctsCodeName}
                                        autoComplete="cts code name"
                                        variant="standard"
                                        onChange={(e)=>dispatch(setFilter({...deliveryForms.filters, ctsCodeName: e.target.value }))}
                                    />
                                </FormControl>
                            </Grid> */}
                            <Grid item xs={12} sm={12}>
                                {
                                    props.role === 'admin' &&
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        sx={{  mb: 2 }}
                                        style={{float: 'right', marginRight:'10px'}}
                                        onClick={generateReport}>
                                        Generate Report
                                    </Button>
                                }

                            <Button
                                        type="button"
                                        variant="outlined"
                                        sx={{  mb: 2 }}
                                        style={{float: 'right', marginRight:'10px'}}
                                        onClick={clearFilters}>
                                        Clear
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="contained"
                                        sx={{  mb: 2 }}
                                        style={{float: 'right', marginRight:'10px'}}
                                        onClick={search}>
                                        Search
                                    </Button>
                                </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>


            </Box>
            <div style={{ height: 500, width: '100%', paddingTop:'10px' }}>
                <Grid container spacing={2}>

                    <Grid item xs={6}>
                        <Typography style={{ paddingBottom:'10px' }} component="div">
                            List Delivery Form
                        </Typography>

                    </Grid>
                    <Grid item xs={6}>
                        {
                            deliveryForms.selectedForms.length > 0 &&
                            <PrintDeliveryForms snackbar={props.snackbar} formIds={deliveryForms.selectedForms} />
                        }

                    </Grid>
                </Grid>

                <Box
                    sx={{
                        height: 400,
                        width: 1,
                        '& .super-app-theme--Submitted': {
                        bgcolor: (theme) =>
                            getBackgroundColor(theme.palette.info.main, theme.palette.mode),
                        '&:hover': {
                            bgcolor: (theme) =>
                            getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
                        },
                        },

                    }}
                    >
                    <DataGrid
                        getRowClassName={
                            (params) => {
                                if (props.role === "supplier" && params.id === deliveryForms.newEntryCode){
                                    return `super-app-theme--${params.row.status}`
                                }
                            }
                        }
                        checkboxSelection={props.role === 'admin'}
                        className={classes.root}
                        getRowId={(row) => row.deliveryOrderNumber}
                        rows={deliveryForms.list}
                        columns={props.columns}
                        disableColumnMenu={true}
                        isRowSelectable={() => props.role === 'admin'}
                        pageSize={deliveryForms.pagination.pageSize}
                        components={{
                            Pagination: () => {return CustomPagination(deliveryForms.pagination)},
                        }}
                        sortingMode="server"
                        sortModel={sortModel}
                        onSortModelChange={handleSortModelChange}
                        selectionModel={deliveryForms.selectedForms}
                        keepNonExistentRowsSelected={true}
                        onSelectionModelChange={(formIds) => {
                           dispatch(setSelectedForms(formIds));
                        }}
                    />
                </Box>
            </div>
        </div>
    )
}

export default DeliveryForms;