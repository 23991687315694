import { Grid, Typography, Box, Modal } from '@mui/material';
import { useEffect } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

export const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    // overflow:'scroll',
    p: 4,
  };

const SupplierCTSLink = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const headers = {
            'Authorization': "Bearer " + localStorage.getItem("access_token")
             };
        axios({
            method: 'PUT',
            url: process.env.REACT_APP_API_AUTH_URL + '/getCTSCodeForSupplier',
            data: {
            }, headers: headers
          })
            .then((response) => { 
            if (response.data.message[0] !== null) {
                navigate("/supplier-home");
            }
          })
    }, [navigate])

    return (
        <div>
            <Modal
                open={true}


                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ overflow: 'scroll', backgroundColor: '#279989' }}
            >
                <Box sx={style} component="div">

                    <Typography variant="h5" color="primary" sx={{textAlign: 'center'}} gutterBottom>
                    <InfoOutlinedIcon sx={{verticalAlign: 'middle', pr: '5px', fontSize: '35px'}} color="warning" />
                        &nbsp;Contact AM to link your account with CTS code.
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={1}>
                               
                            </Grid>
                            <Grid item xs={12} sm={11}>
                                <p><h4>Please contact your Area Manager to link your account with CTS code.</h4></p>
                                <p>This is mandatory, can't proceed without linking CTS code.</p>
                            </Grid>
                        </Grid>
                    </Typography>
                    {/* <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Button
                            type="button"
                            variant="contained"
                            sx={{  mb: 2 }}
                            style={{float: 'right', marginRight:'10px'}}
                            onClick={() => {setShow(false)}}>
                            OK
                        </Button>
                        </Grid>
                    </Grid> */}
                </Box>
            </Modal>
        </div>
    )
}

export default SupplierCTSLink;