import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllCTS, setPageNumber, setSort, setFilter, clearFilter } from '../../store/reducers/listCTS';
import { Grid, Button, Tooltip, Box, IconButton, FormControl, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import {  Link } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
    DataGrid
} from '@mui/x-data-grid';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';

const ListCTS = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const cts = useSelector((state) => state.listCTS);
    const [sortModel, setSortModel] = useState([{ field: 'ctsCodeName', sort: 'asc' }]);

    useEffect(() => {
        dispatch(getAllCTS());
    }, [dispatch]);

    const cellTooltip = (value) => {
        return (
            value && value !== '' ?
                <Tooltip title={value} >
                    <span className="MuiDataGrid-cellContent">{value}</span>
                </Tooltip> :
                <span className="MuiDataGrid-cellContent">-</span>
        )
    }

    const columns = [
        {
            field: 'ctsCode', headerName: 'CTS Code ID', flex: 2,
            renderCell: (node) => cellTooltip(node.value)
        },
        {
            field: 'ctsCodeName', headerName: 'CTS Code Name', flex: 2,
            renderCell: (node) => cellTooltip(node.value)
        },
        { field: 'province', headerName: 'Province', flex: 2, renderCell: (node) => cellTooltip(node.value) },
        { field: 'city', headerName: 'City', flex: 2, renderCell: (node) => cellTooltip(node.value) },
        {
            field: "Actions",
            flex: 1,
            headerAlign: 'center',
            sortable: false,
            renderCell: (node) => {
                return (
                    <div>
                        <IconButton>
                            <Tooltip title="VIEW">
                                <VisibilityRoundedIcon onClick={(e) => {
                                    e.stopPropagation();
                                    navigate(`/admin-cts/view/${node.row.ctsCode}`);

                                }
                                } color='primary' />
                            </Tooltip>
                        </IconButton>
                        <IconButton>
                            <Tooltip title="EDIT">
                                <EditRoundedIcon onClick={(e) => {
                                    e.stopPropagation();
                                    navigate('/admin-cts/edit/' + node.row.ctsCode);

                                }
                                } color='primary' />
                            </Tooltip>
                        </IconButton>
                    </div>
                );
            }
        }
    ];

    const onPageChange = (data, value) => {
        dispatch(setPageNumber(value));
        dispatch(getAllCTS());
    }

    const CustomPagination = (pagination) => {
        return (
          <Pagination 
          color="primary"
          count={pagination.pageCount}
          page={pagination.currentPage}
          onChange={onPageChange}
        />
        )
    }

    const handleSortModelChange = (newModel) => {
        setSortModel(newModel);
        dispatch(setSort({sortOrder: newModel[0].sort, sortBy: newModel[0].field}));
        dispatch(getAllCTS());
    };

    const search = () => {
        dispatch(setPageNumber(1));
        dispatch(getAllCTS());
    }

    const clearFilters = () => {
        dispatch(clearFilter());
        dispatch(getAllCTS());
    }

    return (
        <div>
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    {/* <InsertLinkIcon onClick={showPopup} sx={{ ml: 1, float: 'right' }} /> */}
                    <Link to="./add" >
                        <Button variant="contained" style={{ float: 'right', marginRight: '10px' }}>Add CTS Code<AddCircleOutlineIcon sx={{ ml: 1 }} /></Button>
                    </Link>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControl sx={{ m: 1, minWidth: 120,width: '100%'}}>
                        <TextField
                            id="cts-code-id-search"
                            label="CTS Code ID"
                            value={cts.filters.ctsCode}
                            variant="standard"
                            onChange={(e)=>dispatch(setFilter({...cts.filters, ctsCode: e.target.value }))}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControl sx={{ m: 1, minWidth: 120,width: '100%'}}>
                        <TextField
                            id="cts-code-name-search"
                            label="CTS Code Name"
                            value={cts.filters.ctsCodeName}
                            variant="standard"
                            onChange={(e)=>dispatch(setFilter({...cts.filters, ctsCodeName: e.target.value }))}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Button
                        type="button"
                        variant="outlined"
                        sx={{  mb: 2 }}
                        style={{float: 'right', marginRight:'10px'}}
                        onClick={clearFilters}>
                        Clear
                    </Button>
                    <Button
                        type="button"
                        variant="contained"
                        sx={{  mb: 2 }}
                        style={{float: 'right', marginRight:'10px'}}
                        onClick={search}>
                        Search
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                            pagination
                            // rowsPerPageOptions={[5]}
                            getRowId={(row) => `${row.ctsCode}${new Date().getTime()}`}
                            disableColumnMenu={true}
                            isRowSelectable={() => false}
                            pageSize={cts.pagination.pageSize}
                            components={{
                                Pagination: () => { return CustomPagination(cts.pagination) },
                            }}
                            columns={columns}
                            rows={cts.list}
                            sortingMode="server"
                            sortModel={sortModel}
                            onSortModelChange={handleSortModelChange}
                        />
                    </Box>
                </Grid>

            </Grid>
        </div>
    )
}

export default ListCTS;