import React, { useEffect } from 'react';

import AuthLayout from '../layout/AuthLayout';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Loading from '../shared/Loading';
import jwtDecode from 'jwt-decode';

const decodeToken = (accessToken) => {

  var base64Url = accessToken.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}


export default function LoginCallback(props) {
  const navigate = useNavigate();
  const getUserGroups = (accessToken, state) => {
    const headers = {
      'Authorization': "Bearer " + accessToken
    };
    const supplier_id = process.env.REACT_APP_SUPPLIER_ID
    const security_id = process.env.REACT_APP_SECURITY_GUARD_ID
    const driver_id = process.env.REACT_APP_DRIVER_ID

    const fromUrl = state;



    axios({
      method: 'post',
      url: process.env.REACT_APP_API_AUTH_URL + '/getGroupsByUserId',
      data: {
      }, headers: headers
    })
      .then((response) => {

        var roleid = response.data.message[0].roleid;
        localStorage.setItem("roleId", roleid);
        if (roleid !== security_id) {
        axios({
          method: 'post',
          url: process.env.REACT_APP_API_AUTH_URL + '/mandateProfilePhone',
          data: {
          }, headers: headers
        })
          .then((response) => { 
            // Prompt for phone number only if it is not exists
            if (response.data.statusMessage === 'Mobile Number for the User not Exists in OKTA') {
            // if (response.data.statusCode === '500') {
            //  navigate("/phonenumber-popup");
              navigate("/shared-phonenumber-update",{ state: {
                roleid:roleid}
                });
            } else {
              if(roleid === supplier_id) {
                axios({
                  method: 'PUT',
                  url: process.env.REACT_APP_API_AUTH_URL + '/getCTSCodeForSupplier',
                  data: {
                  }, headers: headers
                })
                  .then((response) => { 
                    if (response.data.message[0] === null) {
                     navigate("/supplier-cts-link");
                  }
                
                })
              }
            }
        
        })
      }
      
        if (response.data.statusCode === '200') {
          if (roleid === supplier_id) {
            navigate("/supplier-home");
            fromUrl !== '/'? navigate(fromUrl) : navigate("/supplier-home")
          }
          else if (roleid === security_id) {
            fromUrl !== '/'? navigate(fromUrl) : navigate("/security-home")
          }
          else if (roleid === driver_id) {
            
            fromUrl !== '/'? navigate(fromUrl) : navigate("/driver-home")
          }
          else {
            fromUrl !== '/'? navigate(fromUrl) : navigate("/home")
          }
        }
       
      })
      .catch((error) => {
        console.log('Error', error.message);
      });
  }
  const getAccessToken = (code) => {

    const headers = {
      'Authorization': process.env.REACT_APP_API_AUTHORIZATION
    };
    axios.get(process.env.REACT_APP_API_URL+`/getOktaToken?redirectUrl=${window.location.origin}/login/callback&code=${code}`, { headers: headers })
    .then(response => {
      localStorage.setItem("access_token", response.data.accessToken);
      navigate("/monitor-trucks");
    })
    .catch((error) => {
        console.log('error ' + error);
    });


  }

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.hash);
    const accessToken = queryParams.get('access_token');
    const idToken = queryParams.get('#id_token');
    const state = queryParams.get('state');
    const queryParams2 = new URLSearchParams(window.location.href);

    const code = queryParams2.get('code');

    if (!accessToken) {
      // cargill login
      const authorizeUrl = encodeURI(`${process.env.REACT_APP_OKTA_ISSUER}/v1/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&response_type=id_token token code&scope=openid email profile offline_access&redirect_uri=${window.location.origin}/login/callback&state=onecargillapp&nonce=onecargill`);
      
      if(authorizeUrl.includes(process.env.REACT_APP_OKTA_ISSUER) && authorizeUrl.includes(process.env.REACT_APP_CLIENT_ID)) {
          window.location.replace(decodeURI(authorizeUrl));
      }
    } else {
      const user = jwtDecode(idToken);
      localStorage.setItem("user", user.name);

      var userDetails = decodeToken(accessToken);
      var userGroups = userDetails.groups;

      if(userGroups.includes("COPRA_PH_SlotBooking_Monitor")){
        localStorage.setItem("roleId", 'monitor');
        getAccessToken(code)

      } else if (userGroups.includes("COPRA_PH_SlotBooking_Scanner_Staff") || userGroups.includes("COPRA_PH_SlotBooking_SCANNER_STAFF")) {
          localStorage.setItem("access_token", accessToken);
          localStorage.setItem("roleId", 'scanner');
          navigate("/scanner-home");
      } else {
        localStorage.setItem("access_token", accessToken);
        if ((/@crgl/).test(user.email.toLowerCase()) || (/@cargill/).test(user.email.toLowerCase())) {
          localStorage.setItem("roleId", 'admin');
          navigate("/admin-view-delivery");
        } else {
          getUserGroups(accessToken, state);
        }
      }

    }
  });
  return (
    <AuthLayout>
      <Loading />
    </AuthLayout>
  );
};


