
import { Switch, Grid, Checkbox, Typography, Box, TextField, FormControl, Button } from '@mui/material';
import React, {  useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTemplateFields,initTemplate, addTemplateAPIResponse, setSlotErrorIndex  } from '../../store/reducers/templateForm';
import { getCargoTypes } from '../../store/reducers/utils';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';



const ViewTemplateForm = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { data, slotErrorIndex } = useSelector((state) => state.templateForm);

    useEffect(() => {
        if(props.mode !== 'edit') {
            dispatch(initTemplate()); 
        }
        dispatch(getCargoTypes());
    },[dispatch, props.mode]);



    useEffect(() => {
        if(Object.keys(props.onAddTemplate).length) {
            const res = props.onAddTemplate;
            dispatch(addTemplateAPIResponse({}));
            if(res.statusCode === '200') {
                props.snackbar(`${props.mode === 'edit' ? 'Updated' : 'Created'} Successfully!`);
                dispatch(initTemplate());
                navigate('/admin-template-management');
            } else if(res.statusCode === '403') {
                navigate('/forbidden');
            } else {
                props.snackbar(res.statusMessage, 'error', 5000);
                dispatch(setSlotErrorIndex(parseInt(res.code)));
            }
        }
    });





    const onChangeActive = (e) => { 
        if(e.target.checked === false && data.templateState) {
            dispatch(setTemplateFields({...data, templateState: false, templateStatus: e.target.checked}));
        } else {
            dispatch(setTemplateFields({...data, templateStatus: e.target.checked}));
        }
    }

    return (

        <Box
            sx={{
                p: 1
            }}
        >
            <Typography sx={{mt: 1}} variant="h6" gutterBottom component="div">
                View Template
            </Typography>
            <Grid container spacing={3} sx={{ paddingTop: '24px' }}>
                <Grid item xs={12} sm={5}>
                    <FormControl sx={{ minWidth: 120,width: '100%' }}>
                        <TextField
                        
                            id="deliveryOrderNo"
                            label="Template Name"
                            value={data.templateName}
                            autoComplete="templateName"
                            size="small"
                            inputProps={
                                { readOnly: true, }
                            }
                        />
                    </FormControl>
                </Grid>  
                       
                    <Grid item xs={6} sm={2} md={1} lg={1} sx={{display: 'flex'}}>
                            <FormControlLabel control={
                            <Checkbox 				
                                inputProps={
                                    { readOnly: true, }
                                } checked={data.templateState} />
                        } label="Default" />
                        {
                            props.mode === 'edit' && 
                            <FormControlLabel control={
                                <Switch 
                                    inputProps={
                                        { readOnly: true, }
                                    } disabled={true} checked={data.templateStatus} onChange={onChangeActive} />
                            } label={data.templateStatus ? 'Active' : 'Inactive'} />
                        }
                            
                    </Grid>
                    
            </Grid>

            {data.slotsInTemplate.map((value, index) => {
                return <div key={index}>
                    <Grid container spacing={2} sx={{ paddingTop: '24px' }}>
                        <Grid item xs={12} sm={3}>
                            <FormControl >
                                <TextField
                                    id="slot-start-time"
                                    label="Slot Start Time"
                                    value={value.timeSlotStart}
                                    autoComplete="slot-start-time"
                                    size="small"
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                    error={slotErrorIndex === index} 
                                />
                            </FormControl>

                        </Grid>
                        
                        <Grid item xs={12} sm={3}>

                            <FormControl >
                                <TextField
                                    id="slot-end-time"
                                    label="Slot End Time"
                                    value={value.timeSlotEnd}
                                    autoComplete="slot-end-time"
                                    size="small"
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                    error={slotErrorIndex === index} 
                                />
                            </FormControl>

                        </Grid>

                        <Grid item xs={12} sm={3}>

                            <FormControl >
                                <TextField
                                    id="type-of-cargo"
                                    label="Type of Cargo"
                                    value={value.cargoType}
                                    autoComplete="type-of-cargo"
                                    size="small"
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                    error={slotErrorIndex === index} 
                                />
                            </FormControl>

                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <FormControl >
                                <TextField
                                    id="slot-count"
                                    label="#Slots"
                                    value={value.numberOfSlots}
                                    autoComplete="slots"
                                    size="small"
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                    error={slotErrorIndex === index} 
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
            })}
            <Grid container spacing={3} sx={{ paddingTop: '24px' }}>
                <Grid item xs={12}>
                    <Button
                        type="button"
                        variant="contained"
                        sx={{ marginRight:'10px' }}
                        
                        onClick={() => navigate('/admin-template-management')}>
                            Back
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}

const mapStateToProps = (state) => ({
    onAddTemplate: state.templateForm.addTemplateAPIResponse,
});

export default connect(mapStateToProps)(ViewTemplateForm);