import React, { Component } from 'react';
import Typography from '@mui/material/Typography';
import Loading from '../../shared/Loading';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useParams, useNavigate } from 'react-router-dom';
import FormatDate from '../../shared/FormatDate';

const withRouter = WrappedComponent => props => {
    const params = useParams();
    const navigate = useNavigate();
    return (
      <WrappedComponent
        {...props}
        params={params}
        navigate={navigate}
        // etc...
      />
    );
  };
const axios = require('axios');
// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     boxShadow: 24,
//     height: '60%',
//     p: 4,
//   };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    boxShadow: 'none'
  }));

class SecurityViewDetails extends Component {

    constructor(props) {
        super(props);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleApproveStatus = this.handleApproveStatus.bind(this);       
        this.handleChangeEndStatus = this.handleChangeEndStatus.bind(this);       
        this.state = {details: null, provinceName: null, cityName: null,cargoTypeName: null, remarksName: null,
            isModalOpen: false,
            imageDate: null
        };
    }

    componentDidMount(){
        this.getDeliveryDetails()
    }

    getDeliveryDetails(){
        // console.log(this.props.match.params.objectId)
        // let searchParams = new URLSearchParams(url.search);

        const headers = {
            'Authorization': "Bearer "+localStorage.getItem("access_token")
        };
        const params = {objectId: this.props.params.id}
        axios.get(process.env.REACT_APP_API_AUTH_URL+'/viewDeliveryForm', { headers: headers, params: params })
        .then(response => {
            this.setState({details: response.data.message[0]})
            this.getProvinceDetails(response.data.message[0].provinceId)
            this.getCityDetails(response.data.message[0].provinceId, response.data.message[0].cityId)
            this.getCargiType(response.data.message[0].cargoType)
            this.getRemarksName(response.data.message[0].remarks)
        })
        .catch((error) => {
            console.log('error ' + error);
        });
    }
    getRemarksName(remarksId){
        const headers = {
            'Authorization': "Bearer "+localStorage.getItem("access_token")
        };
        const params = {remarksId: remarksId}
        axios.get(process.env.REACT_APP_API_AUTH_URL+'/remarks', { headers: headers, params: params })
        .then(response => {
            this.setState({remarksName: response.data.message[0].name})
        })
        .catch((error) => {
            console.log('error ' + error);
        });
    }
    getCargiType(cargoTypeId){
        const headers = {
            'Authorization': "Bearer "+localStorage.getItem("access_token")
        };
        const params = {cargoTypeId: cargoTypeId}
        axios.get(process.env.REACT_APP_API_AUTH_URL+'/cargotypes', { headers: headers, params: params })
        .then(response => {
            this.setState({cargoTypeName: response.data.message[0].name})
        })
        .catch((error) => {
            console.log('error ' + error);
        });
    }
    getCityDetails(provinceId, cityId){
        const headers = {
            'Authorization': "Bearer "+localStorage.getItem("access_token")
        };
        const params = {provinceId: provinceId,cityId: cityId}
        axios.get(process.env.REACT_APP_API_AUTH_URL+'/cities', { headers: headers, params: params })
        .then(response => {
            this.setState({cityName: response.data.message[0].name})
        })
        .catch((error) => {
            console.log('error ' + error);
        });
    }
    getProvinceDetails(provinceId){
        const headers = {
            'Authorization': "Bearer "+localStorage.getItem("access_token")
        };
        const params = {provinceId: provinceId}
        axios.get(process.env.REACT_APP_API_AUTH_URL+'/provinces', { headers: headers, params: params })
        .then(response => {
            this.setState({provinceName: response.data.message[0].name})
        })
        .catch((error) => {
            console.log('error ' + error);
        });
    }
    handleModalClose(){
        this.setState({isModalOpen: false})
    }

    handleApproveStatus(){
        const headers = {
            'Authorization': "Bearer "+localStorage.getItem("access_token")
        };
        const params = {objectId: this.props.params.id, action: 'Truck-In'}
        
        axios.put(process.env.REACT_APP_API_AUTH_URL+'/updateDeliveryStatus',null, { params: params, headers: headers })
        .then(response => {
            if (response.data.statusCode === '200') {
                this.props.navigate('/security-home');
                this.props.snackbar("Form confirmed successfully");                
            }
        })
        .catch((error) => {
            console.log('error ' + error);
        });        
    }

    // change status to Truck-out
    handleChangeEndStatus(){
        const headers = {
            'Authorization': "Bearer "+localStorage.getItem("access_token")
        };
        const params = {objectId: this.props.params.id}
        
        axios.put(process.env.REACT_APP_API_AUTH_URL+'/updateDFStatusToTruckOut',null, { params: params, headers: headers })
        .then(response => {
            if (response.data.statusCode === '200') {
                this.props.navigate('/security-home');
                this.props.snackbar("Delivery form updated successfully");                
            }
        })
        .catch((error) => {
            console.log('error ' + error);
        });        
    }
    
    render() {
            if(this.state.details !== null){
                return (
                        // <Layout>
                        <>
                            <Typography variant="h6" gutterBottom>
                                View Delivery Form 
                            </Typography>
                            <TableContainer>
                                <Paper variant="outlined">
                                    <Grid container spacing={2}>
                                        <Grid item xs={5}>
                                            <Item style={{fontWeight: 'bold'}}>Supplier Name</Item>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Item>{this.state.details.entryName}</Item>                                        
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Item style={{fontWeight: 'bold'}}>Driver's Mobile Number</Item>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Item>{this.state.details.driverContactNo}</Item>                                        
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Item style={{fontWeight: 'bold'}}>Province</Item>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Item>{this.state.provinceName}</Item>                                        
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Item style={{fontWeight: 'bold'}}>City</Item>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Item>{this.state.cityName}</Item>                                        
                                        </Grid>                                                                                                            
                                    </Grid>
                                </Paper>

                                <br/>

                                <Paper variant="outlined">
                                    <Grid container spacing={2}>
                                        <Grid item xs={5}>
                                            <Item style={{fontWeight: 'bold'}}>Truck/Vehicle Plate Number</Item>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Item>{this.state.details.truckNo}</Item>                                        
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Item style={{fontWeight: 'bold'}}>Quantity (MT)</Item>
                                        </Grid>
                                        <Grid item xs={7}>
                                            {/* <Item>{this.state.details.quantity}</Item> */}
                                            <Item>{this.state.details.quantitystr}</Item>  
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Item style={{fontWeight: 'bold'}}>Type of Cargo</Item>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Item>{this.state.cargoTypeName}</Item>                                        
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Item style={{fontWeight: 'bold'}}>Remarks</Item>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Item>{this.state.remarksName}</Item>                                        
                                        </Grid>                                                                                                            
                                    </Grid>
                                </Paper>                            
                    
                                    <br/>
                                    <Paper variant="outlined">
                                    <Grid container spacing={2}>
                                        <Grid item xs={5}>
                                            <Item style={{fontWeight: 'bold'}}>Date</Item>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Item><FormatDate value={this.state.details.slotDate}/></Item>                                        
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Item style={{fontWeight: 'bold'}}>Time Slot</Item>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Item>{this.state.details.slotStartTime.slice(0, -3)+"-"+ this.state.details.slotEndTime.slice(0, -3)}</Item>                                        
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Item style={{fontWeight: 'bold'}}>Status</Item>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Item>{this.state.details.status}</Item>                                        
                                        </Grid>
                                        {this.state.details.status === 'Cancelled' ?
                                            <><Grid item xs={5}>
                                                <Item style={{ fontWeight: 'bold' }}>Cancellation Reason</Item>
                                            </Grid>
                                                <Grid item xs={7}>
                                                    <Item>{this.state.details.cancelReason}</Item>
                                                </Grid></>
                                            :
                                            null
                                                }
                                                {/* <div> */}
                                                {(() => {
                                                    if (this.state.details.status === 'Submitted') {
                                                    return (
                                                        <>
                                                            <Grid item xs={5}>
                                                    <Item style={{fontWeight: 'bold'}}>Actions</Item>
                                                </Grid>
                                                <Grid item xs={7}>
                                               
                                                 <Item><Button style={{textTransform: "none"}} variant="contained" onClick={this.handleApproveStatus}>Truck-In</Button></Item>
                                                </Grid> 
                                                        </>
                                                    )
                                                    }
                                                    else if (this.state.details.status === 'Weigh-In-Tare' || this.state.details.status === 'Weigh-In-Gross' || this.state.details.status === 'Dumping-In' || this.state.details.status === 'Dumping-Out' || this.state.details.status === 'Truck-In') {
                                                        return (
                                                            <>
                                                                <Grid item xs={5}>
                                                                    <Item style={{fontWeight: 'bold'}}>Actions</Item>
                                                                </Grid>
                                                                <Grid item xs={7}>
                                                                    <Item><Button style={{textTransform: "none"}} variant="contained" onClick={this.handleChangeEndStatus}>Truck-Out</Button></Item>
                                                                </Grid> 
                                                            </>
                                                        )
                                                    }
                                                    else
                                                    {
                                                        return (
                                                            <>
                                                                <Grid item xs={5}>
                                                        <Item ></Item>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                   
                                                    <Item >-</Item>
                                                    </Grid> 
                                                            </>
                                                        )
                                                    }
                                                    
                                                })()}     
                                                {/* </div>  */}

                                               
                                                 
                                                
                                                                                                                               
                                    </Grid>
                                </Paper>                                 
                            </TableContainer> 
                            </>
                        // </Layout>
                );
            } else {
                return(
                    // <Layout>
                        <Loading/>
                    // </Layout>
                );
            }
    }
}
 
export default withRouter(SecurityViewDetails);

