import { GET } from '../requests/requests';
import { put, call } from 'redux-saga/effects';
import { setSuppliersList } from '../../store/reducers/listSuppliers';
import { setSuppliersInfo } from '../../store/reducers/viewCTS';

import { objectToQuery } from '../../utils';

export function* handleGetSuppliersRequest (params) {
    try {
        const { filters, pagination, sort } = params.payload;
        const url = `${process.env.REACT_APP_API_AUTH_URL}/fetchSupplierCtsCode?start=${pagination.offset}&${objectToQuery({...filters, ...sort})}`;
        const response = yield call (GET, {url: url});
        const { message, totalCount, code } = response.data;
        yield put (setSuppliersList({message, totalCount, code}));
    } catch (err) {
        console.log(err);
    }
}

export function* handleGetSuppliersByCTSRequest (params) {
    try {
        const url = `${process.env.REACT_APP_API_AUTH_URL}/getSuppliersForCtsCode?ctsCode=${params.payload}`;
        const response = yield call (GET, {url: url});
        yield put (setSuppliersInfo(response.data.message));
    } catch (err) {
        console.log(err);
    }
}