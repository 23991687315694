import { GET, PUT } from '../requests/requests';
import { setTemplates, setDatewiseTemplates, updateAssignTemplateAPIResponse } from '../../store/reducers/assignTemplate';
import { put, call } from 'redux-saga/effects';

export function* handleGetTemplatesRequest () {
    try {
        const url = `${process.env.REACT_APP_API_AUTH_URL}/listTemplates`;
        const response = yield call (GET, {url: url});
        yield put (setTemplates(response.data.message));
    } catch (err) {
        console.log(err)
    }
}

export function* handleGetDatewiseTemplatesRequest () {
    try {
        const url = `${process.env.REACT_APP_API_AUTH_URL}/getTemplatesforDateRange`;
        const response = yield call (GET, {url: url});
        yield put (setDatewiseTemplates(response.data.message));
    } catch (err) {
        console.log(err)
    }
}

export function* handleAssignTemplatesRequest (params) {
    try {
        const data = params.payload;
        const url = `${process.env.REACT_APP_API_AUTH_URL}/assignTemplate`;
        const response = yield call (PUT, {url: url, data});
        yield put (updateAssignTemplateAPIResponse(response.data));
    } catch ({response}) {
        yield put (updateAssignTemplateAPIResponse(response.data));
    }
}