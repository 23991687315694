import SupplierDeliveryForm from './new';
import { useDispatch, useSelector } from 'react-redux';
import { getDeliveryFormInfo, setDeliveryFormInfo, getFormAPIResponse } from '../../store/reducers/editDeliveryForms';
import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

const SupplierEditDeliveryForm = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();


    useEffect(() => {
        dispatch(getDeliveryFormInfo(params.id));
       
        return () => {
            dispatch(setDeliveryFormInfo({}));
            
        }
    },[dispatch, params.id]);

    const handleResponse = (res) => {
        
         if(res.statusCode === '403' || res.statusCode === '500') {
            navigate('/forbidden');
        }
    }

    useEffect(() => {
        if(Object.keys(props.getFormAPIResponse).length) {
           
                dispatch(getFormAPIResponse({}));
             
            handleResponse(props.getFormAPIResponse);
            
        }
    })

    const { data } = useSelector((state) => state.editForm);

    return (
        data.objectId === params.id && data.status === "Draft" ? 
        <SupplierDeliveryForm mode="edit" key={data.objectId} data={data} snackbar={props.snackbar} /> : null
        
    )
}

const mapStateToProps = (state) => ({
    getFormAPIResponse: state.editForm.getFormAPIResponse
});

export default connect(mapStateToProps)(SupplierEditDeliveryForm);