import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import axios from 'axios';
import {useLocation} from 'react-router-dom';
import '../../App.css'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
    overflow:'scroll',
    height: '400px',
    p: 4,
   
};

export default function BasicModal() {
  const [open, setOpen] = useState(true);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  
  const [tableData, setTableData] = useState();
  const location = useLocation();
  
  const roleid = `${location.state.roleid}`;
  const supplier_id = process.env.REACT_APP_SUPPLIER_ID;
  const security_id = process.env.REACT_APP_SECURITY_GUARD_ID;
  const driver_id = process.env.REACT_APP_DRIVER_ID;

      const handleSubmit = (e)=>{
        e.preventDefault();
       
        const headers = {
      'Authorization': "Bearer " + localStorage.getItem("access_token")
       };
      
        axios({
          method: 'post',
          url: process.env.REACT_APP_API_AUTH_URL + `/users/00u16awqwdeurS5Id0h8/factors/${location.state.userId}/activate`, 
      
          data: {
            passCode:tableData,
          }, headers: headers
        })
      
      .then((response) =>{
        if (roleid === supplier_id) {

          axios({
            method: 'PUT',
            url: process.env.REACT_APP_API_AUTH_URL + '/getCTSCodeForSupplier',
            data: {
            }, headers: headers
          })
            .then((response) => { 
              if (response.data.message[0] === null) {
               navigate("/supplier-cts-link");
            } else {
              navigate("/supplier-home");
            }
          })
        }
        else if (roleid === driver_id) {
          navigate("/driver-home");
        }
        else if (roleid === security_id) {
          navigate("/security-home");
        }
        else {
          navigate("/home");
        }
       })
      
      .catch((error) => {
          console.log('error ' + error);
      });
      }
     



  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow:'scroll', backgroundColor:'#279989' }}
      >
        <Box sx={style} component="form" onSubmit={handleSubmit}>
      
           <Typography variant="h6" gutterBottom>
                                Verify Phone Number
                            </Typography>
         <br/>
                  <Grid container spacing={3}>  
           <Grid item xs={12} sm={12}>
                                <FormControl sx={{ m: 1, minWidth: 120,width: '100%' }}>

                                <TextField
                                    id="phonenumberotp"
                                    name="phonenumberotp"
                                    label="Enter OTP"
                                    fullWidth
                                    autoComplete="phone-number -otp"
                                    variant="standard"
                                    onChange={(e)=>setTableData(e.target.value)}
                                    required
                                                                      
                                />
                                </FormControl>

                        </Grid>
                        <Grid item xs={12} sm={12}>

                            <FormControl sx={{ m: 1 }}>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    
                                    >
                                    Verify
                                </Button>
                                </FormControl>
                               
                            </Grid>
                                                   </Grid>
           </Box>
      </Modal>
    </div>
  );
}
