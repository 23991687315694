import { Grid, Button, Tooltip, Box, IconButton, FormControlLabel, Switch, FormControl, TextField } from '@mui/material';

// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import EditRoundedIcon from '@mui/icons-material/EditRounded';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';

import Pagination from '@mui/material/Pagination';
// import {  Link } from 'react-router-dom';

import {
    DataGrid
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getSuppliersList, setPageNumber, setSort, setFilter, clearFilter } from '../../store/reducers/listSuppliers';


const ListSuppliers = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const suppliers = useSelector((state) => state.listSupplier);
    const [sortModel, setSortModel] = useState([{ field: 'supplier', sort: 'asc' }]);

    const handleSortModelChange = (newModel) => {
        setSortModel(newModel);
        dispatch(setSort({sortOrder: newModel[0].sort, sortBy: newModel[0].field}));
        dispatch(getSuppliersList());
    };

    useEffect(() => {
        dispatch(getSuppliersList());
    }, [dispatch]);

    const cellTooltip = (value) => {
        return (
            value && value !== '' ?
                <Tooltip title={value} >
                    <span className="MuiDataGrid-cellContent">{value}</span>
                </Tooltip> :
                <span className="MuiDataGrid-cellContent">-</span>
        )
    }

    const onPageChange = (data, value) => {
        dispatch(setPageNumber(value));
        dispatch(getSuppliersList());
    }

    const columns = [
        {
            field: 'supplier', headerName: 'Supplier Email', flex: 2,
            renderCell: (node) => cellTooltip(node.value)
        },
        {
            field: 'supplierName', headerName: 'Supplier Name', flex: 2,
            renderCell: (node) => cellTooltip(node.value)
        },
        { field: 'ctsCode', headerName: 'CTS Code ID', flex: 2, renderCell: (node) => cellTooltip(node.value) },
        {
            field: 'ctsCodeName', headerName: 'CTS Code Name', flex: 2,
            renderCell: (node) => cellTooltip(node.value)
        },
        {
            field: "Actions",
            flex: 1,
            headerAlign: 'center',
            sortable: false,
            renderCell: (node) => {
                const orgId = node.row.ctsCode;
                return (
                    <div>
                        <IconButton disabled={orgId === undefined}>
                            <Tooltip title="VIEW">
                                <VisibilityRoundedIcon onClick={(e) => {
                                    e.stopPropagation();
                                    navigate(`/admin-suppliers-mapping/view/${orgId}`);

                                }
                                } color={orgId === undefined ? 'disabled' : 'primary'} />
                            </Tooltip>
                        </IconButton>
                        {/* <IconButton disabled={orgId === null}>
                            <Tooltip title="EDIT">
                                <EditRoundedIcon onClick={(e) => {
                                    e.stopPropagation();
                                }
                                } color={orgId === null ? 'disabled' : 'primary'} />
                            </Tooltip>
                        </IconButton> */}
                        <IconButton>
                            <Tooltip title="Map CTS">
                                <InsertLinkIcon onClick={(e) => {
                                    e.stopPropagation();
                                    navigate(`/admin-suppliers-mapping/link/${node.row.supplier}/${node.row.ctsCodeName ? node.row.ctsCodeName : '-'}/${node.row.ctsCode ? node.row.ctsCode : '-'}`);
                                }
                                } color="primary" />
                            </Tooltip>
                        </IconButton>
                    </div>
                );
            }
        }
    ];

    const CustomPagination = (pagination) => {
        return (
          <Pagination 
          color="primary"
          count={pagination.pageCount}
          page={pagination.currentPage}
          onChange={onPageChange}
        />
        )
    }

    const onMappedFilterChange = (e) => {
        dispatch(setFilter({...suppliers.filters, ctsCode: "", ctsCodeName: "", unmapped: `${e.target.checked}` }));
        dispatch(getSuppliersList());
    }
    
    const search = () => {
        dispatch(setPageNumber(1));
        dispatch(getSuppliersList());
    }

    const clearFilters = () => {
        dispatch(clearFilter());
        dispatch(getSuppliersList());
    }

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <FormControl sx={{ m: 1, minWidth: 120,width: '100%'}}>
                        <TextField
                            id="supplier-id-search"
                            label="Supplier Email"
                            value={suppliers.filters.supplier}
                            variant="standard"
                            onChange={(e)=>dispatch(setFilter({...suppliers.filters, supplier: e.target.value }))}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControl sx={{ m: 1, minWidth: 120,width: '100%'}}>
                        <TextField
                            id="supplier-name-search"
                            label="Supplier Name"
                            value={suppliers.filters.supplierName}
                            variant="standard"
                            onChange={(e)=>dispatch(setFilter({...suppliers.filters, supplierName: e.target.value }))}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControl sx={{ m: 1, minWidth: 120,width: '100%'}}>
                        <TextField
                            id="cts-code-id-search"
                            label="CTS Code ID"
                            value={suppliers.filters.ctsCode}
                            variant="standard"
                            disabled={suppliers.filters.unmapped === 'true'}
                            onChange={(e)=>dispatch(setFilter({...suppliers.filters, ctsCode: e.target.value }))}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControl sx={{ m: 1, minWidth: 120,width: '100%'}}>
                        <TextField
                            id="cts-code-name-search"
                            label="CTS Code Name"
                            value={suppliers.filters.ctsCodeName}
                            variant="standard"
                            disabled={suppliers.filters.unmapped === 'true'}
                            onChange={(e)=>dispatch(setFilter({...suppliers.filters, ctsCodeName: e.target.value }))}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControlLabel sx={{float: 'left'}} control={
                        <Switch checked={suppliers.filters.unmapped === 'true'} onChange={onMappedFilterChange} />
                            } label='Unmapped Suppliers' />
                </Grid>
                <Grid item sx={{float: 'right'}} xs={6}>
                    <Button
                        type="button"
                        variant="outlined"
                        sx={{  mb: 2 }}
                        style={{marginRight:'10px', float: 'right'}}
                        onClick={clearFilters}>
                        Clear
                    </Button>
                    <Button
                        type="button"
                        variant="contained"
                        sx={{  mb: 2 }}
                        style={{marginRight:'10px', float: 'right'}}
                        onClick={search}>
                        Search
                    </Button>
                </Grid>
                
                <Grid item xs={12}>
                    <Box sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                            pagination
                            // rowsPerPageOptions={[5]}
                            getRowId={(row) => `${row.supplier}${new Date().getTime()}`}
                            disableColumnMenu={true}
                            isRowSelectable={() => false}
                            pageSize={suppliers.pagination.pageSize}
                            components={{
                                Pagination: () => {return CustomPagination(suppliers.pagination)},
                            }}
                            columns={columns}
                            rows={suppliers.list}
                            sortingMode="server"
                            sortModel={sortModel}
                            onSortModelChange={handleSortModelChange}
                        />
                    </Box>
                </Grid>

            </Grid>
        </div>
    )
}



export default ListSuppliers;