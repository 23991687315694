import { useEffect } from 'react';
import { Grid, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getDeliveryForms } from '../../../store/reducers/deliveryForms';

import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';

import Tooltip from '@mui/material/Tooltip';
import ReportList from '../../shared/deliveryForms/reportList';
import { useNavigate } from 'react-router-dom';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import FormatDate from '../../shared/FormatDate';
import FormatDateTime from '../../shared/FormatDateTime';

const AdminDeliveryForms = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getDeliveryForms());
    }, [dispatch]);

    const deliveryForms = useSelector((state) => state.reportFilter);

    const cellTooltip = (value) => {
        return (
            value && value !== '' ?
                <Tooltip title={value} >
                    <span className="MuiDataGrid-cellContent">{value}</span>
                </Tooltip> :
                <span style={{ margin: 'auto' }} className="MuiDataGrid-cellContent">-</span>
        )
    }

    const columns = [
        { field: 'deliveryOrderNumber', headerAlign: 'center', headerName: 'Delivery Order No', width: 180, renderCell: (node) => cellTooltip(node.value) },
        { field: 'supplier', headerAlign: 'center', headerName: 'Supplier', width: 120, renderCell: (node) => cellTooltip(node.value) },
        { field: 'truckNumber', headerAlign: 'center', headerName: 'Truck/Vehicle Plate Number', width: 230, renderCell: (node) => cellTooltip(node.value) },
        { field: 'cargoType', headerAlign: 'center', headerName: 'Cargo Type', width: 120, renderCell: (node) => cellTooltip(node.value) },
        { field: 'remarks', headerAlign: 'center', headerName: 'Remarks', width: 120, renderCell: (node) => cellTooltip(node.value) },
        { field: 'deliveryDate', headerAlign: 'center', headerName: 'Delivery Date', width: 140, renderCell: (node) => {
            return(
                <FormatDate value={node.value} />
            )
        } },
        {
            field: 'slotStartTime',
            headerAlign: 'center',
            headerName: 'Time Slot',
            width: 140,
            sortable: false,
            renderCell: (node) => {
                const slotStart = node.row.slotStartTime ? node.row.slotStartTime.split(' ')[1] : '';
                const slotEnd = node.row.slotEndTime ? node.row.slotEndTime.split(' ')[1] : '';
                const value = `${slotStart.slice(0, -3)}-${slotEnd.slice(0, -3)}`;
                return (
                    <Tooltip title={value} >
                        <span className="MuiDataGrid-cellContent">{value}</span>
                    </Tooltip>
                )
            }
        },
        { field: 'status', headerAlign: 'center', headerName: 'Status', width: 120, renderCell: (node) => cellTooltip(node.value) },
        { field: 'lastStatusChange',  sortable: false, headerAlign: 'center', headerName: 'Last Updated', width: 160, renderCell: (node) => {
            return(
                <FormatDateTime value={node.value} />
            )
        } },
        {
            field: 'origSlotStartTime',  sortable: false, headerAlign: 'center', headerName: 'Original Delivery Date', width: 190,
            renderCell: (node) => {
                const originalDate = node.row.origSlotStartTime && node.row.origSlotStartTime.length ? node.row.origSlotStartTime.split(' ')[0] : null;
                if(node.row.origSlotEndTime !== node.row.slotEndTime && node.row.origSlotStartTime !== node.row.slotStartTime) {
                    return (
                        <Tooltip title={originalDate} >                            
                            <FormatDate value={originalDate} />
                        </Tooltip>
                        
                    )
                } else {
                    return <span className="MuiDataGrid-cellContent">-</span>
                }
            }
        },
        {
            field: 'origSlotEndTime',
            headerAlign: 'center',
            headerName: 'Original Time Slot',
            width: 140,
            sortable: false,
            renderCell: (node) => {
                
                if(node.row.origSlotEndTime !== node.row.slotEndTime && node.row.origSlotStartTime !== node.row.slotStartTime) {
                    const slotStart = node.row.origSlotStartTime ? node.row.origSlotStartTime.split(' ')[1] : '';
                    const slotEnd = node.row.origSlotEndTime ? node.row.origSlotEndTime.split(' ')[1] : '';
                    const value = `${slotStart.slice(0, -3)}-${slotEnd.slice(0, -3)}`;
                    return (
                        <Tooltip title={value} >
                            <span className="MuiDataGrid-cellContent">{value}</span>
                        </Tooltip>
                    )
                } else {
                    return <span className="MuiDataGrid-cellContent">-</span>
                }
            }
        },
        {
            field: 'cancellationReason',
            headerName: 'Cancellation Reason',
            headerAlign: 'center',
            align: 'center',
            width: 200,
            renderCell: (node) => cellTooltip(node.value)
        },
        {
            field: "Actions",
            width: 150,
            headerAlign: 'center',
            sortable: false,
            renderCell: (node) => {
                const status = node.row.status;
                return (
                    <div >
                        <IconButton aria-label="View">
                            <Tooltip title="VIEW">
                                <VisibilityRoundedIcon onClick={() => navigate('/admin-view-delivery/' + node.id)} color='primary' />
                            </Tooltip>
                        </IconButton>
                        <IconButton disabled={status === 'Cancelled' || status === 'Truck-Out'} aria-label="Edit">
                            <Tooltip title="EDIT">
                                <EditRoundedIcon onClick={() => {
                                    navigate('/admin-edit-delivery/' + node.id)
                                }
                                } color={status !== 'Cancelled' && status !== 'Truck-Out' ? 'primary' : 'disabled'} />
                            </Tooltip>
                        </IconButton>
                    </div>
                );
            }
        }
    ];

    const onSearch = () => {
        dispatch(getDeliveryForms());
    }

    return (
        <Grid style={{paddingTop: '100px'}} >
            <ReportList role="admin" columns={columns} deliveryForms={deliveryForms} snackbar={props.snackbar}
                search={onSearch}
            />

        </Grid>
    )
}

export default AdminDeliveryForms;