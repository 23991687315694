import Box from '@mui/material/Box';

import {Grid, Button} from '@mui/material';
import TextField from "@mui/material/TextField";
import FormControl from '@mui/material/FormControl';
import { useState, useEffect  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setReportFilter, clearReportFilter } from '../../../store/reducers/reportFilter';
import { getFormStatuses } from '../../../store/reducers/utils';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { formatDate } from '../../../utils';
import axios from 'axios';
import FileSaver from 'file-saver'
import { objectToQuery } from '../../../utils';

const ReportList = (props) => {

    const dispatch = useDispatch();
    const [minDate, setMinDate] = useState();
    const [maxDate, setMaxDate] = useState();

    // const deliveryForms = useSelector((state) => state.deliveryForms);
    const deliveryForms = props.deliveryForms;
    const utils = useSelector((state) => state.utils);
    const list = utils.statusList

    useEffect(() => {
        // var date = new Date() 
        // var tommorowDate = date.setDate(date.getDate() + 1)
        dispatch(getFormStatuses());
        // dispatch(setReportFilter({...deliveryForms.filters,status: 'Truck-Out', slotDateFrom: formatDate(new Date(), 'yyyy-mm-dd'), slotDateTo: formatDate(tommorowDate, 'yyyy-mm-dd') }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch]);

    const clearReportFilters = () => {
        setMinDate(null);
        setMaxDate(null);
        dispatch(clearReportFilter())
        props.search();
    }

    const generateReport = () => {
        if(!deliveryForms.filters.slotDateFrom && !deliveryForms.filters.slotDateTo && deliveryForms.filters.status === "") {
            return props.snackbar('Please select any filter', 'error', 3000);
        }

        if(!deliveryForms.filters.slotDateFrom && deliveryForms.filters.slotDateTo) {
            return props.snackbar('Please select start date!', 'error', 3000);
        }

        if(deliveryForms.filters.slotDateFrom && !deliveryForms.filters.slotDateTo) {
            return props.snackbar('Please select end date!', 'error', 3000);
        }

        var params = {
            startDate: deliveryForms.filters.slotDateFrom,
            endDate: deliveryForms.filters.slotDateTo,
            status: deliveryForms.filters.status
        }
        const headers = {
            'Authorization': "Bearer "+localStorage.getItem("access_token")
          };
          axios.get(process.env.REACT_APP_API_AUTH_URL+`/fetchAllDeliveriesReport2?${objectToQuery(params)}`, { headers: headers,responseType: 'arraybuffer' })
          .then(response => {

            var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, `Report_2_${formatDate(new Date(), 'mm-dd-yyyy-hh-mm-ss')}.xlsx`);

          })
          .catch((error) => {
              if (error.response.status === 404) {
                props.snackbar('No deliveries available currently for criteria selected', 'error', 3000);
              }
          });        
    }

    const onStartDateChange = (value) => {
        setMinDate(value);
        dispatch(setReportFilter({...deliveryForms.filters, slotDateFrom: formatDate(value, 'yyyy-mm-dd') }));
    }

    const onEndDateChange = (value) => {
        setMaxDate(value);
        dispatch(setReportFilter({...deliveryForms.filters, slotDateTo: formatDate(value, 'yyyy-mm-dd') }));
    }

    return (
        <div>
            <Box style={{paddingTop:'10px'}}>

                        <Grid container spacing={3}>         
                            <Grid item xs={12} sm={4}>
                                <FormControl variant="standard"  sx={{ m: 1, minWidth: 120,width: '100%' }}>
                                    <InputLabel id="status-label">Status</InputLabel>
                                    <Select
                                        labelId="status"
                                        id="status"            
                                        label="Status"
                                        value={deliveryForms.filters.status}
                                        onChange={(e)=>dispatch(setReportFilter({...deliveryForms.filters, status: e.target.value }))}>
                                        <MenuItem value={'All'} >All</MenuItem>

                                        {props.role === 'admin' ?
                                        /** Hide Draft for Admin */
                                        list.filter(({id, name}) => name !== 'Draft' && name !== 'Cancelled' && name !== 'Submitted').map(({ id, name }) =><MenuItem value={name} >{name}</MenuItem>)
                                        :
                                        utils.statusList.map(({ id, name }) =><MenuItem value={name} >{name}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        value={deliveryForms.filters.slotDateFrom} 
                                        label="Start Date"
                                        onChange={onStartDateChange}
                                        maxDate={maxDate}
                                        renderInput={(params) => <TextField  style={{paddingTop: '8px', width: '100%'}} variant="standard" {...params} />}
                                    />
                                </LocalizationProvider>
                                
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="End Date"
                                        value={deliveryForms.filters.slotDateTo} 
                                        onChange={onEndDateChange}
                                        minDate={minDate}
                                        renderInput={(params) => <TextField style={{paddingTop: '8px', width: '100%'}} variant="standard" {...params} />}
                                    />
                                </LocalizationProvider>
                                
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Button
                                    type="button"
                                    variant="outlined"
                                    sx={{  mb: 2 }}
                                    style={{float: 'right', marginRight:'10px'}}
                                    onClick={clearReportFilters}>
                                    Clear
                                </Button>
                                <Button
                                    type="button"
                                    variant="contained"
                                    sx={{  mb: 2 }}
                                    style={{float: 'right', marginRight:'10px'}}
                                    onClick={generateReport}>
                                    Generate Report
                                </Button>
                            </Grid>
                        </Grid>

            </Box>

        </div>
    )
}

export default ReportList;