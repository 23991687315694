import React, { useEffect } from 'react';
import { styled, alpha } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useOktaAuth } from '@okta/okta-react';
import okta from '../auth/okta';
import Notification from '../shared/Notification';
const drawerWidth = 240;
const axios = require('axios');

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


const RenderAvatar = () => {
  const userName = localStorage.getItem("user");

  const getInitials = (name) => {
    return name.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase();
  }

  return (
    userName && <Avatar>{getInitials(userName)}</Avatar>
  )
}

export default function NavBar({drawerOpen, openStatus}) {
  const [open] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [batchCount, setBatchCount] = React.useState(0);
  const roleId = localStorage.getItem('roleId');
  // const [notificationEnable, setNotificationEnable] = React.useState('none');
  // const showNotification = (roleId !== process.env.REACT_APP_SECURITY_GUARD_ID && roleId !== process.env.REACT_APP_DRIVER_ID && roleId !== process.env.REACT_APP_MONITOR_ID)
  const showNotification = (roleId === 'admin' || roleId === process.env.REACT_APP_SUPPLIER_ID);

  const interval = React.useRef(0);

  const getNotification = () => {
    const headers = {
        'Authorization': "Bearer "+localStorage.getItem("access_token")
      };
    axios.get(process.env.REACT_APP_API_AUTH_URL+'/fetchnotification', { headers: headers })
    .then(response => {

        var batchCount = 0;
        response.data.message.map(function (object) {
          if(object.notification_flag === "UNREAD"){
            batchCount = batchCount+1
          }
          return batchCount
        });
        setBatchCount(batchCount)

    })
    .catch((error) => {
        clearInterval(interval.current)
        console.log('error ' + error);
    });
  }
  
  useEffect(() => {
    
    // getUserDetails();
    if(showNotification) {
      getNotification()
      
      interval.current = setInterval(() => {
        getNotification()
      }, 10000);
      
    }
    return () => {
      clearInterval(interval.current)
    };
  },[showNotification]);

  const { oktaAuth } = useOktaAuth();
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const unReadCall = () => {
    const headers = {
        'Authorization': "Bearer "+localStorage.getItem("access_token")
      };

    axios({
      method: 'post',
      url: process.env.REACT_APP_API_AUTH_URL+'/updatenotifyflag',
      data: {
      },headers: headers
    })
    .then(response => {
      setBatchCount(0)
    });

  } 
  

  const handleCloseUserMenu = (event) => {
    oktaAuth.signOut();
    okta.getAccountClosed();
    localStorage.clear();    
  };


  const [anchorEl, setAnchorEl] = React.useState(null);
  const notificationOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    unReadCall()
  };


  // const getUserDetails = () => {
  //   const headers = {
  //     'Authorization': "Bearer "+localStorage.getItem("access_token")
  //   };
  // axios({
  //     method: 'post',
  //     url: process.env.REACT_APP_API_AUTH_URL+'/getGroupsByUserId',
  //     data: {         
  //     },headers: headers
  //   })
  //   .then((response) => {
  //     var value = (response.data.message[0].roleid === process.env.REACT_APP_SECURITY_GUARD_ID) || (response.data.message[0].roleid === process.env.REACT_APP_DRIVER_ID) || (response.data.message[0].roleid === process.env.REACT_APP_MONITOR_ID)
  //     setNotificationEnable(value ? 'none' : 'flex')  
  //   })
  // }


  const handleClose = () => {
    setAnchorEl(null);
  };


  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props} />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      left: '350px',
      width: '500px',
      minWidth: 180,
      overflowY: 'hidden',
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));
  

  return (
    <AppBar position="fixed" open={openStatus}>
        <Toolbar>
        <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={drawerOpen}
            edge="start"
            sx={{
            marginRight: '36px',
            ...(open && { display: 'none' }),
            }}
        >
            <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div" flex= '1'>
           Cargill Operations Planning Resource Application
        </Typography>
        <MenuItem style={{display: showNotification ? 'flex' : 'none'}}>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
          id="basic-button"
          // aria-controls={notificationOpen ? 'basic-menu' : undefined}
          aria-haspopup="true"
          // aria-expanded={notificationOpen ? 'true' : undefined}
          onClick={handleClick}
        >
          <Badge badgeContent={batchCount} color="error">
            <NotificationsIcon/>
          </Badge>
        </IconButton>
        </MenuItem>
           <div>
            <StyledMenu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={notificationOpen}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
              >
                            <Notification/>
            </StyledMenu>
          </div>
        <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <RenderAvatar />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={() => {setAnchorElUser(null)}}
            >
                <MenuItem key='signout' onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">Signout</Typography>
                </MenuItem>
            </Menu>
          </Box>        
        </Toolbar>
    </AppBar>
  );
}
