
const initialState = {
    ctsDetails: {
        ctsCode: "",
        ctsCodeName: "",
        province: "",
        city: "",
        status: "Active",
    },
    formErr: {
        ctsCode: false,
        ctsCodeName: false,
        province: false,
        city: false
    },
    saveAPIResponse: {},
    updateAPIResponse: {},
    showWarnMsg: false
}

export const SET_ORG_FIELDS = 'SET_ORG_FIELDS';
export const SAVE_ORG = 'SAVE_ORG';
export const SET_ORG_API_RESPONSE = 'SET_ORG_API_RESPONSE';
export const CLEAR_ORG = 'CLEAR_ORG';
export const SET_ORG_FORM_ERR = 'SET_FORM_ERR';
export const GET_CTS_INFO_FOR_EDIT = 'GET_CTS_INFO_FOR_EDIT';
export const SET_CTS_INFO_FOR_EDIT = 'SET_CTSET_CTS_INFO_FOR_EDITS_INFO';
export const UPDATE_CTS = 'UPDATE_CTS';
export const UPDATE_CTS_API_RESPONSE = 'UPDATE_CTS_API_RESPONSE';
export const TOGGLE_INACTIVE_CTS_WARN_MSG = 'TOGGLE_INACTIVE_CTS_WARN_MSG';

export const setOrgFields = (data) => {
    return {
        type: SET_ORG_FIELDS,
        payload: data
    }
}

export const saveOrg = (data) => {
    return {
        type: SAVE_ORG,
        payload: data
    }
}

export const updateOrg = (data) => {
    return {
        type: UPDATE_CTS,
        payload: data
    }
}

export const updateOrgAPIResponse = (data) => {
    return {
        type: UPDATE_CTS_API_RESPONSE,
        payload: data
    }
}

export const setOrgAPIResponse = (data) => {
    return {
        type: SET_ORG_API_RESPONSE,
        payload: data
    }
}

export const setOrgFormErr = (data) => {
    return {
        type: SET_ORG_FORM_ERR,
        payload: data
    }
}

export const clearOrg = () => {
    return {
        type: CLEAR_ORG
    }
}

export const getCTSInfo = (ctsCode) => {
    return {
        type: GET_CTS_INFO_FOR_EDIT,
        payload: ctsCode
    }
}

export const setCTSInfo = (data) => {
    return {
        type: SET_CTS_INFO_FOR_EDIT,
        payload: data
    }
}

export const toggleWarnMsg = (flag) => {
    return {
        type: TOGGLE_INACTIVE_CTS_WARN_MSG,
        payload: flag
    }
}


export const addOrgReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_ORG_FIELDS: {
            return {
                ...state,
                ctsDetails: action.payload
            }
        }
        case SET_ORG_API_RESPONSE: {
            return {
                ...state,
                saveAPIResponse: action.payload
            }
        }
        case UPDATE_CTS_API_RESPONSE: {
            return {
                ...state,
                updateAPIResponse: action.payload
            }
        }
        case SET_ORG_FORM_ERR: {
            return {
                ...state,
                formErr: action.payload
            }
        }
        case CLEAR_ORG: {
            return {
                ...state,
                ctsDetails: initialState.ctsDetails,
                formErr: initialState.formErr,
                saveAPIResponse: initialState.saveAPIResponse
            }
        }
        case SET_CTS_INFO_FOR_EDIT: {
            return {
                ...state,
                ctsDetails: {
                    ctsCode: action.payload.ctsCode,
                    ctsCodeName: action.payload.ctsCodeName,
                    province: action.payload.provinceId,
                    city: action.payload.cityId,
                    status: action.payload.status === 'Active',
                    provinceName: action.payload.province,
                    cityName: action.payload.city
                }
            }
        }
        case TOGGLE_INACTIVE_CTS_WARN_MSG: {
            return {
                ...state,
                showWarnMsg: action.payload
            }
        }
        default:
            return state
    }
}