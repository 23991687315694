import { GET, POST } from '../requests/requests';
import { setBlockedUnblockedDates, updateBlockedDatesAPIResponse } from '../../store/reducers/blockDates';
import { put, call } from 'redux-saga/effects';

export function* handleGetBlockedDatesRequest () {
    try {
        const url = `${process.env.REACT_APP_API_AUTH_URL}/getAllDaysStatus`;
        const response = yield call (GET, {url: url});
        console.log(response);
        yield put (setBlockedUnblockedDates(response.data.message));
    } catch (err) {
        console.log(err)
    }
}

export function* handleUpdateBlockedDatesRequest (params) {
    try {
        const data = params.payload;
        const url = `${process.env.REACT_APP_API_AUTH_URL}/blockDates`;
        const response = yield call (POST, {url: url, data});
        console.log(response);
        yield put (updateBlockedDatesAPIResponse(response.data));
    } catch ({response}) {
        yield put (updateBlockedDatesAPIResponse(response.data));
    }
}