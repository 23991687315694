const initialState = {
    formDetails: {},
    errObj: {},
    updateStatusAPIResponse: {},
    isStatusLoading: false
}

export const GET_FORM_DETAILS_FOR_SCANNER = 'GET_FORM_DETAILS_FOR_SCANNER';
export const SET_FORM_DETAILS_FOR_SCANNER = 'SET_FORM_DETAILS_FOR_SCANNER';
export const SET_FORM_DETAILS_ERR = 'SET_FORM_DETAILS_ERR';
export const SCANNER_UPDATE_FORM_STATUS = 'SCANNER_UPDATE_FORM_STATUS';
export const SCANNER_UPDATE_FORM_STATUS_API_RES = 'SCANNER_UPDATE_FORM_STATUS_API_RES';
export const SET_STATUS_LOADING = 'SET_STATUS_LOADING';

export const getDeliveryFormInfo = (formId) => {
    return {
        type: GET_FORM_DETAILS_FOR_SCANNER,
        payload: formId
    }
}

export const setDeliveryFormInfo = (data) => {
    return {
        type: SET_FORM_DETAILS_FOR_SCANNER,
        payload: data
    }
}

export const setDeliveryFormInfoErr = (data) => {
    return {
        type: SET_FORM_DETAILS_ERR,
        payload: data
    }
}

export const updateDeliveryFormStatus = (data) => {
    return {
        type: SCANNER_UPDATE_FORM_STATUS,
        payload: data
    }
}

export const updateDeliveryFormStatusAPIResponse = (data) => {
    return {
        type: SCANNER_UPDATE_FORM_STATUS_API_RES,
        payload: data
    }
}



export const setFormStatusLoading = (data) => {
    return {
        type: SET_STATUS_LOADING,
        payload: data
    }
}

export const scannerViewDeliveryReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_FORM_DETAILS_FOR_SCANNER: {
            return {
                ...state,
                formDetails: action.payload
            }
        }
        case SET_FORM_DETAILS_ERR: {
            return {
                ...state,
                errObj: action.payload
            }
        }
        case SET_STATUS_LOADING: {
            return {
                ...state,
                isStatusLoading: action.payload
            }
        }
        case SCANNER_UPDATE_FORM_STATUS_API_RES: {
            return {
                ...state,
                updateStatusAPIResponse: action.payload
            }
        }
        default:
            return state;
    }
}