import React from 'react';
import { Provider } from 'react-redux';
import store from './store';
import './App.css';
import { Routes, Route } from "react-router-dom";
import Register from './components/auth/Register';
import Home from './components/home';
import RegisterSuccess from './components/auth/RegisterSuccess';
import PageNotFound from './components/auth/PageNotFound';

import Login from './components/auth/Login';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import LoginCallback from './components/auth/LoginCallBack';
import PrivateRoute from './components/auth/PrivateRoute'
import SupplierHome from './components/home/supplier';
import SupplierNewDelivery from './components/delivery/new'
import SupplierViewDetails from './components/delivery/view';
import SecurityViewDetails from './components/delivery/security/view';
import AdminDeliveryForms from './components/home/admin/AdminDeliveryForms';
import AdminReportList from './components/home/admin/AdminReportList';

import DriverDeliveryForms from './components/home/driver/DriverDeliveryForms';

import DriverViewDetails from './components/delivery/driver/view';
import SecurityHome from './components/home/security';
import SecurityScan from './components/scan/security';
import SupplierEditDeliveryForm from './components/delivery/edit';
import Forbidden from './components/auth/Forbidden';
import PhoneNumberPopup from './components/shared/PhoneNumberPopup';
import PhonenoOTP from './components/shared/PhonenoOTP';
import WelcomLogin from './components/auth/WelcomLogin';


import AdminEditDeliveryForm from './components/delivery/admin/editDeliveryForm';
import AdminViewDeliveryForm from './components/delivery/admin/viewDeliveryForm';
import { withSnackbar } from './components/shared/SnackBar';

import TemplateManagement from './components/templates/template';
import NewTemplate from './components/templates/newTemplate';
import EditTemplate from './components/templates/editTemplate';
import BulkEdit from './components/delivery/admin/bulkEdit/bulkEdit';

import ViewTemplate from './components/templates/viewTemplate';


import AssignTemplate from './components/templates/assignTemplate';
import ManageTemplates from './components/templates/manageTemplates';
import AdminSettings from './components/settings/adminSettings';
import BlockDates from './components/block/blockDates/blockDates';
import BlockSlots from './components/block/blockSlots/blockSlots';
import MonitorTrucks from './components/monitor/truckList';
import SuppliersManagement from './components/suppliers/suppliers';
import ListSuppliers from './components/suppliers/listSuppliers';
import AddCTS from './components/cts/addCTS';
import LinkCTS from './components/suppliers/linkCTS';
import ViewCTS from './components/cts/viewCTS';
import CTSManagement from './components/cts/cts';
import ListCTS from './components/cts/listCTS';
import SupplierCTSLink from './components/shared/supplierCTSLink';
import ScannerHome from './components/home/scanner-staff';
import ScannerViewDelivery from './components/home/scanner-staff/scannerViewDelivery';



const windowLocation = encodeURI(window.location.origin);
const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_OKTA_BASEURL,
  clientId: process.env.REACT_APP_CLIENT_ID,
  redirectUri: decodeURI(windowLocation) + process.env.REACT_APP_REDIRECT_URI
});


class App extends React.Component {

  constructor(props) {
    super(props);
    this.restoreOriginalUri = async (_oktaAuth, originalUri) => {
      var url = encodeURI(originalUri)
      props.history.replace(toRelativeUrl(decodeURI(url) || '/', decodeURI(windowLocation)));
    };
    this.attachCookieConsent = this.attachCookieConsent.bind(this);
    this.showCookieConsentAlert = this.showCookieConsentAlert.bind(this);
    this.timeoutID = null;
  }

  showCookieConsentAlert() {
    return setTimeout(() => {
      const ccElem = document.getElementById('truste-consent-track');
      if(ccElem) {
        ccElem.style.visibility = 'visible';
      }
    }, 5000)
  }

  attachCookieConsent() {
      this.timeoutID = this.showCookieConsentAlert();
  }

  componentDidMount() {
    this.attachCookieConsent();
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutID);
  }

  render() {
    return (    
      <div>
        <Provider store={store}>
          <Security oktaAuth={oktaAuth} restoreOriginalUri={this.restoreOriginalUri}>
            <Routes>
              <Route path="register" element={<Register />} />
              <Route path="register-success" element={<RegisterSuccess />} />
              <Route path="page-not-found" element={<PageNotFound />} />
              <Route path="login" element={<Login />} />
              <Route path="login/callback" element={<LoginCallback />} />
              <Route path="forbidden" element={<Forbidden />} />
              <Route path="welcom-login" element={<WelcomLogin />}/>

              <Route exact path='/' element={<PrivateRoute/>}>
                <Route path="home" element={<Home />} />
                <Route path="/" element={<Home />}>
                  <Route path="supplier-home" element={<SupplierHome />} />
                  <Route path="security-home" element={<SecurityHome />} />
                  <Route path="driver-home" element={<DriverDeliveryForms />} />
                  <Route path="supplier-new-delivery" element={<SupplierNewDelivery snackbar={this.props.snackbar}/>} />
                  <Route path="supplier-view-delivery/:id" element={<SupplierViewDetails snackbar={this.props.snackbar} />} />
                  <Route path="security-view-delivery/:id" element={<SecurityViewDetails  snackbar={this.props.snackbar} />} />
                  <Route path="supplier-edit-delivery/:id" element={<SupplierEditDeliveryForm snackbar={this.props.snackbar} />} />
                  <Route path="driver-view-delivery/:id" element={<DriverViewDetails />} />
                  <Route path="admin-view-delivery" element={<AdminDeliveryForms  snackbar={this.props.snackbar}/>} />
                  <Route path="admin-report-list" element={<AdminReportList  snackbar={this.props.snackbar}/>} />

                  <Route path="admin-edit-delivery/:id" element={<AdminEditDeliveryForm snackbar={this.props.snackbar}/>} />
                  <Route path="admin-view-delivery/:id" element={<AdminViewDeliveryForm snackbar={this.props.snackbar}/>} />
                  <Route path='admin-batch-edit' element={<BulkEdit snackbar={this.props.snackbar} />} />
                  <Route path="security-scan" element={<SecurityScan />} />
                  <Route path="shared-phonenumber-update" element={<PhoneNumberPopup />} />
                  <Route path="shared-phonenumber-verify" element={<PhonenoOTP />} />
                  <Route path="supplier-cts-link" element={<SupplierCTSLink />} />
                  <Route path="monitor-trucks" element={<MonitorTrucks  snackbar={this.props.snackbar}/>} />
                  <Route path="scanner-home" element={<ScannerHome snackbar={this.props.snackbar}/>} />
                  <Route path="scanner-view-delivery/:id" element={<ScannerViewDelivery snackbar={this.props.snackbar}/>} />


                  <Route path='admin-template-management' element={<TemplateManagement />}>
                    <Route path='' element={<ManageTemplates />} />
                    <Route path='new' element={<NewTemplate snackbar={this.props.snackbar} />} />
                    <Route path='edit/:id' element={<EditTemplate snackbar={this.props.snackbar} />} />
                    <Route path='view/:id' element={<ViewTemplate snackbar={this.props.snackbar} />} />
                    <Route path='assign' element={<AssignTemplate snackbar={this.props.snackbar} />} />

                  </Route>
                  <Route path="admin-settings" element={<AdminSettings  snackbar={this.props.snackbar}/>} />
                  <Route path="admin-block-dates" element={<BlockDates  snackbar={this.props.snackbar}/>} />
                  <Route path="admin-block-slots" element={<BlockSlots  snackbar={this.props.snackbar}/>} />
                  <Route path="admin-suppliers-mapping" element={<SuppliersManagement  snackbar={this.props.snackbar}/>}>
                    <Route path='' element={<ListSuppliers />} />
                    <Route path='link/:id/:cts/:old' element={<LinkCTS snackbar={this.props.snackbar} />} />
                    <Route path='view/:cts' element={<ViewCTS snackbar={this.props.snackbar} />} />

                  </Route>
                  <Route path="admin-cts" element={<CTSManagement  snackbar={this.props.snackbar}/>}>
                    <Route path='add' element={<AddCTS snackbar={this.props.snackbar} />} />
                    <Route path='edit/:id' element={<AddCTS snackbar={this.props.snackbar} />} />
                    <Route path='view/:cts' element={<ViewCTS snackbar={this.props.snackbar} />} />
                    <Route path='' element={<ListCTS />} />
                  </Route>
                </Route>
                </Route>
            </Routes> 
          </Security>
        </Provider>
      </div>
    )
  }
}
export default withSnackbar(App);
