import PrintIcon from '@mui/icons-material/Print';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { getProvinces, getCargoTypes, getRemarks, getCancellationReasons  } from '../../../store/reducers/utils';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { formatDate } from "../../../utils";


const PrintDeliveryForms = (props) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const cityMap = {};

    useEffect(() => {
        dispatch(getProvinces());
        dispatch(getCargoTypes());
        dispatch(getRemarks());
        dispatch(getCancellationReasons());
    }, [dispatch]);

    const { provinces, cargoTypes, remarks } = useSelector((state) => state.utils);

    // useEffect(() => {
    //     if(Object.keys(props.getFormAPIResponse).length) {
    //         dispatch(getFormAPIResponse({}));
    //         if(props.getFormAPIResponse.statusCode === '200') {
    //             generateData(props.getFormAPIResponse.message);
    //         } else {
    //             props.snackbar('Something went wrong! Try again!', 'error');
    //         }
    //     }
    // })

    const generateData = (forms) => {
        // forms.forEach((form, index) => {
        //     getCity(form.provinceId, form.cityId, forms.length, index, forms);
        // });
        if(forms.length) {
            getCity(forms[0].provinceId, forms[0].cityId, 0, forms);
        }
    }


    const getCity = (provinceId, cityId, formIndex, forms) => {

        if(cityMap[cityId] === undefined) {
            const headers = {
                'Authorization': "Bearer "+localStorage.getItem("access_token")
            };
            const params = { provinceId: provinceId };
            const province = provinces.find((province) => province.id === provinceId.toString());
            axios.get(process.env.REACT_APP_API_AUTH_URL+'/cities', { headers: headers, params: params })
            .then(response => {
                const cities = response.data.message;
                const currentCity = cities.find((city) => city.id === cityId.toString());
                cityMap[cityId] = {
                    cityName: currentCity.name,
                    provinceName: province.name,
                    provinceId: provinceId
                }
                if(formIndex === forms.length - 1) {
                    print(forms)
                } else {
                    getCity(forms[formIndex+1].provinceId, forms[formIndex+1].cityId, formIndex+1, forms);
                }
            })
        } else {
            if(formIndex === forms.length - 1) {
                print(forms)
            } else {
                getCity(forms[formIndex+1].provinceId, forms[formIndex+1].cityId, formIndex+1, forms);
            }
        }
    }

    const getLabel = (list, key) => {
        if (list.length && key !== '') {
            const keyType = typeof key;
            const index = list.findIndex(({ id, name }) => {
                return keyType === 'number' ? (id === key.toString()) : (id === key);
            });
            if(index !== -1) {
                return list[index].name;
            }
            return "";
        }
        return "";
    }

    const getDeliveryFormsData = (formIds) => {
        const headers = {
            'Authorization': "Bearer " + localStorage.getItem("access_token")
          };

        axios.get(`${process.env.REACT_APP_API_AUTH_URL}/viewDeliveryForm?objectId=${formIds}`, { headers: headers })
        .then(response => {
            generateData(response.data.message)
        })
        .catch((error) => {
            props.snackbar('Something went wrong! Try again!', 'error');
        });
    }

    const print = (forms) => {
        
        setIsLoading(false);

        const div = document.createElement('div');
        div.innerHTML = ``;

        var printWindow = window.open(' ', ' ', 'height=1000,width=1000');
        printWindow.document.write('<html><head><title> </title>');
        printWindow.document.write(
            `</head>
            <style>
                * {
                    font-family: "Roboto","Helvetica","Arial",sans-serif;
                }
                .print-container {
                    page-break-before: always;
                    display: flex; 
                    flex-wrap: wrap; 
                }
                .print-content {
                    width: 47%; 
                    padding: 5px;
                }
                .form-container {
                    font-size: 13px;
                    border: 1px solid black;
                    border-radius: 5px;
                    padding: 5px;
                    display: block;
                }
                .info {
                    width: 100%;
                    border-radius: 5px;
                }
                .title {
                    display: flex;
                    justify-content: center;
                    padding-bottom: 10px;
                    font-weight: bold;
                }
                .row-info {
                    display: flex;
                    justify-content: space-evenly;
                }
                .label {
                    flex-basis: 50%;
                    padding: 9px;
                }
                .value {
                    flex-basis: 50%;
                    padding: 9px;
                    font-weight: bold;
                }
            </style>
            <body>`
        );
        let doc = `<div>`;
        forms.forEach((form, index) => {
            doc += `
            ${index % 4 === 0 ? '<div class="print-container">' : ''}
            <div class="print-content">
                <div class="form-container">
                    <div class="title">Delivery Order Number: ${form.objectId}</div>
                    <div style="display: flex;">
                        <div class="info">
                            <div class="row-info">
                                <div class="label">
                                    <div>
                                        Supplier Name:
                                    </div>
                                </div>
                                <div class="value">
                                    <div>
                                        ${form.entryName}
                                    </div>
                                </div>
                            </div>
                            <div class="row-info">
                                <div class="label">
                                    <div>
                                        Driver's Mobile Number:
                                    </div>
                                </div>
                                <div class="value">
                                    <div>
                                        ${form.driverContactNo}
                                    </div>
                                </div>
                            </div>
                            <div class="row-info">
                                <div class="label">
                                    <div>
                                        City:
                                    </div>
                                </div>
                                <div class="value">
                                    <div>
                                        ${cityMap[form.cityId].cityName}
                                    </div>
                                </div>
                            </div>
                            <div class="row-info">
                                <div class="label">
                                    <div>
                                        Province:
                                    </div>
                                </div>
                                <div class="value">
                                    <div>
                                    ${cityMap[form.cityId].provinceName}
                                    </div>
                                </div>
                            </div>
                            <div class="row-info">
                                <div class="label">
                                    <div>
                                        Truck/Vehicle Plate Number:
                                    </div>
                                </div>
                                <div class="value">
                                    <div>
                                        ${form.truckNo}
                                    </div>
                                </div>
                            </div>
                            <div class="row-info">
                                <div class="label">
                                    <div>
                                        Quantity (MT):
                                    </div>
                                </div>
                                <div class="value">
                                    <div>
                                        ${form.quantitystr}
                                    </div>
                                </div>
                            </div>
                            <div class="row-info">
                                <div class="label">
                                    <div>
                                        Type of Cargo:
                                    </div>
                                </div>
                                <div class="value">
                                    <div>
                                    ${getLabel(cargoTypes, form.cargoType)}
                                    </div>
                                </div>
                            </div>
                            <div class="row-info">
                                <div class="label">
                                    <div>
                                        Remarks:
                                    </div>
                                </div>
                                <div class="value">
                                    <div>
                                    ${getLabel(remarks, form.remarks)}
                                    </div>
                                </div>
                            </div>
                            <div class="row-info">
                                <div class="label">
                                    <div>
                                        Date:
                                    </div>
                                </div>
                                <div class="value">
                                    <div>
                                        ${formatDate(new Date(form.slotDate),'mm/dd/yyyy')}
                                    </div>
                                </div>
                            </div>
                            <div class="row-info">
                                <div class="label">
                                    <div>
                                        Time Slot:
                                    </div>
                                </div>
                                <div class="value">
                                    <div>
                                        ${(form.slotStartTime !== null ? form.slotStartTime.slice(0, -3) : ' ') + "-" + (form.slotEndTime !== null ? form.slotEndTime.slice(0, -3) : ' ')}
                                    </div>
                                </div>
                            </div>
                            <div class="row-info">
                                <div class="label">
                                    <div>
                                        Status:
                                    </div>
                                </div>
                                <div class="value">
                                    <div>
                                    ${form.status}
                                    </div>
                                </div>
                            </div>
                            ${
                                form.status === 'Cancelled' ? '<div class="row-info"><div class="label"><div>Cancellation Reason:</div></div><div class="value" style="font-size: 9px;"><div>'+form.cancelReason+'</div></div></div>' : '</br></br>'
                            }
                        </div>
                    </div>
                
                </div>
            </div>
            ${index % 4 === 3 ? '</div>' : ''}
            `
        })
        doc += `</div>`
        printWindow.document.write(doc);
        printWindow.document.write(
            `</body></html>`
        );

        printWindow.print();
        printWindow.close();

        return true;
    }

    return (
        <div>
            {
                isLoading 
                ?  
                <Button 
                size="small" 
                variant="text" 
                style={{ float: 'right' }}>
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress style={{width: '25px', height: '25px', display: 'block', marginLeft: 'auto', marginRight: 'auto'}} />
                    </Box>
                </Button>
                :
                <Button 
                size="small" 
                onClick={() => {
                    setIsLoading(true);
                    getDeliveryFormsData(props.formIds.toString());

                }} 
                variant="text" 
                style={{ float: 'right' }}>
                    <PrintIcon />
                </Button>
            }
        </div>
    )
}


const mapStateToProps = (state) => ({
    getFormAPIResponse: state.editForm.getFormAPIResponse
});

export default connect(mapStateToProps)(PrintDeliveryForms);