import TemplateForm from './templateForm';
import { getTemplateDetails } from '../../store/reducers/templateForm';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const EditTemplate = (props) => {
    const dispatch = useDispatch();
    const params = useParams();

    useEffect(() => {
        dispatch(getTemplateDetails(params.id));
    }, [dispatch, params.id]);

    return (
        <TemplateForm mode="edit" {...props} />
    )
}

export default EditTemplate;