import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import Loading from '../shared/Loading';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import dayjs from 'dayjs';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { withRouter } from '../shared/withRouter';

const axios = require('axios');
export const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflow:'scroll',
    height: '500px',

    p: 4,
  };

class Home extends Component {
    constructor(props) {
        super(props);
        // formInitTime & formSubmitTime
        this.formInitTime = "";
        this.formSubmitTime = "";
      
        this.handleSubmit = this.handleSubmit.bind(this);
        this.formValidation = this.formValidation.bind(this);
        this.handleChangeEntryName = this.handleChangeEntryName.bind(this);
        this.handleChangeProvince = this.handleChangeProvince.bind(this);
        this.handleChangeCity = this.handleChangeCity.bind(this);
        this.handleChangeDriverPhoneNumber = this.handleChangeDriverPhoneNumber.bind(this);
        this.handleChangeTruckDetails = this.handleChangeTruckDetails.bind(this);
        this.handleChangeQuantity = this.handleChangeQuantity.bind(this);
        this.handleChangeCargoType = this.handleChangeCargoType.bind(this);
        this.handleChangeRemark = this.handleChangeRemark.bind(this);
        this.handleBlurQuantity = this.handleBlurQuantity.bind(this);
        this.handleBlurDriverPhoneNumber = this.handleBlurDriverPhoneNumber.bind(this);
        this.handleModalOpen = this.handleModalOpen.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.getSlotDates = this.getSlotDates.bind(this);
        this.handleTimeSlot = this.handleTimeSlot.bind(this);
        this.handleChangetermsandconditionsErr = this.handleChangetermsandconditionsErr.bind(this);
        this.disableDates = this.disableDates.bind(this);
        this.setDeliveryFormData = this.setDeliveryFormData.bind(this);
        this.state = {
          province: [],
          city: [],
          cargoTypes: [],
          remarks: [],
          selectedProvince: 71,
          selectedCity: "",
          selectCargoType: null,
          selectedRemark: null,
          quantity: null,
          driverContactNo: null,
          entryName: "",
          truckDetails: null,
          date: null,
          dateErr: false,
          dateErrMsg: '',
          truckHistoryDetails: [{vehicleRegNo: ""}],
          entryNameErr: false,
          provinceErr: false,
          driverPhoneNumberErr: false,
          driverPhoneNumberErrMsg: "This information is required.",
          truckDetailsErr: false,
          truckDetailsErrMsg: "This information is required.",
          quantityErr: false,
          quantityErrMessage: "This information is required.",
          cityErr: false,
          cargoTypeErr: false,
          remarkErr: false,
          formError: false,
          formErrorMessage: "We found some errors. Please review the form and make corrections.",
          email: this.decodeToken(localStorage.access_token).sub,
          availableSlotDates: [],
          timeSlots: [],
          slotStartTime: '',
          slotEndTime: '',
          slotId: null,
          slotErr: false,
          slotErrMsg: '',
          termsandconditions: false,
          termsandconditionsErr: false,
          policyList: []
        }
      }

    componentDidMount(){
        this.formInitTime = dayjs().format('YYYY-MM-DD hh:mm:ss');
        this.getProvince()
        this.getCargoTypes()
        this.getUserDetails()
        this.getTruckHistory()
        this.getSlotDates()
        this.getPolicyList()
        if (this.props.mode === 'edit' && this.props.data.objectId) {
            this.setDeliveryFormData(this.props.data);
            this.getCities(this.props.data.provinceId, this.props.data.cityId);
            this.getRemarks(this.props.data.remarks);
        } else {
            this.getCities();
            this.getRemarks();
        }
    }

    // get policy
    getPolicyList() {
        const headers = {
            'Authorization': "Bearer "+localStorage.getItem("access_token")
          };
        axios.get(process.env.REACT_APP_API_AUTH_URL+'/getFairusagePolicy', { headers: headers })
        .then(response => {
            const { data: {message} } = response;
            const list = message || [];
            this.setState({policyList: list})
        })
        .catch((error) => {
            console.log('error ' + error);
        });
    }

    setDeliveryFormData(data) {
        this.setState({
            driverContactNo: data.driverContactNo,
            selectedProvince: data.provinceId,
            selectedCity: data.cityId,
            truckDetails: data.truckNo,
            //--quantity changed to str ---
            quantity: data.quantitystr,
            selectCargoType: data.cargoType ? parseInt(data.cargoType) : null,
            selectedRemark: data.remarks ? parseInt(data.remarks) : null,
            date: data.slotDate,
            slotStartTime: data.slotStartTime || '',
            slotEndTime: data.slotEndTime || '',
            slotId: data.slotId
        });
    }

    getSlotDates(){
        const headers = {
            'Authorization': "Bearer "+localStorage.getItem("access_token")
          };
        axios.get(process.env.REACT_APP_API_AUTH_URL+'/slotDate', { headers: headers })
        .then(response => {
            this.setState({availableSlotDates: response.data.message})
        })
        .catch((error) => {
            console.log('error ' + error);
        });
    }

    decodeToken (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
    }

    getTruckHistory(){
        const headers = {
            'Authorization': "Bearer "+localStorage.getItem("access_token")
          };
          const params = {userId: this.state.email}
        axios.get(process.env.REACT_APP_API_AUTH_URL+'/recentFiveTruckRegNos', { headers: headers, params: params })
        .then(response => {
            this.setState({truckHistoryDetails: response.data.message})
        })
        .catch((error) => {
            console.log('error ' + error);
        });        
    }
    getCities(provinceId, cityId){
        const headers = {
            'Authorization': "Bearer "+localStorage.getItem("access_token")
          };
          const params = {provinceId: provinceId ? provinceId : this.state.selectedProvince}
        axios.get(process.env.REACT_APP_API_AUTH_URL+'/cities', { headers: headers, params: params })
        .then(response => {
            this.setState({city: response.data.message, selectedCity: cityId ? cityId : 1420})
        })
        .catch((error) => {
            console.log('error ' + error);
        });
    }
    getUserDetails(){
        const headers = {
            'Authorization': "Bearer "+localStorage.getItem("access_token")
          };
          axios({
            method: 'post',
            url: process.env.REACT_APP_API_AUTH_URL+'/getGroupsByUserId',
            data: {         
            },headers: headers
          })
          .then((response) => {
            if(response.data.statusCode === '200'){
                this.setState({entryName: response.data.message[0].userfullname})
            }
          })
          .catch((error) => {
            console.log('Error', error.message);
          });
    }
    getCargoTypes(){
        const headers = {
            'Authorization': "Bearer "+localStorage.getItem("access_token")
          };
        axios.get(process.env.REACT_APP_API_AUTH_URL+'/cargotypes', { headers: headers })
        .then(response => {
            this.setState({cargoTypes: response.data.message})
        })
        .catch((error) => {
            console.log('error ' + error);
        });        
    }
    getRemarks(remarksId){
        const headers = {
            'Authorization': "Bearer "+localStorage.getItem("access_token")
          };
        axios.get(process.env.REACT_APP_API_AUTH_URL+'/remarks', { headers: headers })
        .then(response => {
            this.setState({remarks: response.data.message, selectedRemark: remarksId ? remarksId : 2})
        })
        .catch((error) => {
            console.log('error ' + error);
        });     
    }
    getProvince(){
        const headers = {
            'Authorization': "Bearer "+localStorage.getItem("access_token")
          };
        axios.get(process.env.REACT_APP_API_AUTH_URL+'/provinces', { headers: headers })
        .then(response => {
            this.setState({province: response.data.message})
        })
        .catch((error) => {
            console.log('error ' + error);
        });
    }

    getCity(provinceId){
        const headers = {
            'Authorization': "Bearer "+localStorage.getItem("access_token")
          };
          const params = {provinceId: provinceId}
        axios.get(process.env.REACT_APP_API_AUTH_URL+'/cities', { headers: headers, params: params })
        .then(response => {
            this.setState({city: response.data.message})
        })
        .catch((error) => {
            console.log('error ' + error);
        });
    }

    handleChangeEntryName(e){
        this.setState({entryNameErr: false, entryName: e.target.value})
    }

    handleChangeDriverPhoneNumber(e){
        this.setState({driverPhoneNumberErr: false, driverContactNo: e.target.value})
    }

    handleChangeTruckDetails(e,value){
        if(value.length > 20){
            this.setState({truckDetailsErr: true, truckDetailsErrMsg: "Truck details cannot be longer than 20 characters.", truckDetails: value})
        }else{
            this.setState({truckDetailsErr: false, truckDetails: value})
        }
    }

    handleChangeQuantity(e){
        this.setState({quantityErr: false, quantity: e.target.value})
    }
    handleBlurDriverPhoneNumber(e){
        if(e.target.value) {
            this.validatePhoneNumber(e.target.value)
        };
    }
    // ------
    handleChangetermsandconditionsErr(e){
        this.setState({termsandconditionsErr: false, termsandconditions: e.target.checked})
    }



    // ----------
    handleBlurQuantity(e) {
        if(!this.state.quantity) {
            return;
        }
        var num = parseFloat(this.state.quantity);
        if(!isNaN(num)) {
            var cleanNum = num.toFixed(2);
            this.setState({quantity: cleanNum});
        }else if(isNaN(num)){
            this.setState({quantityErr: true, quantityErrMessage: "Please enter valid number"})
        }
      }

    handleChangeProvince(event){
        this.setState({selectedProvince: event.target.value, provinceErr: false, selectedCity: ""})
        this.getCity(event.target.value)
    }

    handleChangeCity(event){
        this.setState({selectedCity: event.target.value, cityErr: false})
    }

    handleChangeCargoType(event){
        this.setState({selectCargoType: event.target.value, cargoTypeErr: false,slotStartTime: '', slotEndTime: '', slotId: null, date: null})
        
    }

    handleChangeRemark(event){
        this.setState({selectedRemark: event.target.value, remarkErr: false})
    }

    validatePhoneNumber(number){
        var isValid = true
        if (typeof number !== "undefined") {
            var pattern = new RegExp(/^0(9)\d{9}$/);
            if (!pattern.test(number)) {          
              isValid = false;
              this.setState({driverPhoneNumberErrMsg: "Please enter valid 11 digit number starts with 09.", driverPhoneNumberErr: true})                    
            }
          }
        return isValid  
    }

    formatDateWithName(value){
        var date = new Date(value)
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = date.toLocaleString('en-us', { month: 'short' }); 
        var yyyy = date.getFullYear();

        var formatedDate = dd + '-' + mm + '-' + yyyy;
        return formatedDate
    }

    handleSubmit(event, status){
        event.preventDefault();
        const validation = status === 'Submitted' ? this.formValidation() : this.draftFormValidation();
        const headers = {
            'Authorization': "Bearer "+localStorage.getItem("access_token"),
          };
         
        if(validation){
            this.formSubmitTime = dayjs().format('YYYY-MM-DD hh:mm:ss');
            axios({
              method: 'post',
              url: process.env.REACT_APP_API_AUTH_URL+'/saveDeliveryForm',
              data: {
                "objectId":this.props.mode === 'edit' ? this.props.data.objectId : '',
                "userId": this.state.email,
                "entryName": this.state.entryName,
                "provinceId":this.state.selectedProvince,
                "cityId":this.state.selectedCity,
                "driverContactNo": this.state.driverContactNo,
                "truckNo": this.state.truckDetails,
                "quantity": this.state.quantity,
                "cargoType": this.state.selectCargoType,
                "remarks": this.state.selectedRemark,
                "slotSequence":1,
                "status": status,
                "slotDate": this.state.date ? this.formatDateWithName(this.state.date) : undefined,
                "slotStartTime": this.state.slotStartTime !== '' ? this.formatDateWithName(this.state.date)+' '+this.state.slotStartTime : undefined,
                "slotEndTime": this.state.slotStartTime !== '' ? this.formatDateWithName(this.state.date)+' '+this.state.slotEndTime : undefined,
                "slotId": this.state.slotId,
                "formInitTime": this.formInitTime,
                "formSubmitTime": this.formSubmitTime,
                "fairUsagePolicy": this.state.termsandconditions
              },headers: headers
            })
            .then((response) => {
                if (response.data.statusCode === "200") {
                    this.props.navigate('/supplier-home');
                    this.props.snackbar("Form has been successfully submitted");
                } else if (response.data.statusCode === '500') {
                    this.props.snackbar(response.data.statusMessage, 'error', 5000);
                    this.getSlotDates();
                } else if (response.data.statusCode === '403') {
                    window.location.replace('/forbidden');
                } else {
                    this.setState({ formError: true, formErrorMessage: response.data.statusMessage })
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                }
            })
            .catch((error) => {
              console.log('Error', error.message);
            });
          }
    }

    draftFormValidation(){
        this.setState({ entryNameErr: false,
            driverPhoneNumberErr: false,
            truckDetailsErr: false,
           
            quantityErr: false,
            cityErr: false,
            cargoTypeErr: false,
            remarkErr: false, slotErr: false, dateErr: false })

            if(this.state.entryName === ""){
                this.setState({ entryNameErr: true} )
            }
        

        // if(this.state.driverContactNo === null){
        //     this.setState({ driverPhoneNumberErr: true} )
        // }

        if(this.state.driverContactNo !== null){
            this.validatePhoneNumber(this.state.driverContactNo)
        }

        if(this.state.truckDetails === null){
            this.setState({ truckDetailsErr: true} )
        }

        if (isNaN(this.state.quantity)) {
            this.setState({ quantityErr: true} )
        }

        // if(this.state.quantity === null || isNaN(this.state.quantity)){
        //     this.setState({ quantityErr: true} )
        // }

        // if(this.state.selectedProvince === null){
        //     this.setState({ provinceErr: true} )
        // }


        // if(this.state.selectedCity === ""){
        //     this.setState({ cityErr: true} )
        // }

        // if(this.state.selectCargoType === null){
        //     this.setState({ cargoTypeErr: true} )
        // }

        // if(this.state.selectedRemark === null){
        //     this.setState({ remarkErr: true} )
        // }
        
        // if(this.state.slotStartTime === '' || this.state.slotEndTime === ''){
        //     this.setState({ slotErr: true})
        // }

        // if(this.state.date === null){
        //     this.setState({ dateErr: true})
        // }

        if(this.state.entryName !== "" && this.state.truckDetails !== null){
            return true
        } else {
            return false
        }
    }

    formValidation(){
        this.setState({ entryNameErr: false,
            driverPhoneNumberErr: false,
            truckDetailsErr: false,
            termsandconditionsErr: false,
            quantityErr: false,
            cityErr: false,
            cargoTypeErr: false,
            remarkErr: false, slotErr: false, dateErr: false })

        if(this.state.entryName === ""){
            this.setState({ entryNameErr: true} )
        }

        if(this.state.driverContactNo === null){
            this.setState({ driverPhoneNumberErr: true} )
        }

        if(this.state.driverContactNo !== null){
            this.validatePhoneNumber(this.state.driverContactNo)
        }

        if(this.state.truckDetails === null){
            this.setState({ truckDetailsErr: true} )
        }
        // -------------


        if(this.state.termsandconditions === false){
            this.setState({ termsandconditionsErr: true} )
        }



        // --------------

        if(this.state.quantity === null || isNaN(this.state.quantity)){
            this.setState({ quantityErr: true} )
        }

        if(this.state.selectedProvince === null){
            this.setState({ provinceErr: true} )
        }


        if(this.state.selectedCity === ""){
            this.setState({ cityErr: true} )
        }

        if(this.state.selectCargoType === null){
            this.setState({ cargoTypeErr: true} )
        }

        if(this.state.selectedRemark === null){
            this.setState({ remarkErr: true} )
        }
        
        if(this.state.slotStartTime === '' || this.state.slotEndTime === ''){
            this.setState({ slotErr: true})
        }

        if(this.state.date === null){
            this.setState({ dateErr: true})
        }

        if(this.state.entryName !== "" && this.state.driverContactNo !== null && this.state.truckDetails !== null && this.state.quantity !== null && this.state.selectedProvince !== null && this.state.selectedCity !== "" && this.state.selectCargoType !== null && this.state.selectedRemark !== null && !isNaN(this.state.quantity) && this.state.slotStartTime !== '' && this.state.slotEndTime !== '' && this.state.date !== null  &&  this.state.termsandconditions !== false){
            return true
        } else {
            return false
        }
    }
    setValue(newValue){
        this.setState({date: newValue})
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        return [year, month, day].join('-');
    }

    disableDates(date){
        var value = this.formatDate(date);
        if(this.state.date && this.state.date === value ) {
            return false;
        }
        var items = this.state.availableSlotDates;        
        return items.every((item)=>{ return item.slotDate!==value; });
    }

    getFormattedDate(date) {
        var year = date.getFullYear();
      
        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;
      
        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        
        return year + '-' + month + '-' + day ;
      }

    handleModalOpen(newValue){

        if(this.state.selectCargoType === null || this.state.truckDetails === null || this.state.truckDetails === "" || newValue === null){
            if(this.state.selectCargoType === null){
                this.setState({ cargoTypeErr: true} )
            } 
            if(this.state.truckDetails === null || this.state.truckDetails === ""){
                this.setState({ truckDetailsErr: true} )
            }
        }
        else{
            const headers = {
                'Authorization': "Bearer "+localStorage.getItem("access_token")
            };
            const params = {date: this.formatDateWithName(newValue), cargoTypeId: this.state.selectCargoType, truckno: this.state.truckDetails}
            axios.get(process.env.REACT_APP_API_AUTH_URL+'/slotsAvailable', { headers: headers, params: params })
            .then(response => {
                if(response.data.message && response.data.message.length) {
                    this.setState({isModelOpen: true, date: newValue, timeSlots: response.data.message, dateErr: false})
                } else {
                    this.props.snackbar("No slots available for the selected date!", "warning");
                    this.setState({slotStartTime: "", slotEndTime: "", date: null})

                }
            })
            .catch((error) => {
                console.log('error ' + error);
            });
        }

    }

    handleModalClose(){
        this.setState({isModelOpen: false})
    }
    
    handleTimeSlot(event, value){
        this.setState({slotId: value.slotId, slotStartTime: value.timeSlotStart, slotEndTime: value.timeSlotEnd, isModelOpen: false, slotErr: false})
    }

    render() {
        if((this.state.province.length !== 0) && (this.state.cargoTypes.length !== 0) && (this.state.remarks.length !== 0) ){
            return (
                    // <Layout>
                    <>
                        <Typography variant="h6" gutterBottom>
                            {this.props.mode === 'edit' ? 'Edit' : 'New'} Delivery Form
                            <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    style={{float: 'right'}}
                                    onClick={(event) => this.handleSubmit(event,'Draft')}
                                    >
                                    Save Draft
                            </Button>   
                        </Typography>
                        <Box component="form" noValidate onSubmit={(event) => this.handleSubmit(event, 'Submitted')} sx={{ mt: 3 }}>                         
                        <Grid container spacing={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} style={{paddingLeft: '25px'}}>
                                <FormControl fullWidth error={this.state.formError}>
                                <FormHelperText>
                                    {this.state.formError === true ? this.state.formErrorMessage : '' }
                                </FormHelperText>   
                                </FormControl>    
                                </Grid>     
                            </Grid>  

                            <Grid item xs={12} sm={6}>
                                <FormControl sx={{ m: 1, minWidth: 120,width: '100%' }}>

                                <TextField
                                    id="entryName"
                                    name="entryName"
                                    label="Supplier Name"
                                    fullWidth
                                    autoComplete="entry-name"
                                    variant="standard"
                                    onChange={this.handleChangeEntryName}
                                    error={this.state.entryNameErr}
                                    value = {this.state.entryName}
                                    disabled = {true}
                                    helperText={this.state.entryNameErr ? "This information is required." : null}                                    
                                />
                                </FormControl>

                        </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl sx={{ m: 1, minWidth: 120,width: '100%' }}>

                                <TextField
                                    id="driver-phone-number"
                                    name="driver-phone-number"
                                    label="Driver's Mobile Number"
                                    fullWidth
                                    autoComplete="Driver's Mobile Number"
                                    variant="standard"
                                    onChange={this.handleChangeDriverPhoneNumber}
                                    onBlur={this.handleBlurDriverPhoneNumber}
                                    value = {this.state.driverContactNo}
                                    error={this.state.driverPhoneNumberErr}
                                    helperText={this.state.driverPhoneNumberErr ? this.state.driverPhoneNumberErrMsg : null}                                    

                                />
                                </FormControl>
                            </Grid>  
                            <Grid item xs={12} sm={12}>                            
                                <Typography id="modal-modal-title" variant="h7" style={{paddingLeft: '5px'}}>
                                    Supplier's Address
                                </Typography>     
                            </Grid>                                                  
                            <Grid item xs={12} sm={6}>
                               
                                <FormControl error={this.state.provinceErr} variant="standard" sx={{ m: 1, minWidth: 120,width: '100%' }}>
                                    <InputLabel id="province-select-standard-label">Province</InputLabel>
                                    <Select
                                    labelId="province-select-standard-label"
                                    id="province"            
                                    label="Province"
                                    onChange={this.handleChangeProvince}
                                    value={this.state.selectedProvince}
                                    error={this.state.provinceErr}
                                    >
                                        {this.state.province.map(({ id, name }) => <MenuItem value={id} >{name}</MenuItem>)}
                                    </Select>
                                    <FormHelperText>
                                        {this.state.provinceErr === true ? 'This information is required.' : '' }
                                    </FormHelperText>                                       
                                </FormControl>

                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl error={this.state.cityErr} variant="standard" sx={{ m: 1, minWidth: 120,width: '100%' }}>
                                    <InputLabel id="city-select-standard-label">City</InputLabel>
                                    <Select
                                    labelId="city-select-standard-label"
                                    id="city"            
                                    label="City"
                                    error={this.state.cityErr}
                                    onChange={this.handleChangeCity}
                                    value={this.state.selectedCity}
                                    >          
                                        {(this.state.city.length !== 0 )? (
                                            this.state.city.map(({ id, name }) => <MenuItem value={id} >{name}</MenuItem>)
                                        ) : (
                                            <span/>
                                        )}

                                    </Select>
                                    <FormHelperText>
                                        {this.state.cityErr === true ? 'This information is required.' : '' }
                                    </FormHelperText>                                    
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                            <FormControl sx={{ m: 1, minWidth: 120,width: '100%' }}>
                                <Autocomplete
                                        disableClearable
                                        onChange={(e,value) => this.handleChangeTruckDetails(e,value)}
                                        options={this.state.truckHistoryDetails.map((option) => option.vehicleRegNo)}
                                        value={this.state.truckDetails}
                                        renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            InputProps={{
                                            ...params.InputProps,
                                            maxLength: 12
                                            }}
                                            id="truck-details"
                                            name="truck-details"
                                            label="Truck/Vehicle Plate Number"
                                            fullWidth
                                            autoComplete="vehicle details"
                                            variant="standard"
                                            onChange={(e) => this.handleChangeTruckDetails(e,e.target.value)}
                                            error={this.state.truckDetailsErr}
                                            helperText={this.state.truckDetailsErr ? this.state.truckDetailsErrMsg : null}
                                        />
                                        )}
                                    />
                            </FormControl>

                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl sx={{ m: 1, minWidth: 120,width: '100%' }}>
                                <TextField
                                    id="quantity"
                                    name="quantity"
                                    label="Quantity (MT)"
                                    fullWidth
                                    variant="standard"
                                    value = {this.state.quantity}
                                    onChange={this.handleChangeQuantity}
                                    onBlur={this.handleBlurQuantity}
                                    error={this.state.quantityErr}
                                    helperText={this.state.quantityErr ? this.state.quantityErrMessage : null}
                                />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl error={this.state.cargoTypeErr} variant="standard" sx={{ m: 1, minWidth: 120,width: '100%' }}>
                                    <InputLabel id="type-of-cargo-label">Type of Cargo</InputLabel>
                                    <Select
                                    labelId="type-of-cargo"
                                    id="cargo-type"            
                                    label="Type of Cargo"
                                    value={this.state.selectCargoType}
                                    error={this.state.cargoTypeErr}
                                    onChange={this.handleChangeCargoType}

                                    >
                                        {this.state.cargoTypes.map(({ id, name }) => <MenuItem value={id} >{name}</MenuItem>)}
                                    </Select>
                                    <FormHelperText>
                                        {this.state.cargoTypeErr === true ? 'This information is required.' : '' }
                                    </FormHelperText>                                       
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl error={this.state.remarkErr} variant="standard" sx={{ m: 1, minWidth: 120,width: '100%' }}>
                                    <InputLabel id="remarks-label">Remarks</InputLabel>
                                    <Select
                                    labelId="remarks"
                                    id="remarks"            
                                    label="Remarks"
                                    error={this.state.remarkErr}
                                    onChange={this.handleChangeRemark}
                                    value={this.state.selectedRemark}

                                    >
                                        {this.state.remarks.map(({ id, name }) => <MenuItem value={id} >{name}</MenuItem>)}
                                    </Select>
                                    <FormHelperText>
                                        {this.state.remarkErr === true ? 'This information is required.' : '' }
                                    </FormHelperText>                                     
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                 <FormControl error={this.state.dateErr} variant="standard" sx={{ m: 1, minWidth: 120,width: '100%' }}>

                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        label="Select Date"
                                        value={this.state.date}
                                        onChange={(newValue) => {
                                        this.handleModalOpen(newValue)
                                        }}
                                        allowSameDateSelection={true}
                                        shouldDisableDate={this.disableDates}
                                        renderInput={(params) => <TextField {...params} disabled = {true}/>}
                                    />
                                </LocalizationProvider>
                                <FormHelperText>
                                    {this.state.dateErr === true ? 'This information is required.' : '' }
                                </FormHelperText>   
                                </FormControl>                                     
                               
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl error={this.state.slotErr} sx={{ m: 1, minWidth: 120,width: '100%' }}>
                                    <TextField
                                        id="Time-slot"
                                        name="Time slot"
                                        label="Time Slot"
                                        fullWidth
                                        autoComplete="time slot"
                                        variant="standard"
                                        value = {this.state.slotStartTime.slice(0, -3)+"-"+this.state.slotEndTime.slice(0, -3)}
                                        disabled = {true}
                                        error={this.state.slotErr}
                                        helperText={this.state.slotErr ? "This information is required." : null}                                    
                                    />
                                </FormControl>
                            </Grid>
                            {/* --------------- */}
                            <Grid item xs={12} sm={12}>
                            <FormControl error={this.state.termsandconditionsErr} sx={{ m: 1, minWidth: 120,width: '100%' }}>
                            <label>
                                <FormControlLabel
                                control={<Checkbox  
                                required="true"
                                onChange={this.handleChangetermsandconditionsErr}
                                checked = {this.state.termsandconditions}
                                id="termsandconditions"
                                name="termsandconditions"
                                error={this.state.termsandconditionsErr}
                                />}
                                
                                label="I understand limitations below and any changes I should consult the Area Manager."
                                />
                                 <li style={{marginLeft:'27px'}}>Delivery can only be scheduled or booked two (2) days before intended delivery date.</li>
                                  <li style={{marginLeft:'27px'}}> Only the area manager can edit and cancel any scheduled delivery in the App.</li>
                                  {this.state.policyList.map((v, index) => 
                                    <li key={{index}} style={{marginLeft:'27px'}}>{ v }</li>
                                  )}
                                </label>
                                    <FormHelperText>
                                 {this.state.termsandconditionsErr === true ? 'The Terms and Conditions required.' : '' }
                                    </FormHelperText> 
                                    </FormControl>
                             </Grid>


                            {/* -------------------- */}

                            <Grid item xs={12} sm={12}>

                            <FormControl sx={{ m: 1 }}>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    >
                                    Submit
                                </Button>
                                </FormControl>
                                <FormControl sx={{ m: 1 }}>
                                <Link href={'/supplier-home'} style={{marginLeft:'30px;'}}>
                                <Button
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    >
                                    Cancel
                                </Button>
                                </Link>
                                </FormControl>
                            </Grid>
                        </Grid>
                        </Box>

                        <Modal
                            open={this.state.isModelOpen}
                            onClose={this.handleModalClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            style={{ overflow:'scroll' }}
                            >
                            <Box sx={style}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Select Time Slot
                                </Typography>
                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    <Paper elevation={3}>

                                        {(() => {
                                            if (this.state.timeSlots.length !== 0) {
                                            return (
                                                <Grid container spacing={2} style={{paddingLeft: '15px', paddingRight: '15px', mariginTop: '15px', mariginBottom: '15px'}}>
                                                {this.state.timeSlots.map((value, i) =>
                                                                <Grid item xs={12} sm={4}>
                                                                    <Button                   
                                                                    variant="contained"
                                                                    disabled = {(parseInt(value.slotsAvailable) > 0 && parseInt(value.slotsAvailableForTruck) !== 1) ? false : true}
                                                                    onClick={(event) => this.handleTimeSlot(event, value)}
                                                                    
                                                                   sx={{ mt: 0, mb: 2, width: '100%' }} 
                                                                    >
                                                                        <AccessTimeIcon/>
                                                                        {value.timeSlotStart.slice(0, -3)+"-"+value.timeSlotEnd.slice(0, -3)}
                                                                    </Button>
                                                                </Grid>
                                                        )}
                                                </Grid>
                                                )
                                            }
                                        })()}


                                    </Paper>
                                </Typography>
                            </Box>
                        </Modal>
                        </>
                    // </Layout>
            );
        } else {
            return(
                // <Layout>
                    <Loading/>
                // </Layout>
            );
        }

    }
    
}
 
export default withRouter(Home);
