import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

export default function DateTemplatesTreeV2(props) {

    const [expanded, setExpanded] = React.useState('');

    const handleChange =
    (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    const generateTreeDate = (data) => {
        let result = [];
        data.forEach((i) => {
            const date = new Date(i.day);
            const month = date.toLocaleDateString('en-GB', {month: 'long'});
            const monthObj = result.find((s) => s.label === month);
            const label = `${String(date.getDate()).padStart(2, '0')} ${date.toLocaleDateString('en-GB', {weekday: 'short'})} - ${i.templateName}`;
            if(!monthObj) {
                result.push({
                    label: month,
                    children: [{label: label}]
                });
            } else {
                monthObj.children.push({
                    label: label
                });
            }
        });
        return result;
    };

    const datewiseTemplates = generateTreeDate(props.templates);
    console.log(datewiseTemplates);

    return (
        <div>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>View Date-wise Templates</Typography>
                </AccordionSummary>
                <AccordionDetails>

                    {
                        datewiseTemplates.map((month, i) => {
                            return <Accordion expanded={expanded === month.label} onChange={handleChange(month.label)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={month.label}
                                id={month.label}
                            >
                                <Typography>{month.label}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>

                            {
                                month.children.map((day) => {
                                    return <List dense={true}>
                                                <ListItem sx={{height: 15}}>
                                                    <ListItemText
                                                        primary={day.label}
                                                    />
                                                </ListItem>
                                            </List>
                                            
                                })
                            }

                            
                            </AccordionDetails>
                            </Accordion>
                        })
                    }
                </AccordionDetails>
            </Accordion>
        </div>
    );
}