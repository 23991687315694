import {Grid, Typography } from '@mui/material';
import {
    Outlet
  } from 'react-router-dom';

const SuppliersManagement = () => {
    return (
        <Grid >
            <Typography variant="h5" component="h2">
                Map Suppliers to CTS
            </Typography>
            <Outlet />
        </Grid> 
    )
}

export default SuppliersManagement;