import React, { Component } from 'react';
import { Grid, Button } from '@mui/material';

import Link from '@mui/material/Link';
import SupplierDeliveryForms from './SupplierDeliveryForms';


class Home extends Component {

  constructor(props) {
    super(props);
    this.state = { role: '' };
    console.log("home page")
  }

  render() {

    return (
      <>
        {/* <Layout> */}
          <Grid container spacing={2}>
            <Grid item xs={12} >
              <Link href="/supplier-new-delivery" >
                <Button variant="contained" style={{ float: 'right' }}>New Delivery</Button>
              </Link>
            </Grid>
            <SupplierDeliveryForms />
           
          </Grid>
                            
      </>
    );
  }
 
}

export default Home;