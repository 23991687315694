import { Grid, Typography, Button, Box, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import { Calendar, getAllDatesInRange } from "react-multi-date-picker"
import Divider from '@mui/material/Divider';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import React, { useState, useEffect } from 'react';
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import DateTemplatesTreeV2 from './dateTemplatesTreeV2';
import { useDispatch, useSelector } from 'react-redux';
import { getBlockedUnblockedDates } from '../../store/reducers/blockDates';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './assignTemplate.css';

import { getTemplates, getDatewiseTemplates, setTemplateId, setSlotDates, setFieldValidation, resetForm, assignTemplate, updateAssignTemplateAPIResponse } from '../../store/reducers/assignTemplate';


const AssignTemplate = (props) => {
    const [pickMode, setPickMode] = useState('multiple');
    const [selectedDates, setSelectedDates] = useState(false);

    const [formSubmitted, setFormSubmitted] = useState(false);

    const [isClear, setIsClear] = useState(false);
    const navigate = useNavigate();


    const minDate = new Date();
    minDate.setDate(minDate.getDate() + 1);

    const maxDate = new Date();
    maxDate.setDate(minDate.getDate() + 90); // Only allowed 90 days in advance

    const dispatch = useDispatch();
    

    useEffect(() => {
        dispatch(getBlockedUnblockedDates());
        dispatch(getTemplates());
        dispatch(getDatewiseTemplates());
    },[dispatch]);

    const { dates } = useSelector((state) => state.blockDates);
    const { templates, datewiseTemplates, form, validation } = useSelector((state) => state.assignTemplate);

    const [monthsCount, setMonthsCount] = useState(1);

    const detectScreenWidth = () => {
        const width = window.innerWidth;
        if (width >= 890 ) {
            setMonthsCount(3);
        } else if (width >= 640 ) {
            setMonthsCount(2);
        } else {
            setMonthsCount(1);
        }
    }

    useEffect(() => {
        detectScreenWidth();
    })

    const prepareDatesList = (dates) => {
        const list = {blockedDates: [], unblockedDates: [], bookedDates: []};
        dates.forEach((date) => {
            if(date.status === 'blocked' || date.status === 'partially-blocked') {
                list.blockedDates.push(date.slotDate.replaceAll('-', '/'));
            }
            if(date.status === 'unblocked') {
                list.unblockedDates.push(date.slotDate.replaceAll('-', '/'));
            }
            if(date.status === 'active-blocked') {
                list.bookedDates.push(date.slotDate.replaceAll('-', '/'));
            }
        });
        return list
    }

    const { blockedDates, unblockedDates, bookedDates } = prepareDatesList(dates);

    const onSubmit = () => {
        setFormSubmitted(true);
        const errList = Object.values(validation);
        if(errList.indexOf(true) >= 0) {
            return;
        }
        dispatch(assignTemplate(form))
    }

    useEffect(() => {
        if(Object.keys(props.onAssignTemplate).length) {
            const res = props.onAssignTemplate;
            dispatch(updateAssignTemplateAPIResponse({}));
            if(res.statusCode === '200') {
                props.snackbar('Assigned Successfully!');
                dispatch(getDatewiseTemplates());
                onClear();

            } else if(res.statusCode === '403') {
                navigate('/forbidden');
            } else {
                props.snackbar(res.statusMessage, 'error', 5000);
            }
        }
    });

    const onChangeDates = (dateObjects) => {
        const allDates = pickMode === 'range' ? getAllDatesInRange(dateObjects) : dateObjects;
        let dates = [];
        allDates.forEach((date) => {
            const isWeekend = [0].includes(date.weekDay.index);
            const formatted = date.format().replaceAll('-', '/');

            if(!blockedDates.includes(formatted) && !bookedDates.includes(formatted)) {
                if(isWeekend) {
                    if(unblockedDates.includes(formatted)) {
                        dates.push(formatted.replaceAll('/', '-'));
                    }
                } else {
                    dates.push(formatted.replaceAll('/', '-'));
                }
            }
        });
        dispatch(setSlotDates(dates));
        dispatch(setFieldValidation({...validation, slotDates: !dates.length}))
    }

    const onClear = () => {
        setIsClear(!isClear);
        setFormSubmitted(false);
        dispatch(resetForm());

    }

    const MapDate = (e) => {

        const currentDate = e.date.format();
        const isWeekend = [0].includes(e.date.weekDay.index);


        if(e.date < e.today && e.date.format() !== e.today.format()) {
            return {
                disabled: true
            }

        } else if (bookedDates.includes(currentDate)) {
            return {
                disabled: true,
                style: { color: "white", backgroundColor: "grey" }
            }
        } else if ((blockedDates.includes(currentDate) || isWeekend) && !unblockedDates.includes(currentDate)) {
            return {
                disabled: true,
                style: { color: "white", backgroundColor: "lightgrey" }
            }
        } else {
            const date = datewiseTemplates.find((s) => s.day === currentDate.replaceAll('/', '-'));
            if(date) {
                return {
                    title: date.templateName,
                    disabled: false,
                    style: { color: "white", backgroundColor: "#bbebbb" }
                }
            }
        }
    }

    const onModeChange = (e, mode) => {
        setPickMode(mode);
    };

    const onSelectTemplate = (e) => {
        dispatch(setTemplateId(e.target.value));
        dispatch(setFieldValidation({...validation, templateId: false}))
    }

    const Legends = () => {
        return (
            <Grid item xs={12} sm={12} md={8} lg={8}>
                    <Grid container sx={{fontSize: '11px'}}>
                        <Grid item xs={12} sm={3} md={3}  sx={{display: 'flex'}}>
                            <div className="badge" style={{backgroundColor: 'grey', }}></div>
                            <div >Booked</div>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}  sx={{display: 'flex'}}>
                            <div className="badge" style={{backgroundColor: 'lightgrey', }}></div>
                            <div >Blocked</div>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}  sx={{display: 'flex'}}>
                            <div className="badge" style={{backgroundColor: '#bbebbb', }}></div>
                            <div >Assigned</div>
                        </Grid>
                    </Grid>
                </Grid>
        )
    }

    return (
        <Box
            sx={{
                pt: 1
            }}
        >
            <Typography sx={{mt: 1}} variant="h6" gutterBottom component="div">
                Assign Day Template
            </Typography>
            <Divider />
            <br />
            <Grid container spacing={3} >
                <Grid item xs={12} sm={10} md={6} lg={6} sx={{mr: 2}}>
                    <DateTemplatesTreeV2 templates={datewiseTemplates} />
                </Grid>
            </Grid>
            <Grid container spacing={2} >
            <Grid item xs={8} sm={12} md={12} lg={8}>
                <Typography sx={{mt: 1}} variant="h6" gutterBottom component="div">
                    <Typography sx={{mt: 1}} variant="h6" gutterBottom component="div">
                        Select Dates
                    </Typography>
                    <ToggleButtonGroup
                    color="primary"
                    exclusive
                    size="small"
                    
                    value={pickMode}
                    onChange={onModeChange}
                    >
                    <ToggleButton sx={{lineHeight: '0.5'}} size="small" value="multiple">Multiple</ToggleButton>
                    <ToggleButton sx={{lineHeight: '0.5'}} size="small" value="range">Range</ToggleButton>
                </ToggleButtonGroup>
                </Typography>
                 
                <Calendar
                    key={isClear} 
                    mapDays={MapDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    plugins={selectedDates ? [
                        <DatePanel className="date-panel" />
                       ] : []}
                    multiple={pickMode === 'multiple'}
                    range={pickMode === 'range'}
                    onChange={dateObjects => {
                        setSelectedDates(true);
                        onChangeDates(dateObjects)
                    }}
                    numberOfMonths={monthsCount}
                />
                <FormHelperText sx={{color: '#d32f2f'}}>
                    { formSubmitted && validation.slotDates ? 'Please select dates' : '' }
                </FormHelperText> 
            </Grid>
            
            {/* <Grid item xs={12} sm={12} md={12} lg={4} mt={11} sx={{height: '200px'}}>
                <DateTemplatesTree />
                
            </Grid> */}
            <Grid item xs={12} sm={12} md={11} lg={8}>
                <Legends />
            </Grid>
            
            <Grid item xs={10} sm={8} md={6} lg={6}>
                <Typography sx={{mt: 1}} variant="h6" gutterBottom component="div">
                    Choose a Template
                </Typography>
                <FormControl error={formSubmitted && validation.templateId} fullWidth>
                    <InputLabel size="small" 
                    id="demo-simple-select-label">Select</InputLabel>
                    <Select
                    labelId="templates-list"
                    id="templates-list"
                    label="Select"
                    size="small"
                    value={form.templateId}
                    error={formSubmitted && validation.templateId}
                    onChange={onSelectTemplate}>
                    {templates.map(({templateId, templateName, templateStatus}) => {

                            if (templateStatus !== 'Inactive') {
                            return <MenuItem  value={templateId} >{templateName}</MenuItem>;
                            }
                            return <></>;
                        })
                    }
                    {/* {templates.map(({ templateId, templateName, templateStatus }) => <Hidden></Hidden>)} */}
                    </Select>
                    <FormHelperText sx={{marginLeft: '0'}}>
                        {formSubmitted && validation.templateId ? 'Please select a template' : '' }
                    </FormHelperText>   
                </FormControl>
            </Grid>
                <Grid item xs={12}>
                    <Button
                        type="button"
                        variant="contained"
                        sx={{ marginRight:'10px' }}
                        
                        onClick={onSubmit}>
                            Assign
                    </Button>
                    <Button
                        type="button"
                        variant="outlined"
                        sx={{ marginRight:'10px' }}
                        
                        onClick={onClear}>
                            Reset
                    </Button>
                </Grid>   
            </Grid>
            </Box>
    )
}

const mapStateToProps = (state) => ({
    onAssignTemplate: state.assignTemplate.assignTemplateAPIResponse,
});

export default connect(mapStateToProps)(AssignTemplate);
