import { Button, Grid, Typography, Box, FormControl, TextField, FormHelperText, InputLabel, Select, MenuItem, FormControlLabel, Switch, Modal } from '@mui/material';

import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getProvinces, getCities } from '../../store/reducers/utils';
import { setOrgFormErr, setOrgFields, saveOrg, updateOrg, toggleWarnMsg, setOrgAPIResponse, updateOrgAPIResponse, clearOrg, getCTSInfo } from '../../store/reducers/addCTS';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DataGrid } from '@mui/x-data-grid';

const AddCTS = (props) => {
    const params = useParams();

    const id = params.id;

    const isEdit = id !== undefined;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getProvinces());
    }, [dispatch]);

    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    // const [warnMsg, showWarnMsg] = useState(false);


    // const [orgDetails, setOrgDetails] = useState({});
    const { provinces, cities } = useSelector((state) => state.utils);
    const { ctsDetails, formErr, showWarnMsg } = useSelector((state) => state.addOrg);
    // const [ suppliersToDelete, setSuppliersToDelete ] = useState([]);
    let suppliersToDelete = useRef([]);

    useEffect(() => {
        if (isEdit) {
            dispatch(getCTSInfo(id));
        } else {
            dispatch(clearOrg());
        }
    }, [dispatch, isEdit, id]);

    useEffect(() => {
        if (ctsDetails.province) {
            dispatch(getCities({ provinceId: ctsDetails.province }));
        }
    }, [dispatch, ctsDetails.province, ctsDetails.ctsCode]);

    const cellTooltip = (value) => {
        return (
            value && value !== '' ?
                <Tooltip title={value} >
                    <span className="MuiDataGrid-cellContent">{value}</span>
                </Tooltip> :
                <span className="MuiDataGrid-cellContent">-</span>
        )
    }

    useEffect(() => {
        if (Object.keys(props.saveAPIResponse).length) {
            const res = props.saveAPIResponse;
            if (res.statusCode === '200') {
                dispatch(setOrgAPIResponse({}));
                props.snackbar("CTS Code created successfully!");
                dispatch(clearOrg());
                navigate('/admin-cts');
            } else if (res.statusCode === '403') {
                navigate('/forbidden');
            } else {
                dispatch(setOrgAPIResponse({}));
                props.snackbar(res.statusMessage, 'error', 5000);
            }
        }
    });

    useEffect(() => {
        if (Object.keys(props.updateAPIResponse).length) {
            const res = props.updateAPIResponse;

            if (res.status === 'Warn' && res.message.length) {
                dispatch(updateOrgAPIResponse({}));
                suppliersToDelete.current = res.message[0];
                if (suppliersToDelete.current.listWithSupplierAndFormsToBeDeleted.length === 0) {
                    dispatch(updateOrg({ ...ctsDetails, status: ctsDetails.status ? 'Active' : 'Inactive', validateWhenStatusInactive: "false" }));
                } else {
                    // setSuppliersToDelete(data.listWithSupplierAndFormsToBeDeleted);
                    dispatch(toggleWarnMsg(true));
                }
            }
            else if (res.statusCode === '200') {
                dispatch(updateOrgAPIResponse({}));
                props.snackbar("CTS Code updated successfully!");
                dispatch(clearOrg());
                dispatch(toggleWarnMsg(false));
                navigate('/admin-cts');
            } else if (res.statusCode === '403') {
                navigate('/forbidden');
                dispatch(toggleWarnMsg(false));
            } else {
                dispatch(updateOrgAPIResponse({}));
                props.snackbar(res.statusMessage, 'error', 5000);
                dispatch(toggleWarnMsg(false));
            }
        }
    });



    const onProvinceChange = (e) => {
        const value = e.target.value;
        dispatch(setOrgFields({ ...ctsDetails, province: value }));
        dispatch(setOrgFormErr({ ...formErr, province: !value || value === '' }));
        dispatch(getCities({ provinceId: e.target.value }));
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setIsFormSubmitted(true);

        dispatch(setOrgFormErr({
            ...formErr,
            ctsCodeName: !ctsDetails.ctsCodeName || ctsDetails.ctsCodeName === '',
            ctsCode: !ctsDetails.ctsCode || ctsDetails.ctsCode === '',
            province: !ctsDetails.province || ctsDetails.province === '',
            city: !ctsDetails.city || ctsDetails.city === ''
        }));

        updateForm();
    }

    const updateForm = () => {
        const errList = Object.values(formErr);
        if (errList.indexOf(true) >= 0) {
            return;
        }
        if (isEdit) {
            dispatch(updateOrg({ ...ctsDetails, status: ctsDetails.status ? 'Active' : 'Inactive', validateWhenStatusInactive: true }));

        } else {
            dispatch(saveOrg(ctsDetails));
        }
    }

    const onCTSCodeChange = (e) => {
        const value = e.target.value;
        dispatch(setOrgFields({ ...ctsDetails, ctsCode: value }));
        dispatch(setOrgFormErr({ ...formErr, ctsCode: !value || value === '' }));
    }

    const onCTSCodeNameChange = (e) => {
        const value = e.target.value;
        dispatch(setOrgFields({ ...ctsDetails, ctsCodeName: value }));
        dispatch(setOrgFormErr({ ...formErr, ctsCodeName: !value || value === '' }));
    }

    const onCityChange = (e) => {
        const value = e.target.value;
        dispatch(setOrgFields({ ...ctsDetails, city: value }));
        dispatch(setOrgFormErr({ ...formErr, city: !value || value === '' }));
    }

    const onClearForm = () => {
        dispatch(clearOrg())
    }

    const onStatusChange = (e) => {
        dispatch(setOrgFields({ ...ctsDetails, status: e.target.checked }));
    }

    const goBack = () => {
        navigate('../../admin-cts');
        onClearForm();
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '60%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        // overflow: 'scroll',
        // height: '300px',
        p: 4,

    };

    const columns = [
        {
            field: 'supplierId', headerName: 'Supplier', flex: 2,
            renderCell: (node) => cellTooltip(node.value)
        }
        ,
        {
            field: 'formsToBeDeleted', headerName: 'Delivery Forms', flex: 2,
            renderCell: (node) => {
                return (
                    node.formattedValue.length ? node.formattedValue.map((form, index) => <span>{form.deliveryOrderNumber}{index === node.formattedValue.length - 1 ? '' : ', '}</span>) : '-'
                )
            } 
        },
    ];

    const onForceUpdate = () => {
        dispatch(updateOrg({ ...ctsDetails, status: ctsDetails.status ? 'Active' : 'Inactive', validateWhenStatusInactive: "false" }));
    }


    return (
        <Box
            sx={{
                p: 2
            }}
        >
            <Grid >
                <Typography variant="h6" component="div">
                    {isEdit ? 'Edit CTS' : 'Add New'}
                </Typography>

                <Box component="form" noValidate onSubmit={onSubmit} sx={{
                    p: 2
                }}>
                    <Grid container spacing={3}>
                        <Grid container spacing={3}>

                            <Grid item xs={12} sm={6}>
                                <FormControl sx={{ m: 1, minWidth: 120, width: '100%' }}>
                                    <TextField
                                        disabled={isEdit}
                                        id="ctsCode"
                                        name="ctsCode"
                                        label="CTS Code ID"
                                        fullWidth
                                        variant="standard"
                                        onChange={onCTSCodeChange}
                                        error={formErr.ctsCode}
                                        value={ctsDetails.ctsCode}
                                        helperText={formErr.ctsCode ? "CTS Code ID is required." : null}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl sx={{ m: 1, minWidth: 120, width: '100%' }}>
                                    <TextField
                                        id="ctsCodeName"
                                        name="ctsCodeName"
                                        label="CTS Code Name"
                                        fullWidth
                                        variant="standard"
                                        onChange={onCTSCodeNameChange}
                                        error={formErr.ctsCodeName}
                                        value={ctsDetails.ctsCodeName}
                                        helperText={formErr.ctsCodeName ? "CTS Code Name required." : null}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>

                                <FormControl error={isFormSubmitted && formErr.province} variant="standard" sx={{ m: 1, minWidth: 120, width: '100%' }}>
                                    <InputLabel id="province-select-standard-label">Province</InputLabel>
                                    <Select
                                        labelId="province-select-standard-label"
                                        id="province"
                                        label="Province"
                                        onChange={onProvinceChange}
                                        value={ctsDetails.province}
                                        error={formErr.province}
                                    >
                                        {provinces.map(({ id, name }) => <MenuItem value={id} >{name}</MenuItem>)}
                                    </Select>
                                    <FormHelperText className="Mui-error">
                                        {formErr.province ? 'Province is required.' : ''}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl error={isFormSubmitted && formErr.city} variant="standard" sx={{ m: 1, minWidth: 120, width: '100%' }}>
                                    <InputLabel id="city-select-standard-label">City</InputLabel>
                                    <Select
                                        labelId="city-select-standard-label"
                                        id="city"
                                        label="City"
                                        onChange={onCityChange}
                                        value={ctsDetails.city}
                                        error={formErr.city}
                                    >
                                        {cities.map(({ id, name }) => <MenuItem value={id} >{name}</MenuItem>)}
                                    </Select>
                                    <FormHelperText className="Mui-error">
                                        {formErr.city ? 'City is required.' : ''}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            {
                                isEdit &&
                                <Grid item xs={12} sm={6}>
                                    <FormControlLabel control={
                                        <Switch checked={ctsDetails.status} value={ctsDetails.status} onChange={onStatusChange} />
                                    } label={ctsDetails.status ? 'Active' : 'Inactive'} />
                                </Grid>
                            }

                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ marginRight: '10px' }}>
                                    {isEdit ? 'Update' : 'Submit'}
                                </Button>
                                {
                                    !isEdit &&
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        onClick={onClearForm}>
                                        Clear
                                    </Button>
                                }

                                {
                                    isEdit &&
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        onClick={goBack}>
                                        BACK
                                    </Button>
                                }

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} sx={{ paddingTop: '24px' }}>

                    </Grid>
                </Box>
            </Grid>
            <Modal
                open={showWarnMsg}
                // onClose={handleClose}
                style={{ overflow: 'scroll', backgroundColor: '#279989' }}
            >
                <Box sx={style} component="div">

                    <Typography variant="h5" sx={{ textAlign: 'center' }} >
                        Are you sure? <InfoOutlinedIcon sx={{ verticalAlign: 'middle' }} color="warning" />
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                        <Grid container spacing={1}>

                            <Grid item xs={12} >
                                <p>
                                    1. Deactivating the CTS code <strong>{ctsDetails.ctsCodeName}</strong> would unlink the below suppliers and they would not be able to book any deliveries, Please link these suppliers with new CTS code (if applicable).
                                </p>
                                <p>
                                    2. All the scheduled bookings mapped with these suppliers will be cancelled (if any).
                                </p>
                                <Grid item xs={12}>
                                    <Box sx={{ height: 200, width: '100%' }}>
                                    <DataGrid
                                        hideFooter={true}
                                        getRowId={(row) => row.supplierId}
                                        disableColumnMenu={true}
                                        isRowSelectable={() => false}
                                        columns={columns}
                                        rows={suppliersToDelete.current.listWithSupplierAndFormsToBeDeleted}
                                    />
                                    </Box>
                                </Grid>
                                
                            </Grid>
                        </Grid>

                    </Typography>
                    <br />
                    <Button sx={{ float: 'right' }} variant="outlined"
                        onClick={() => {
                            dispatch(toggleWarnMsg(false));
                        }}
                        >
                        Cancel
                    </Button>
                    <Button sx={{ float: 'right', marginRight: '10px' }} variant="contained"
                        onClick={() => {
                            onForceUpdate()
                        }}>
                        Proceed
                    </Button>


                </Box>
            </Modal>
        </Box>
    )
}

const mapStateToProps = (state) => ({
    saveAPIResponse: state.addOrg.saveAPIResponse,
    updateAPIResponse: state.addOrg.updateAPIResponse,

});

export default connect(mapStateToProps)(AddCTS);
