
import { Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getDeliveryFormInfo, setDeliveryFormField, getSlotDates, getSlotsAvailable, setSlotsAvailableAPIResponse, updateDeliveryFormInfo, setFormError, updateAPIResponse } from '../../../store/reducers/editDeliveryForms';
import { getProvinces, getCities, getCargoTypes, getRemarks, getFormStatuses  } from '../../../store/reducers/utils';

import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
// import Loading from '../../shared/Loading';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

// import Autocomplete from '@mui/material/Autocomplete';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { style } from '../new';
import { connect } from 'react-redux';

const AdminEditDeliveryForm = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const params = useParams();
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        dispatch(getDeliveryFormInfo(params.id));
        // return () => {
        //     dispatch(setDeliveryFormInfo({}));
        // }
    }, [dispatch, params.id]);

    useEffect(() => {
        dispatch(getProvinces());
        dispatch(getCargoTypes());
        dispatch(getRemarks());
        dispatch(getFormStatuses());
        dispatch(getSlotDates());
    }, [dispatch]);

   
    useEffect(() => {
        if(Object.keys(props.updateAPI).length) {
            const res = props.updateAPI;
            if(res.statusCode === '200') {
                dispatch(updateAPIResponse({}));
                props.snackbar("Form has been successfully submitted.");
                navigate('/admin-view-delivery');
            } else if(res.statusCode === '403') {
                navigate('/forbidden');
            } else {
                dispatch(updateAPIResponse({}));
                props.snackbar(res.statusMessage, 'error', 5000);
            }
        }
    });

    useEffect(() => {
        if(Object.keys(props.slotsAvailableAPI).length) {
            const res = props.slotsAvailableAPI;
            if(res.statusCode === '200') {
                if(res.status === 'Fail') {
                    dispatch(setSlotsAvailableAPIResponse({}));
                    props.snackbar("No slots available for the selected date!", "warning");
                } else {
                    dispatch(setSlotsAvailableAPIResponse({}));
                    showModal();
                }
            }
        }
    });

    const { data, slotDates, slotsAvailable, formErr } = useSelector((state) => state.editForm);
    const { provinces, cities, cargoTypes, remarks } = useSelector((state) => state.utils);

    useEffect(() => {
        if(data.provinceId) {
            dispatch(getCities({provinceId: data.provinceId}));
        }
    }, [dispatch, data.provinceId, data.userId]);

    const showModal = () => {
        setIsModalOpen(true);
    }

    const handleProvinceChange = (e) => {
        dispatch(getCities({provinceId: e.target.value}));
        dispatch(setDeliveryFormField({...data, provinceId: e.target.value, cityId: "" }));
        dispatch(setFormError({...formErr, cityId: true}));
    }

    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        return [year, month, day].join('-');
    }

    const disableDates = (date) => {
        var value = formatDate(date);
        if(data.slotDate && data.slotDate === value ) {
            return false;
        }
        return slotDates.every((item)=>{ return item.slotDate!==value; });;
    }

    const handleModalClose = () => {
        setIsModalOpen(false);
    }

    const handleModalOpen = (newDate, keyboardInputValue) => {
        if(!newDate) return;

        const params = {
            date: formatDateWithName(newDate), 
            cargoTypeId: data.cargoType, 
            truckno: data.truckNo
        }
        dispatch(setDeliveryFormField({...data, slotDate: newDate, slotStartTime: '', slotEndTime: ''  }))
        dispatch(getSlotsAvailable(params));
        // console.log(slotsAvailable);
        // setIsModalOpen(true);

    }

    const formatDateWithName = (value) => {
        var date = new Date(value)
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = date.toLocaleString('en-us', { month: 'short' }); 
        var yyyy = date.getFullYear();

        var formatedDate = dd + '-' + mm + '-' + yyyy;
        return formatedDate
    }

    const validateCity = (cityId) => {
        if(!cityId || cityId === '') {
            dispatch(setFormError({...formErr, cityId: true}));
        } else {
            dispatch(setFormError({...formErr, cityId: false}));
        }
    }

    const validateTruckNo = (truckNo) => {
        if(!truckNo || truckNo === '') {
            dispatch(setFormError({...formErr, truckNo: true}));
        } else {
            dispatch(setFormError({...formErr, truckNo: false}));
        }
    }

    const validateQuantity = (quantity) => {
        if(!quantity || quantity === '' || quantity === 0) {
            dispatch(setFormError({...formErr, quantity: true}));
        } else {
            dispatch(setFormError({...formErr, quantity: false}));
        }
    }

    const validateCargoType = (cargoType) => {
        if(!cargoType || cargoType === '' || cargoType === 0) {
            dispatch(setFormError({...formErr, cargoType: true}));
        } else {
            dispatch(setFormError({...formErr, cargoType: false}));
        }
    }

    const validateRemarks = (remarks) => {
        if(!remarks || remarks === '' || remarks === 0) {
            dispatch(setFormError({...formErr, remarks: true}));
        } else {
            dispatch(setFormError({...formErr, remarks: false}));
        }
    }

    const onSelectTimeSlot = (event, value) => {
        dispatch(setDeliveryFormField({...data, slotId: value.slotId, slotStartTime: value.timeSlotStart, slotEndTime: value.timeSlotEnd }))
        setIsModalOpen(false);
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setIsFormSubmitted(true);

        if(!data.cityId || data.cityId === '') {
            dispatch(setFormError({...formErr, cityId: true}));
        }

        if(!data.provinceId || data.provinceId === '') {
            dispatch(setFormError({...formErr, provinceId: true}));
        }

        if(!data.truckNo || data.truckNo === '') {
            dispatch(setFormError({...formErr, truckNo: true}));
        }

        if(!data.quantity || isNaN(data.quantity) || data.quantity === 0) {
            dispatch(setFormError({...formErr, quantity: true}));
        }
       

        if(!data.cargoType || data.cargoType === '' || data.cargoType === 0) {
            dispatch(setFormError({...formErr, cargoType: true}));
        }

        if(!data.remarks || data.remarks === '' || data.remarks === 0) {
            dispatch(setFormError({...formErr, remarks: true}));
        }

        updateDeliveryForm();
    }

    const updateDeliveryForm = () => {
        const errList = Object.values(formErr);
        if(errList.indexOf(true) >= 0) {
            return;
        }

        dispatch(updateDeliveryFormInfo({
            ...data, 
            slotDate: formatDateWithName(data.slotDate),
            slotStartTime: formatDateWithName(data.slotDate) + ' ' + data.slotStartTime,
            slotEndTime: formatDateWithName(data.slotDate) + ' ' + data.slotEndTime,
            // quantitystr: undefined,
            createdUser: undefined,
            createdDate: undefined,
            modifiedUser: undefined,
            modifiedDate: undefined,
        }));
    }

    const onMobileNumberChange = (e) => {
        const value = e.target.value;
        dispatch(setDeliveryFormField({...data, driverContactNo: value }));
        dispatch(setFormError({...formErr, driverContactNo: !isPhoneNumberValid(value) }));
    }

    const isPhoneNumberValid = (number) => {
        if (typeof number !== "undefined") {
            var pattern = new RegExp(/^0(9)\d{9}$/);
            if (pattern.test(number)) {          
                return true
            }
          }
        return false  
    }

    const onCityChange = (e) => {
        dispatch(setDeliveryFormField({...data, cityId: e.target.value }));
        validateCity(e.target.value);
    }

    const onTruckNumberChange = (e) => {
        dispatch(setDeliveryFormField({...data, truckNo: e.target.value }));
        validateTruckNo(e.target.value);
    }

    const onQuantityChange = (e) => {
        dispatch(setDeliveryFormField({...data, quantity: e.target.value, quantitystr: e.target.value}));
        validateQuantity(e.target.value);
    }
    // ---

    const handleBlurQuantity = (e) => {
        if(!data.quantitystr) {
            return;
        }
        var num = parseFloat(data.quantitystr);
        if(!isNaN(num)) {
            const cleanNum = num.toFixed(2);
            dispatch(setDeliveryFormField({...data, quantitystr: cleanNum}));
        }
        // else if(isNaN(num)){
        //     // validateQuantity(e.target.value);
        //     // dispatch(setFormError({quantityErr: true, quantityErrMessage: "Please enter valid number"})
        // }
      }

    //---

    const onCargoTypeChange = (e) => {
        dispatch(setDeliveryFormField({...data, cargoType: e.target.value }));
        validateCargoType(e.target.value);
    }

    const onRemarksChange = (e) => {
        dispatch(setDeliveryFormField({...data, remarks: e.target.value }));
        validateRemarks(e.target.value);
    }
    
    return (
        // <Layout>
        <>
            <Typography variant="h6" gutterBottom>
                Edit Delivery Form
            </Typography>
            <Box component="form" noValidate onSubmit={(event) => onSubmit(event, 'Submitted')} sx={{ mt: 3 }}>
                <Grid container spacing={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} style={{ paddingLeft: '25px' }}>
                            {/* <FormControl fullWidth error={this.state.formError}>
                                <FormHelperText>
                                    {this.state.formError === true ? this.state.formErrorMessage : ''}
                                </FormHelperText>
                            </FormControl> */}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl sx={{ m: 1, minWidth: 120, width: '100%' }}>

                            <TextField
                                id="entryName"
                                name="entryName"
                                label="Supplier Name"
                                fullWidth
                                autoComplete="entry-name"
                                variant="standard"
                                onChange={() => {}}
                                // error={this.state.entryNameErr}
                                value={data.entryName}
                                disabled={true}
                            // helperText={this.state.entryNameErr ? "This information is required." : null}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl  error={isFormSubmitted && formErr.driverContactNo} sx={{ m: 1, minWidth: 120, width: '100%' }}>

                            <TextField
                                id="driver-phone-number"
                                name="driver-phone-number"
                                label="Driver's Mobile Number"
                                fullWidth
                                autoComplete="Driver's Mobile Number"
                                variant="standard"
                                // onChange={handleInputChange('driverContactNo')}
                                onChange={onMobileNumberChange}

                                // onBlur={this.handleBlurDriverPhoneNumber}
                                value={data.driverContactNo}
                                error={formErr.driverContactNo}
                                helperText={formErr.driverContactNo ? "Please enter valid 11 digit number starts with 09." : null}                                    

                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography id="modal-modal-title" variant="h7" style={{ paddingLeft: '5px' }}>
                            Supplier's Address
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>

                        <FormControl error={isFormSubmitted && formErr.provinceId} variant="standard" sx={{ m: 1, minWidth: 120, width: '100%' }}>
                            <InputLabel id="province-select-standard-label">Province</InputLabel>
                            <Select
                                labelId="province-select-standard-label"
                                id="province"
                                label="Province"
                                onChange={handleProvinceChange}
                                value={data.provinceId}
                                error={formErr.provinceId}
                            >
                                {provinces.map(({ id, name }) => <MenuItem value={id} >{name}</MenuItem>)}
                            </Select>
                            <FormHelperText>
                                {formErr.provinceId ? 'This information is required.' : ''}
                            </FormHelperText>
                        </FormControl>

                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl  error={isFormSubmitted && formErr.cityId} variant="standard" sx={{ m: 1, minWidth: 120, width: '100%' }}>
                            <InputLabel id="city-select-standard-label">City</InputLabel>
                            <Select
                                labelId="city-select-standard-label"
                                id="city"
                                label="City"
                                error={formErr.cityId}
                                onChange={onCityChange}
                                value={data.cityId}

                            >
                                {(cities.length !== 0) ? (
                                    cities.map(({ id, name }) => <MenuItem value={id} >{name}</MenuItem>)
                                ) : (
                                        <span />
                                    )}

                            </Select>
                            <FormHelperText>
                                {formErr.cityId === true ? 'This information is required.' : ''}
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                    <FormControl error={isFormSubmitted && formErr.truckNo} sx={{ m: 1, minWidth: 120, width: '100%' }}>
                        <TextField
                            id="truck-plate-number"
                            name="truck-plate-number"
                            label="Truck/Vehicle Plate Number"
                            fullWidth
                            autoComplete="vehicle details"
                            variant="standard"
                            onChange={onTruckNumberChange}
                            // onBlur={this.handleBlurDriverPhoneNumber}
                            value={data.truckNo}
                            error={formErr.truckNo}
                            helperText={formErr.truckNo ? "This information is required." : null}                                                                      

                        />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl error={isFormSubmitted && formErr.quantity} sx={{ m: 1, minWidth: 120, width: '100%' }}>
                            <TextField
                                id="quantity"
                                name="quantity"
                                label="Quantity (MT)"
                                fullWidth
                                variant="standard"
                                value={data.quantitystr}
                                onChange={onQuantityChange}
                                onBlur={handleBlurQuantity}
                                error={formErr.quantity}
                                helperText={formErr.quantity ? "Please enter valid number." : null}                                                                      
                                />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl  error={isFormSubmitted && formErr.cargoType} variant="standard" sx={{ m: 1, minWidth: 120, width: '100%' }}>
                            <InputLabel id="type-of-cargo-label">Type of Cargo</InputLabel>
                            <Select
                                labelId="type-of-cargo"
                                id="cargo-type"
                                label="Type of Cargo"
                                value={data.cargoType}
                                error={formErr.cargoType}
                                onChange={onCargoTypeChange}

                            >
                                {cargoTypes.map(({ id, name }) => <MenuItem value={id} >{name}</MenuItem>)}
                            </Select>
                            <FormHelperText>
                                {formErr.cargoType === true ? 'This information is required.' : ''}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl  error={isFormSubmitted && formErr.remarks} variant="standard" sx={{ m: 1, minWidth: 120, width: '100%' }}>
                            <InputLabel id="remarks-label">Remarks</InputLabel>
                            <Select
                                labelId="remarks"
                                id="remarks"
                                label="Remarks"
                                error={formErr.remarks}
                                onChange={onRemarksChange}
                                value={data.remarks}

                            >
                                {remarks.map(({ id, name }) => <MenuItem value={id} >{name}</MenuItem>)}
                            </Select>
                            <FormHelperText>
                                {formErr.remarks ? 'This information is required.' : ''}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl  variant="standard" sx={{ m: 1, minWidth: 120, width: '100%' }}>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    
                                    id="admin-edit-deliver-slot-date"
                                    value={data.slotDate}
                                    onChange={(newValue) => {
                                        // handleModalOpen(newValue)
                                    }}
                                    onAccept={(newValue) => {
                                        handleModalOpen(newValue)
                                    }}
                                    allowSameDateSelection={true}
                                    shouldDisableDate={disableDates}
                                    disablePast={true}
                                    // readOnly={true}
                                    renderInput={(params) => <TextField onKeyDown={(event) => {
                                        event.preventDefault();
                                      }} {...params} label="Slot Date" disabled={true} />}
                                />
                            </LocalizationProvider>                           
                            <FormHelperText>
                                {formErr.slotDate ? 'This information is required.' : ''}
                            </FormHelperText>
                        </FormControl>

                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl sx={{ m: 1, minWidth: 120, width: '100%' }}>
                            <TextField
                                id="Time-slot"
                                name="Time slot"
                                label="Time Slot"
                                fullWidth
                                autoComplete="time slot"
                                variant="standard"
                                value={data.slotStartTime.slice(0, -3) + "-" + data.slotEndTime.slice(0, -3)}
                                disabled={true}
                                // error={this.state.slotErr}
                                // helperText={this.state.slotErr ? "This information is required." : null}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12}>

                        <FormControl sx={{ m: 1 }}>

                            <Button
                        
                                type="submit"
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Submit
                                </Button>
                        </FormControl>
                        <FormControl sx={{ m: 1 }}>
                           
                                <Button type="button"
                                onClick={()=> navigate('/admin-view-delivery')}
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    BACK
                                </Button>
                            
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
            <Modal
                open={isModalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ overflow:'scroll' }}
                >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Select Time Slot
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <Paper elevation={3}>

                            {(() => {
                                if (slotsAvailable.length !== 0) {
                                return (
                                    <Grid container spacing={2} style={{paddingLeft: '15px', paddingRight: '15px', mariginTop: '15px', mariginBottom: '15px'}}>
                                    {slotsAvailable.map((value, i) =>
                                                    <Grid item xs={12} sm={4}>
                                                        <Button                   
                                                        variant="contained"
                                                        disabled = {(parseInt(value.slotsAvailable) > 0 && parseInt(value.slotsAvailableForTruck) !== 1) ? false : true}
                                                        onClick={(event) => onSelectTimeSlot(event, value)}
                                                        
                                                        sx={{ mt: 0, mb: 2, width: '100%' }} 
                                                        >
                                                            <AccessTimeIcon/>
                                                            {value.timeSlotStart.slice(0, -3)+"-"+value.timeSlotEnd.slice(0, -3)}
                                                        </Button>
                                                    </Grid>
                                            )}
                                    </Grid>
                                    )
                                }
                            })()}


                        </Paper>
                    </Typography>
                </Box>
                        </Modal>
            </>
        //</Layout>
        
    )
}

const mapStateToProps = (state) => ({
    updateAPI: state.editForm.updateAPIResponse,
    slotsAvailableAPI: state.editForm.slotsAvailableAPIResponse
});
  

// export default AdminEditDeliveryForm;
export default connect(mapStateToProps)(AdminEditDeliveryForm);
