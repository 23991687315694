import * as React from 'react';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';

const Footer = () => {

    const roleId = localStorage.getItem('roleId');

    return (
        <>
                    <div className="footer" style={{
                        bottom: '0',
                        width: 'calc(100% + 16px)',
                        position: 'fixed',
                        marginLeft: '-24px',
                        padding: '10px',
                        maxHeight:'85px',
                        height: 'initial',
                        backgroundColor: 'aliceblue',
                        fontSize:'12px'
                    }}>
                        <Grid container sx={{width: '72%'}}>
                        <Grid xs={12}>
                                {'Copyright © '}
                                <Link color="inherit">
                                  Cargill
                                </Link>{' '}
                                  {new Date().getFullYear()}
                                  &nbsp;&nbsp; 
                                  {
                                  roleId !== 'admin' &&
                                  <>|&nbsp;&nbsp;
                                  <Link  href="https://www.cargill.com/privacy" sx={{marginLeft: '3px'}}>
                                        Privacy Policy 
                                  </Link>
                                  </>
                                }
                                
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Link>
                                  <div id="teconsent" style={{display: 'inline'}}>
                                  </div>
                                </Link>
                                
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Link>
                                  <div id="trustarc-irm-placeholder" style={{display: 'inline'}}>
                                  </div>
                                </Link>
                        </Grid>
                        
                    </Grid>
                    </div>
            
           
        </>
        

        );
};

export default Footer;
