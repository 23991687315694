import store from '../../store';

const initialState = {
    totalCount: 0,
    list:  [],
    pagination: {
        currentPage: 1,
        offset: 0,
        pageSize: 10,
        pageCount: 0,
    },
    filters: {
        supplier: "",
        supplierName: "",
        ctsCode: "",
        ctsCodeName: "",
        unmapped: "false"
    },
    sort: {
        sortOrder: "asc",
        sortBy: "supplier"
    }
}

export const GET_SUPPLIERS_LIST_WITH_CTS = 'GET_SUPPLIERS_LIST_WITH_CTS';
export const SET_SUPPLIERS_LIST_WITH_CTS = 'SET_SUPPLIERS_LIST_WITH_CTS';
export const SET_SUPPLIERS_LIST_PAGE_NUMBER = 'SET_SUPPLIERS_LIST_PAGE_NUMBER';
export const SET_SUPPLIERS_LIST_FILTER = 'SET_SUPPLIERS_LIST_FILTER';
export const CLEAR_SUPPLIERS_LIST_FILTER = 'CLEAR_SUPPLIERS_LIST_FILTER';
export const SET_SUPPLIERS_LIST_SORT = 'SET_SUPPLIERS_LIST_SORT';

export const getSuppliersList = () => {
    return {
        type: GET_SUPPLIERS_LIST_WITH_CTS,
        payload: store.getState().listSupplier
    }
}

export const setSuppliersList = (data) => {
    return {
        type: SET_SUPPLIERS_LIST_WITH_CTS,
        payload: {list: data.message, totalCount: data.totalCount}
    }
}

export const setPageNumber = (pageNumber) => {
    return {
        type: SET_SUPPLIERS_LIST_PAGE_NUMBER,
        payload: {currentPage: pageNumber}
    }
}

export const setFilter = (filter) => {
    return {
        type: SET_SUPPLIERS_LIST_FILTER,
        payload: filter
    }
}

export const setSort = (sort) => {
    return {
        type: SET_SUPPLIERS_LIST_SORT,
        payload: sort
    }
}

export const clearFilter = () => {
    return {
        type: CLEAR_SUPPLIERS_LIST_FILTER
    }
}


export const listSupplierReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_SUPPLIERS_LIST_WITH_CTS: {
            return { 
                ...state, 
                ...action.payload, 
                pagination: {
                    ...state.pagination,
                    pageCount: Math.ceil(action.payload.totalCount / state.pagination.pageSize)
                }
            };
        }
        case SET_SUPPLIERS_LIST_PAGE_NUMBER: {
            return { 
                ...state, 
                pagination: {
                    ...state.pagination,
                    currentPage: action.payload.currentPage,
                    offset: action.payload.currentPage === 1 ? 0 : (action.payload.currentPage - 1) * state.pagination.pageSize
                }
            };
        }
        case SET_SUPPLIERS_LIST_FILTER: {
            return {
                ...state,
                filters: action.payload
            }
        }
        case SET_SUPPLIERS_LIST_SORT: {
            return {
                ...state,
                sort: action.payload
            }
        }
        case CLEAR_SUPPLIERS_LIST_FILTER: {
            return {
                ...state,
                filters: initialState.filters
            }
        }
        default:
            return state;
    }
}