import { GET, PUT } from '../requests/requests';
import { setSlots, updateSlotsAPIResponse, getSlotsAPIResponse } from '../../store/reducers/blockSlots';
import { put, call } from 'redux-saga/effects';

export function* handleGetSlotsRequest (params) {
    try {
        const url = `${process.env.REACT_APP_API_AUTH_URL}/slotsForDate?date=${params.payload}`;
        const response = yield call (GET, {url: url});
        yield put (setSlots(
            {
                slots: response.data.message.slice(),
                originalSlots: response.data.message.slice().map((i) => {return { slotId: i.slotId, blockedStatus: i.blockedStatus === null ? 'unblocked' : i.blockedStatus }}),
                templateName: response.data.templateName,
                templateId: response.data.templateId
            }
        ));
    } catch ({response}) {
        yield put (getSlotsAPIResponse(response.data));   

    }
}

export function* handleBlobkUnblockSlotsRequest (params) {
    try {
        const data = params.payload;
        const url = `${process.env.REACT_APP_API_AUTH_URL}/saveSlotsStatus`;
        const response = yield call (PUT, {url: url, data});
        yield put (updateSlotsAPIResponse(response.data));
    } catch ({response}) {
        yield put (updateSlotsAPIResponse(response.data));    }
}