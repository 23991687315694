const initialState = {
    slots: [],
    templateName: '',
    templateId: '',
    originalSlots: [],
    updateSlotsAPIResponse: {},
    getSlotsAPIResponse: {}
}

export const GET_SLOTS_BY_DATE = 'GET_SLOTS_BY_DATE';
export const SET_SLOTS_BY_DATE = 'SET_SLOTS_BY_DATE';
export const UPDATE_SLOTS = 'UPDATE_SLOTS';
export const UPDATE_SLOTS_API_RESPONSE = 'UPDATE_SLOTS_API_RESPONSE';
export const GET_SLOTS_API_RESPONSE = 'GET_SLOTS_API_RESPONSE';
export const SET_ORIGINAL_SLOTS = 'SET_ORIGINAL_SLOTS';

export const getSlots = (date) => {
    return {
        type: GET_SLOTS_BY_DATE,
        payload: date
    }
}

export const setSlots = (data) => {
    return {
        type: SET_SLOTS_BY_DATE,
        payload: data
    }
}

export const updateSlots = (data) => {
    return {
        type: UPDATE_SLOTS,
        payload: data
    }
}



export const updateSlotsAPIResponse = (data) => {
    return {
        type: UPDATE_SLOTS_API_RESPONSE,
        payload: data
    }
}

export const getSlotsAPIResponse = (data) => {
    return {
        type: GET_SLOTS_API_RESPONSE,
        payload: data
    }
}

export const BlockSlotsReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_SLOTS_BY_DATE: {
            return {
                ...state,
                slots: [...action.payload.slots],
                originalSlots: [...action.payload.originalSlots],
                templateName: action.payload.templateName,
                templateId: action.payload.templateId,
            }
        }
    
        case UPDATE_SLOTS_API_RESPONSE: {
            return {
                ...state,
                updateSlotsAPIResponse: action.payload
            }
        }
        case GET_SLOTS_API_RESPONSE: {
            return {
                ...state,
                getSlotsAPIResponse: action.payload
            }
        }
        default:
            return state;
    }
}