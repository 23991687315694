import { takeLatest } from 'redux-saga/effects';
import { GET_DELIVERY_FORMS } from '../store/reducers/deliveryForms';
import { GET_DELIVERY_FORM_INFO, UPDATE_DELIVERY_FORM_INFO, GET_SLOTS_AVAILABLE, GET_SLOT_DATES, CANCEL_DELIVERY_FORM, COMPLETE_DELIVERY_FORM } from '../store/reducers/editDeliveryForms';
import { GET_CARGO_TYPES, GET_REMARKS, GET_CANCELLATION_REASONS, GET_FORM_STATUSES, GET_CITIES, GET_PROVINCES, GET_VEHICLE_PLATE_NUMBERS } from '../store/reducers/utils';
import { UPDATE_SLOT_DATES_COUNT, GET_SLOT_DATES_COUNT, GET_BOOKINGS_ALLOWED_COUNT, UPDATE_BOOKINGS_ALLOWED_COUNT } from '../store/reducers/adminSettings';

import { ADD_TEMPLATE, GET_TEMPLATE_DETAILS } from '../store/reducers/templateForm';
import { handleDeliveryFormsRequest, handleGetDeliveryFormInfoRequest, handleUpdateDeliveryFormInfoRequest, handleGetSlotDatesRequest, handleGetSlotsAvailableRequest, handleCancelDeliveryFormInfoRequest, handleCompleteDeliveryFormRequest } from './handlers/deliveryFormsHandler';
import { handleGetCargoType, handleGetRemarks, handleGetCancellationReasons, handleGetFormStatuses, handleGetCities, handleGetProvinces, handleGetRecentFiveVehicleNumbers } from './handlers/utilsHandler';
import { handleAddTemplateRequest, handleGetTemplateRequest } from './handlers/templateHandlers';
import { handleSetSlotDatesCountRequest, handleGetSlotDatesCountRequest, handleGetBookingsAllowedCountRequest, handleSetBookingsAllowedCountRequest } from './handlers/settingsHandler';
import { handleGetBlockedDatesRequest, handleUpdateBlockedDatesRequest } from './handlers/blockDatesHandler';
import { GET_BLOCKED_UNBLOCKED_DATES, SAVE_BLOCKED_UNBLOCKED_DATES } from '../store/reducers/blockDates';
import { GET_TEMPLATES, GET_DATEWISE_TEMPLATES, ASSIGN_TEMPLATE } from '../store/reducers/assignTemplate';
import { handleGetTemplatesRequest, handleGetDatewiseTemplatesRequest, handleAssignTemplatesRequest } from './handlers/assignTemplateHandler';

import { handleGetSlotsRequest, handleBlobkUnblockSlotsRequest } from './handlers/blockSlotsHandler';
import { GET_SLOTS_BY_DATE, UPDATE_SLOTS } from '../store/reducers/blockSlots';
import { handleGetPendingFormsRequest, handleGetChangesSummaryRequest, handleResolveConflictedSlotsRequest } from './handlers/bulkEditHandler';
import { GET_PENDING_FORMS_TODAY, GET_CHANGES_SUMMARY, UPDATE_RESOLVE_CONFLICTED_SLOTS } from '../store/reducers/bulkEdit';
import { SAVE_ORG, GET_CTS_INFO_FOR_EDIT, UPDATE_CTS } from '../store/reducers/addCTS';
import { GET_SUPPLIERS_LIST_WITH_CTS } from '../store/reducers/listSuppliers';

import { handleaddCTSRequest, handleEditCTSRequest, handleSearchCTSbyCode, handleLinkCTSSupplierRequest, handleGetAllCTSRequest, handleGetCTSbyCode } from './handlers/orgHandlers';
import { handleGetSuppliersRequest, handleGetSuppliersByCTSRequest } from './handlers/suppliersHandler';
import { SEARCH_CTS, LINK_CTS } from '../store/reducers/linkCTS';
import { GET_ALL_CTS } from '../store/reducers/listCTS';
import { GET_SUPPLIERS_INFO } from '../store/reducers/viewCTS';
import { GET_FORM_DETAILS_FOR_SCANNER, SCANNER_UPDATE_FORM_STATUS } from '../store/reducers/scannerViewDelivery';
import { handleGetDeliveryFormDetailsRequest, handleUpdateDeliveryFormStatusRequest } from './handlers/scannerStaffHandler';


export function* watcherSaga() {
    yield takeLatest(GET_DELIVERY_FORMS, handleDeliveryFormsRequest);
    yield takeLatest(GET_CARGO_TYPES, handleGetCargoType);
    yield takeLatest(GET_REMARKS, handleGetRemarks);
    yield takeLatest(GET_CANCELLATION_REASONS, handleGetCancellationReasons);
    yield takeLatest(GET_FORM_STATUSES, handleGetFormStatuses);
    yield takeLatest(GET_DELIVERY_FORM_INFO, handleGetDeliveryFormInfoRequest);
    yield takeLatest(UPDATE_DELIVERY_FORM_INFO, handleUpdateDeliveryFormInfoRequest);
    yield takeLatest(GET_CITIES, handleGetCities);
    yield takeLatest(GET_PROVINCES, handleGetProvinces);
    yield takeLatest(GET_VEHICLE_PLATE_NUMBERS, handleGetRecentFiveVehicleNumbers);
    yield takeLatest(GET_SLOT_DATES, handleGetSlotDatesRequest);
    yield takeLatest(GET_SLOTS_AVAILABLE, handleGetSlotsAvailableRequest);
    yield takeLatest(CANCEL_DELIVERY_FORM, handleCancelDeliveryFormInfoRequest);
    yield takeLatest(COMPLETE_DELIVERY_FORM, handleCompleteDeliveryFormRequest);
    yield takeLatest(ADD_TEMPLATE, handleAddTemplateRequest);
    yield takeLatest(GET_TEMPLATE_DETAILS, handleGetTemplateRequest);
    yield takeLatest(UPDATE_SLOT_DATES_COUNT, handleSetSlotDatesCountRequest);
    yield takeLatest(GET_SLOT_DATES_COUNT, handleGetSlotDatesCountRequest);
    yield takeLatest(GET_BLOCKED_UNBLOCKED_DATES, handleGetBlockedDatesRequest);
    yield takeLatest(SAVE_BLOCKED_UNBLOCKED_DATES, handleUpdateBlockedDatesRequest);
    yield takeLatest(GET_TEMPLATES, handleGetTemplatesRequest);
    yield takeLatest(ASSIGN_TEMPLATE, handleAssignTemplatesRequest);
    yield takeLatest(GET_SLOTS_BY_DATE, handleGetSlotsRequest);
    yield takeLatest(UPDATE_SLOTS, handleBlobkUnblockSlotsRequest);
    yield takeLatest(GET_DATEWISE_TEMPLATES, handleGetDatewiseTemplatesRequest);
    yield takeLatest(GET_CHANGES_SUMMARY, handleGetChangesSummaryRequest);
    yield takeLatest(GET_PENDING_FORMS_TODAY, handleGetPendingFormsRequest);
    yield takeLatest(UPDATE_RESOLVE_CONFLICTED_SLOTS, handleResolveConflictedSlotsRequest);
    yield takeLatest(SAVE_ORG, handleaddCTSRequest);
    yield takeLatest(GET_SUPPLIERS_LIST_WITH_CTS, handleGetSuppliersRequest);
    yield takeLatest(SEARCH_CTS, handleSearchCTSbyCode);
    yield takeLatest(LINK_CTS, handleLinkCTSSupplierRequest);
    yield takeLatest(GET_BOOKINGS_ALLOWED_COUNT, handleGetBookingsAllowedCountRequest);
    yield takeLatest(UPDATE_BOOKINGS_ALLOWED_COUNT, handleSetBookingsAllowedCountRequest);
    yield takeLatest(GET_ALL_CTS, handleGetAllCTSRequest);
    yield takeLatest(GET_CTS_INFO_FOR_EDIT, handleGetCTSbyCode);
    yield takeLatest(UPDATE_CTS, handleEditCTSRequest);
    yield takeLatest(GET_SUPPLIERS_INFO, handleGetSuppliersByCTSRequest);
    yield takeLatest(GET_FORM_DETAILS_FOR_SCANNER, handleGetDeliveryFormDetailsRequest);
    yield takeLatest(SCANNER_UPDATE_FORM_STATUS, handleUpdateDeliveryFormStatusRequest);

}